var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isRender)?_c('div',{staticClass:"dialog shareDialog"},[_c('van-popup',{on:{"click-overlay":_vm.cancel},model:{value:(_vm.dialogVisible),callback:function ($$v) {_vm.dialogVisible=$$v},expression:"dialogVisible"}},[_c('div',{staticClass:"title-wrapper"},[_c('van-tabs',{ref:"tabs",attrs:{"color":"#2496FF"},on:{"change":_vm.onTypeChange},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}},[_c('van-tab',{attrs:{"title":"我的帖子"}}),_c('van-tab',{attrs:{"title":"收藏帖子"}})],1),_c('img',{staticStyle:{"cursor":"pointer"},attrs:{"width":"16px","height":"16px","src":require("@/assets/images/icon-弹窗关闭.png"),"alt":"close"},on:{"click":_vm.cancel}})],1),_c('van-divider'),_c('div',{staticClass:"content-wrapper"},[(_vm.type === 0 && _vm.myList.length > 0)?_c('ul',{staticClass:"list"},_vm._l((_vm.myList),function(item){return _c('li',{key:item._id,staticClass:"list-item",on:{"click":function($event){return _vm.handleSelect(item)}}},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('div',{staticClass:"user"},[_c('div',{staticClass:"avatar"},[_c('img',{attrs:{"src":item.user.icon,"width":"100%","height":"100%"}})]),_c('div',{staticClass:"name"},[_vm._v(_vm._s(item.user.name))])]),(item.brief)?_c('div',{staticClass:"text"},[_vm._v(_vm._s(item.brief))]):_vm._e()]),(item.images && item.images.length > 0)?_c('div',{staticClass:"video",style:({
                backgroundImage: ("url(" + (item.images[0]) + ")")
              })}):_vm._e()]),_c('div',{staticClass:"others"},[_c('div',{staticClass:"left"},[_vm._v(" "+_vm._s(item.count.comment)+"评论 · "+_vm._s(item.count.vote)+"点赞 · "+_vm._s(_vm.timeago(item.time * 1000))+" ")]),_c('div',{staticClass:"right"},[_vm._v(_vm._s(item.community.name))])])])}),0):_vm._e(),(_vm.type === 1 && _vm.fList.length > 0)?_c('ul',{staticClass:"list"},_vm._l((_vm.fList),function(item){return _c('li',{key:item._id,staticClass:"list-item",on:{"click":function($event){return _vm.handleSelectArticleCollection(item)}}},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"user"},[_c('div',{staticClass:"avatar"},[_c('img',{attrs:{"src":item.user.icon,"width":"100%","height":"100%"}})]),_c('div',{staticClass:"name"},[_vm._v(_vm._s(item.user.name))])]),(item.brief)?_c('div',{staticClass:"text"},[_vm._v(_vm._s(item.brief))]):_vm._e()]),(item.images && item.images.length > 0)?_c('div',{staticClass:"video",style:({
                backgroundImage: ("url(" + (item.images[0]) + ")")
              })}):_vm._e()]),_c('div',{staticClass:"others"},[_c('div',{staticClass:"left"},[_vm._v(" "+_vm._s(item.count.comment)+"评论 · "+_vm._s(item.count.vote)+"点赞 · "+_vm._s(_vm.timeago(item.time.create * 1000))+" ")]),_c('div',{staticClass:"right"},[_vm._v(_vm._s(item.bbs.name))])])])}),0):_vm._e(),(
          (_vm.type === 0 && _vm.myList.length === 0) ||
            (_vm.type === 1 && _vm.fList.length === 0)
        )?_c('div',{staticClass:"empty"},[_c('div',{staticClass:"empty-img"},[_c('img',{attrs:{"width":"100%","height":"100%","src":require("@/assets/images/缺省插图-无内容.png")}})]),_c('span',[_vm._v("这里还没有东西哦")])]):_c('NoMoreData',{attrs:{"main":""}})],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }