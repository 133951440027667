




import createApi from "@/api/api"
import { Component, Vue } from "vue-property-decorator"
const api = createApi()
@Component({ name: "Weibo" })
export default class Weibo extends Vue {
  async mounted() {
    if (this.$route.query.code) {
      this.getToken()
    }
  }

  async getToken() {
    try {
      const res = await api.auth.weiboLogin({
        code: this.$route.query.code as string,
        redirect_uri: "https://www.ghzs666.com/auth/weibo"
      })
      this.$cookies.set("access_token", res.data.access_token.value, {
        expires: res.data.access_token.expire
      })
      this.$cookies.set("refresh_token", res.data.refresh_token.value, {
        expires: res.data.refresh_token.expire
      })
      this.$root.$tstore.actions.UPDATE_API_TOKEN({
        access_token: res.data.access_token.value
      })
      ;(this.$parent as any).getUserInfo()
    } catch (error) {
      console.log(error)
    }
  }
}
