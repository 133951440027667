

























































import { Component, Prop, Vue, Watch } from "vue-property-decorator"

@Component({
  name: "GameDialog",
  components: {
    SearchInput: () =>
      import("@/views/Toolkit/components/searchInput/searchInput.vue"),
    Empty: () => import("@/components/empty/empty.vue")
  }
})
export default class GameDialog extends Vue {
  // 通用弹窗的JS数据和方法
  cancel() {
    this.isSubmit = false
    this.dialogVisible = false
  }

  onSubmit() {
    if (!this.selected) {
      this.$error("请选择发布账号")
      return false
    }
    this.isSubmit = true
    this.dialogVisible = false
  }

  dialogDataPresets = {
    fakeUserList: []
  }

  isRender: boolean = true
  isSubmit: boolean = false

  @Prop()
  dialogVisible: boolean = false

  @Watch("dialogVisible")
  onVisible(change) {
    if (change) {
      this.list = this.dialogDataPresets.fakeUserList
    }
  }

  isEmpty = false
  selected = null
  list = []
  handleSearch(keyword) {
    if (!keyword.trim()) {
      this.list = this.dialogDataPresets.fakeUserList
      this.isEmpty = false
      return false
    }
    const list = this.dialogDataPresets.fakeUserList.filter(v =>
      v.name.includes(keyword)
    )
    this.isEmpty = list.length === 0
    this.list = list
  }

  handleSelect(item) {
    this.selected = item
  }
}
