





















































import { Component, Vue, Watch, Prop } from "vue-property-decorator"
import { VueCropper } from "vue-cropper"
@Component({
  name: "uploadImage",
  components: {
    VueCropper
  }
})
export default class uploadImage extends Vue {
  $refs: { cropper: any }

  showEntry = true
  /**
   * @description 点击上传
   */
  async chooseUploadFile(e) {
    const file = e.target.files.item(0)
    if (!file) return
    // console.log(file)

    if (
      !file.type.match(/jpg/i) &&
      !file.type.match(/jpeg/i) &&
      !file.type.match(/png/i) &&
      !file.type.match(/gif/i)
    ) {
      this.$error("仅支持JPG、PNG、GIF格式~")
      return false
    }

    this.$emit("fileName", file.name)

    // file转base64
    const url = await this.fileToBase64(file)

    this.showEntry = false

    // 显示图片
    this.option.img = url

    //设置裁剪框长宽
    this.setWidthHeight(url)

    // 清空，防止上传后再上传没有反应
    e.target.value = ""
  }

  fileToBase64(file) {
    return new Promise((resolve, reject) => {
      var reader = new FileReader()
      // 传入一个参数对象即可得到基于该参数对象的文本内容
      reader.readAsDataURL(file)

      reader.onload = _e => {
        // target.result 该属性表示目标对象的DataURL
        // console.log(_e.target.result)
        resolve(_e.target.result)
      }
    })
  }

  setWidthHeight(url) {
    let image = new Image()
    image.src = url
    image.onload = () => {
      this.option.autoCropWidth = image.width
      this.option.autoCropHeight = image.height
    }
  }

  async fileDrop(e) {
    const file = e.dataTransfer.files[0] // 获取到第一个上传的文件对象

    if (
      !file.type.match(/jpg/i) &&
      !file.type.match(/jpeg/i) &&
      !file.type.match(/png/i) &&
      !file.type.match(/gif/i)
    ) {
      this.$error("仅支持JPG、PNG、GIF格式~")
      return false
    }

    this.$emit("fileName", file.name)

    // file转base64
    const url = await this.fileToBase64(file)

    this.showEntry = false

    // 显示图片
    this.option.img = url

    //设置裁剪框长宽
    this.setWidthHeight(url)
  }

  option = {
    img: null, // 裁剪图片的地址
    info: true, // 裁剪框的大小信息
    outputSize: 0.8, // 裁剪生成图片的质量
    outputType: "jpeg", // 裁剪生成图片的格式
    canScale: false, // 图片是否允许滚轮缩放
    autoCrop: true, // 是否默认生成截图框
    autoCropWidth: 160, // 默认生成截图框宽度
    autoCropHeight: 90, // 默认生成截图框高度
    fixedBox: false, // 固定截图框大小 不允许改变
    fixed: true, // 是否开启截图框宽高固定比例
    fixedNumber: [16, 9], // 截图框的宽高比例
    full: true, // 是否输出原图比例的截图
    canMoveBox: true, // 截图框能否拖动
    canMove: false,
    original: false, // 上传图片按照原始比例渲染
    centerBox: true, // 截图框是否被限制在图片里面
    infoTrue: true // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
  }
  @Watch("showEntry")
  onEntry(c) {
    if (!c) {
      this.$emit("uploaded")
    }
  }
}
