import { render, staticRenderFns } from "./Info.vue?vue&type=template&id=a8c627a2&scoped=true"
import script from "./Info.vue?vue&type=script&lang=ts"
export * from "./Info.vue?vue&type=script&lang=ts"
function injectStyles (context) {
  
  var style0 = require("./Info.vue?vue&type=style&index=0&id=a8c627a2&prod&lang=scss&scoped=true")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "a8c627a2",
  "2cff2b00"
  
)

export default component.exports