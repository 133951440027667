



















import { Component, Vue } from "vue-property-decorator"

@Component({
  name: "FooterCard"
})
export default class FooterCard extends Vue {
  mounted() {
    // if (document.querySelector(".footer-component")) {
    //   ;(document.querySelector(
    //     ".footer-component"
    //   ) as HTMLElement).style.display = "none"
    // }
  }

  destroyed() {
    // if (document.querySelector(".footer-component")) {
    //   ;(document.querySelector(
    //     ".footer-component"
    //   ) as HTMLElement).style.display = ""
    // }
  }

  handleJumpSite() {
    window.open("https://beian.miit.gov.cn/#/Integrated/index")
  }

  handleLicence() {
    window.open("https://resource.ghzs.com/page/license/www.html")
  }
}
