


































































































































import { NVideo } from "@/api/modules/video"
import { timeago } from "@/utils/base"
import { Component, Vue, Prop } from "vue-property-decorator"
import downloadDialog, { downloadDialogType } from "@/components/downloadDialog"
import shareDialog from "@/components/shareDialog"

@Component({
  name: "Info",
  components: {
    Ellipsis: () => import("@/components/Ellipsis/Ellipsis.vue")
  }
})
export default class Info extends Vue {
  @Prop({ type: Object, default: {} }) data: NVideo.IDetail

  timeago = timeago

  showExpands = false

  handleDownload() {
    downloadDialog(
      {
        rmAfterDestroy: false,
        presetData: {
          type: downloadDialogType.BBS
        }
      },
      () => {}
    )
  }
  handleShare() {
    shareDialog(
      {
        rmAfterDestroy: false,
        presetData: {}
      },
      () => {}
    )
  }
}
