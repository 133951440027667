













































































































































































/**
 * @description swiper option https://www.swiper.com.cn/api/methods/107.html
 */
import { Component, Vue, Prop } from "vue-property-decorator"
import { Swiper, SwiperSlide } from "vue-awesome-swiper"
import { MHome } from "@/api/api"
import "swiper/swiper-bundle.css"
import SwiperCore, { Autoplay } from "swiper"
SwiperCore.use([Autoplay])
@Component({
  name: "ColumnCard",
  components: {
    Swiper,
    SwiperSlide
  }
})
export default class ColumnCard extends Vue {
  @Prop({ type: Object, default: () => {} }) data: MHome.IColumn

  get games1() {
    return this.data.games
      ? this.data.games.filter((game, idx) => idx % 2 === 0)
      : []
  }
  get games2() {
    return this.data.games
      ? this.data.games.filter((game, idx) => idx % 2 === 1)
      : []
  }

  createOpts(slidesOffsetBefore?: boolean) {
    return {
      speed: 15000, // 轮播速度
      freeMode: true,
      autoplay: {
        delay: 0,
        waitForTransition: false,
        disableOnInteraction: false
      },
      slidesPerView: "auto",
      spaceBetween: 16,
      loop: true,
      slidesOffsetBefore: slidesOffsetBefore ? 300 : 0
    }
  }
  $refs: { mySwiper1: any; mySwiper2: any }
  activated() {
    this.$nextTick(() => {
      this.$refs.mySwiper1 && this.$refs.mySwiper1.initSwiper()
      this.$refs.mySwiper2 && this.$refs.mySwiper2.initSwiper()
    })
  }

  isActiveDot(start, end) {
    const nowTimeStamp = new Date().getTime() / 1000
    let flag = false
    if (start && end && nowTimeStamp >= start && nowTimeStamp <= end) {
      flag = true
    }
    return flag
  }
}
