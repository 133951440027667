














import VueQr from "vue-qr"
import { Component, Vue, Prop } from "vue-property-decorator"

@Component({
  name: "Qrcode",
  components: { VueQr }
})
export default class Qrcode extends Vue {
  logoSrc = require("@/assets/images/下载引流卡片-二维码图标.png")

  @Prop({ type: String, default: "#2C3A4D" })
  colorDark

  @Prop({ type: String, default: "https://ghzs.com" })
  url

  @Prop({ type: Number, default: 40 })
  size

  @Prop({ type: Number, default: 0 })
  logoMargin
}
