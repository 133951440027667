import { render, staticRenderFns } from "./RelatedGame.vue?vue&type=template&id=506a532a&scoped=true"
import script from "./RelatedGame.vue?vue&type=script&lang=ts"
export * from "./RelatedGame.vue?vue&type=script&lang=ts"
function injectStyles (context) {
  
  var style0 = require("./RelatedGame.vue?vue&type=style&index=0&id=506a532a&prod&lang=scss&scoped=true")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "506a532a",
  "37fbc6d7"
  
)

export default component.exports