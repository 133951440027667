


































import { Datum } from "@/api/modules/toolkit"
import { TLocalStorageTypes } from "@/config"
import { Component, Vue, Prop, Watch } from "vue-property-decorator"

@Component({
  name: "cardInnerList",
  components: {}
})
export default class cardInnerList extends Vue {
  @Prop({ type: Array, required: true }) data: Datum[]
  @Prop({ type: Number, required: true }) total: number
  @Prop({ type: String, required: true }) category_id: string // 最近使用传none，搜索结果页传search，其余正常id

  beforeMount() {
    this.showList = this.data
  }

  @Watch("data")
  onData(data) {
    if (data) {
      this.showList = data
    }
  }
  openIcon: string = require("@/assets/images/icon-展开内容.png")
  closeIcon: string = require("@/assets/images/icon-收起内容.png")

  showList: Datum[] = []
  fetchedList: Datum[] = []
  hasClick = false

  isExpand = false
  get expandText() {
    return this.isExpand ? "收起" : "展开全部"
  }

  async handleExpand() {
    if (!this.hasClick) {
      await this.fetchList()
    }

    this.isExpand = !this.isExpand

    if (this.isExpand) {
      this.showList = this.fetchedList
    } else {
      this.showList = this.fetchedList.slice(0, 4)
    }
  }

  async fetchList() {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await this.$root.$tstore.getters.api.toolkit.listByCategory(
          this.category_id,
          this.total
        )
        this.fetchedList = res.data
        this.hasClick = true
        resolve("")
      } catch (error) {
        console.log(error)
        reject(error)
      }
    })
  }

  open(data: Datum) {
    if (this.category_id !== "none") {
      this.saveClickCache(data)
    }

    window.open(data.url, "_blank")
  }

  saveClickCache(data: Datum) {
    if (localStorage.getItem(TLocalStorageTypes.CLICKEDTOOLS)) {
      const list = JSON.parse(
        localStorage.getItem(TLocalStorageTypes.CLICKEDTOOLS)
      )
      if (!list.map(v => v._id).includes(data._id)) {
        if (list.length >= 4) {
          list.shift()
        }
        list.push(data)
      }
      localStorage.setItem(
        TLocalStorageTypes.CLICKEDTOOLS,
        JSON.stringify(list)
      )
    } else {
      localStorage.setItem(
        TLocalStorageTypes.CLICKEDTOOLS,
        JSON.stringify([data])
      )
    }
  }
}
