














































import { Component, Prop, Vue, Watch } from "vue-property-decorator"

@Component({
  name: "SectionDialog",
  components: {}
})
export default class SectionDialog extends Vue {
  // 通用弹窗的JS数据和方法
  cancel() {
    this.isSubmit = false
    this.dialogVisible = false
  }

  onSubmit() {
    this.isSubmit = true
    this.dialogVisible = false
  }

  dialogDataPresets = {
    sectionList: null,
    selected: null
  }

  isRender: boolean = true
  isSubmit: boolean = false

  @Prop()
  dialogVisible: boolean = false

  @Watch("dialogVisible")
  onVisible(change) {
    if (change) {
      console.log(this.dialogDataPresets.sectionList)
      this.list = this.dialogDataPresets.sectionList
      if (this.dialogDataPresets.selected) {
        this.selected = this.dialogDataPresets.selected
      }
    }
  }

  isEmpty = false
  selected = null
  list = []

  handleSelect(item) {
    this.selected = item
    this.onSubmit()
  }

  // async getSectionList(bbs_id) {
  //   if (!this.$tstore.modules.bbs.getters.sections) {
  //     await this.$tstore.modules.bbs.actions.FETCH_SECTIONS({ bbs_id })
  //   }
  //   if (!this.$tstore.modules.bbs.getters.sections) {
  //     this.isEmpty = true
  //   } else {
  //     this.list = [{ name: "全部（不选择子版块）", _id: "" }].concat(
  //       this.$tstore.modules.bbs.getters.sections
  //     )
  //   }
  // }
}
