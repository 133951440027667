


































import { IBreadcrumbItem } from "@/components/breadcrumb/extra"
import { Component, Vue } from "vue-property-decorator"
import updateTdk from "@/utils/tdk"

@Component({
  name: "Novel",
  components: {
    Breadcrumb: () => import("@/components/breadcrumb/breadcrumb.vue"),
    Slide: () => import("./components/Slide.vue"),
    Column: () => import("./components/Column.vue"),
    Hot: () => import("./components/Hot.vue"),
    LoadMore: () => import("@/components/loadMore/loadMore.vue"),
    NoMoreData: () => import("@/components/noMoreData/noMore.vue"),
    LoadMoreBtn: () => import("@/components/LoadMoreBtn/LoadMoreBtn.vue")
  },
  tdk() {
    return this.$store.state.tdk
  }
})
export default class Novel extends Vue {
  breads: IBreadcrumbItem[] = [{ name: "首页", path: "/" }, { name: "新奇" }]

  get columns() {
    return this.$root.$tstore.modules.novel.getters.columns
  }

  fetchData() {
    return Promise.all([
      this.$root.$tstore.modules.novel.actions.FETCH_COLUMNS(),
      this.$root.$tstore.modules.tdk.actions.FETCH_TDK({
        type: "manual",
        _id_or_name: encodeURI("新奇页")
      })
    ]).finally(() => {
      updateTdk.call(this)
    })
  }

  // ssr hook
  serverPrefetch() {
    return this.fetchData()
  }

  async activated() {
    if (!this.columns) {
      return this.fetchData()
    } else {
      await this.$root.$tstore.modules.tdk.actions.FETCH_TDK({
        type: "manual",
        _id_or_name: encodeURI("新奇页")
      })
      updateTdk.call(this)
    }
  }

  page = 1
  loading = false
  async handleLoadMore() {
    this.loading = true
    this.page++
    const res = await this.$root.$tstore.getters.api.novel.columnList(this.page)
    const list = this.columns.list.concat(res.data)
    const total = parseInt(res.headers.total)

    this.$root.$tstore.modules.novel.mutations.SET_COLUMNS({
      list,
      total,
      finished: list.length >= total
    })
    this.loading = false
  }
}
