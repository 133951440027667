
























import { IToolkitList } from "@/api/modules/toolkit"
import { Component, Vue, Prop } from "vue-property-decorator"

@Component({
  name: "Card",
  components: {
    SearchInput: () => import("../searchInput/searchInput.vue"),
    CardInnerList: () => import("../cardInnerList/cardInnerList.vue")
  }
})
export default class Card extends Vue {
  @Prop({ type: Object, required: true }) data: IToolkitList

  handleSearch(keyword) {
    this.$router.push("toolkit/search/" + keyword)
  }
}
