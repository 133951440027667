



































































































































































































import { Component, Vue } from "vue-property-decorator"
import downloadDialog, { downloadDialogType } from "@/components/downloadDialog"
import { DateFotmat, formatURLInHTML } from "@/utils/base"
import updateTdk, { formatTDK } from "@/utils/tdk"
import { IBreadcrumbItem } from "@/components/breadcrumb/extra"

@Component({
  name: "CommentDetail",
  components: {
    HaloImg: () => import("@/components/image/image.vue"),
    LoadMore: () => import("@/components/loadMore/loadMore.vue"),
    NoMoreData: () => import("@/components/noMoreData/noMore.vue"),
    LoadMoreBtn: () => import("@/components/LoadMoreBtn/LoadMoreBtn.vue"),
    Breadcrumb: () => import("@/components/breadcrumb/breadcrumb.vue")
  },

  tdk() {
    return this.$store.state.tdk
  }
})
export default class CommentDetail extends Vue {
  DateFotmat = DateFotmat
  formatURLInHTML = formatURLInHTML

  breads: IBreadcrumbItem[] = []
  get replies() {
    return this.$root.$tstore.modules.gameList.getters.gameCommentReplies
  }
  get commentDetail() {
    return this.$root.$tstore.modules.gameList.getters.gameCommentDetail
  }
  // 游戏摘要（主站内容）
  get webDetail() {
    return this.$root.$tstore.modules.gameList.getters.gameAbstract
  }

  // 游戏详情
  get newDetail() {
    return this.$root.$tstore.modules.gameList.getters.gameDetail
  }

  currentSort = 0

  goBack() {
    this.$router.back()
  }

  loading = false
  finished = false
  page = 1
  page_size = 30
  list = []
  sort = "time:1" // time:1正序 time：-1 倒序
  total = 0

  destroyed() {
    this.$root.$tstore.modules.gameList.mutations.SET_GAME_COMMENT_DETAIL({
      gameCommentDetail: null
    })
    this.$root.$tstore.modules.gameList.mutations.SET_GAME_COMMENT_REPLIES({
      gameCommentReplies: null
    })
  }

  // ssr hook
  serverPrefetch() {
    return this.fetchData()
  }

  beforeMount() {
    if (!this.replies && !this.commentDetail) {
      this.fetchData()
    } else {
      this.formatData()
    }
    this.prefetchNextPage()
  }

  // 请求数据
  fetchData() {
    const { game_id, comment_id } = this.$route.params

    this.$loading(true)
    this.loading = true

    return Promise.all([
      this.$root.$tstore.modules.gameList.actions.FETCH_GAME_COMMENT_DETAIL({
        game_id,
        comment_id
      }),
      this.$root.$tstore.modules.gameList.actions.FETCH_GAME_COMMENT_REPLIES({
        game_id,
        comment_id,
        params: {
          page: 1,
          page_size: this.page_size
        }
      }),
      this.$store.dispatch("tdk/FETCH_SUB_TDK", {
        type: "game",
        _id_or_name: this.$route.params.game_id,
        sub_type: "comment_detail"
      })
    ])
      .then(() => {
        this.formatData()

        this.$store.commit(
          "tdk/SET_TDK",
          formatTDK(this.$store.state.tdk, [
            {
              replaceKey: "{游戏名称}",
              replaceVal: this.webDetail.name
            },
            {
              replaceKey: "{评论内容}",
              replaceVal: this.commentDetail.content.substr(0, 50)
            },
            {
              replaceKey: "{用户ID}",
              replaceVal: this.commentDetail.user.name
            }
          ])
        )
      })
      .catch(err => {
        console.log(err)
        // this.$router.push("/")
      })
      .finally(() => {
        this.$loading(false)
        updateTdk.call(this)
        this.loading = false
      })
  }

  // 格式数据
  formatData() {
    this.list = this.replies.map(v => {
      v.showTime = DateFotmat(new Date(v.time * 1000), "yyyy-MM-dd hh:mm")
      return v
    })

    //breads
    this.breads = [
      { name: "首页", path: "/" },
      { name: this.webDetail.name, path: `/${this.webDetail._seq}` },
      {
        name: `${this.commentDetail.user.name} 对 ${this.webDetail.name} 的评论`
      }
    ]
  }
  nextList = []
  // 请求回复数据
  async fetchReplies() {
    const res = await this.$root.$tstore.getters.api.games.gameCommentReplies(
      this.$route.params.game_id,
      this.$route.params.comment_id,
      {
        page: this.page,
        page_size: this.page_size,
        sort: this.sort
      }
    )

    if (res.data.length === 0) {
      this.finished = true
    } else {
      this.list = this.list.concat(
        res.data.map(v => {
          v.showTime = DateFotmat(new Date(v.time * 1000), "yyyy-MM-dd hh:mm")
          return v
        })
      )

      this.prefetchNextPage()
    }

    this.loading = false
  }

  // 切换列表顺序
  handleSort(index) {
    this.currentSort = index
    this.sort = index === 0 ? "time:1" : "time:-1"
    this.page = 1
    this.list = []
    this.nextList = []
    this.finished = false
    this.fetchReplies()
  }

  // 下载
  handleDownloadGame() {
    const isLogin = this.$store.getters["userInfo/isLogin"]
    if (isLogin) {
      downloadDialog(
        {
          presetData: {
            type: downloadDialogType.GAME_DETAIL,
            gameName: this.webDetail.name
          }
        },
        () => {}
      )
    } else {
      this.$router.push("/login")
    }
  }

  async handleLoadMore() {
    this.page++
    if (this.nextList.length > 0) {
      this.list = this.list.concat(this.nextList)
      this.prefetchNextPage()
      return
    }
    this.fetchReplies()
  }

  async prefetchNextPage() {
    const nextRes = await this.$root.$tstore.getters.api.games.gameCommentReplies(
      this.$route.params.game_id,
      this.$route.params.comment_id,
      {
        page: this.page + 1,
        page_size: this.page_size,
        sort: this.sort
      }
    )
    if (nextRes.data.length === 0) {
      this.finished = true
      this.nextList = []
    } else {
      this.nextList = nextRes.data.map(v => {
        v.showTime = DateFotmat(new Date(v.time * 1000), "yyyy-MM-dd hh:mm")
        return v
      })
    }
  }
}
