import { render, staticRenderFns } from "./backtop.vue?vue&type=template&id=45579be9&scoped=true"
import script from "./backtop.vue?vue&type=script&lang=ts"
export * from "./backtop.vue?vue&type=script&lang=ts"
function injectStyles (context) {
  
  var style0 = require("./backtop.vue?vue&type=style&index=0&id=45579be9&prod&scoped=true&lang=scss")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "45579be9",
  "3280d08a"
  
)

export default component.exports