

















import { Component, Vue, Prop, Watch } from "vue-property-decorator"

@Component({
  name: "ConfirmBar"
})
export default class ConfirmBar extends Vue {
  @Prop({ type: Boolean, default: true }) disabled: boolean
  @Prop({ type: String, default: "" }) tipText: string

  $refs: { bar: HTMLElement }

  // mounted() {
  //   // console.log(window.innerHeight)
  //   this.$nextTick(() => {
  //     window.addEventListener("resize", this.setLeft)
  //     this.setLeft()
  //   })
  // }

  // getAbsLeft(obj) {
  //   var l = obj.offsetLeft
  //   while (obj.offsetParent !== null) {
  //     obj = obj.offsetParent
  //     l += obj.offsetLeft
  //   }
  //   return l
  // }

  // setLeft() {
  //   this.$refs.bar.style.left = this.getAbsLeft(this.$parent.$el) + "px"
  // }

  // destroyed() {
  //   window.removeEventListener("resize", this.setLeft)
  // }
}
