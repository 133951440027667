import { apiApp } from "@/config"
import { AxiosInstance, AxiosResponse } from "axios"

export default function(service: AxiosInstance) {
  return {
    novel: {
      slideList(): Promise<AxiosResponse<INovelSlideItem[]>> {
        return service.get(`${apiApp}/novel/slide`)
      },
      hotList(): Promise<AxiosResponse<INovelHotItem[]>> {
        return service.get(`${apiApp}/novel/hot`)
      },
      columnList(page?: number): Promise<AxiosResponse<INovelColumnItem[]>> {
        return service.get(`${apiApp}/novel/column`, {
          params: { page: page || 1, page_size: 20 }
        })
      }
    }
  }
}

export interface INovelSlideItem {
  _id: string
  image: string
  link: ILink
  title: string
}

export interface ILink {
  type: string
  text: string
  link: string
  link_community: ILink_community
  link_seq: string
}

interface ILink_community {
  name: string
  _id: string
}

export interface INovelHotItem {
  _id: string
  icon: string
  link: ILink
}

export interface INovelColumnItem {
  _id: string
  name: string
  show_name: boolean
  show_suffix: boolean
  order: boolean
  more: number
  tag: string
  type: string
  background: string
  type_style: string
  home: string
  relation_column_id: string
  data: INovelColumnItemData[]
  _seq: string
}

export interface INovelColumnItemData {
  _id: string
  icon: string
  mirror_status: string
  name: string
  name_suffix: string
  ori_icon: string
  _seq: string
}
