
























import { Component, Vue, Watch } from "vue-property-decorator"
import { MenuType } from "./extra"

@Component({
  name: "Menu"
})
export default class Menu extends Vue {
  show = true

  menus = [
    {
      type: MenuType.game,
      name: "首页",
      icon: require("@/assets/images/icon_home2.png"),
      activeIcon: require("@/assets/images/icon_home1.png"),
      to: "games",
      isActive: false
    },
    {
      type: MenuType.bbs,
      name: "论坛",
      icon: require("@/assets/images/icon_forum2.png"),
      activeIcon: require("@/assets/images/icon_forum1.png"),
      to: "bbsList",
      isActive: false
    },
    {
      type: MenuType.top,
      name: "排行榜",
      icon: require("@/assets/images/icon_ranking2.png"),
      activeIcon: require("@/assets/images/icon_ranking1.png"),
      to: "tops",
      isActive: false
    },
    {
      type: MenuType.column,
      name: "新奇",
      icon: require("@/assets/images/icon_novel2.png"),
      activeIcon: require("@/assets/images/icon_novel1.png"),
      to: "columns",
      isActive: false
    },
    {
      type: MenuType.toolkit,
      name: "工具",
      icon: require("@/assets/images/icon_tool2.png"),
      activeIcon: require("@/assets/images/icon_tool1.png"),
      to: "toolkit",
      isActive: false
    }
  ]

  created() {
    this.judge()
  }

  judge() {
    if (this.$route.meta?.belong) {
      this.show = true
      this.menus.forEach(v => (v.isActive = false))
      let filterItem = this.menus.filter(
        v => v.type === this.$route.meta?.belong
      )[0]
      if (filterItem) {
        filterItem.isActive = true
        if (!this.$isServer) {
          sessionStorage.setItem("ACTIVE_MENU", filterItem.type)
        }
      }
    } else {
      this.show = false
    }
  }

  @Watch("$route")
  onRoute() {
    this.judge()
  }
}
