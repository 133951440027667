// 第一，菜单 class ，Button 菜单继承 BtnMenu class
export default function($, BtnMenu, tstore?) {
  return class customMenu extends BtnMenu {
    constructor(editor) {
      // data-title属性表示当鼠标悬停在该按钮上时提示该按钮的功能简述
      const $elem = $(
        `<div class="w-e-menu" data-title="三级标题">
            <i class="w-e-icon-custom-h3"></i>
         </div>`
      )
      super($elem, editor)
      this._active = false
    }
    // 菜单点击事件
    clickHandler() {
      if (this._active) {
        this.editor.menus.menuList[0].dropList.conf.clickHandler("<p>")
      } else {
        this.editor.menus.menuList[0].dropList.conf.clickHandler("<h3>")
      }
    }
    // 菜单是否被激活（如果不需要，这个函数可以空着）
    tryChangeActive() {
      const editor = this.editor
      const reg = /^h3/i
      const cmdValue = editor.cmd.queryCommandValue("formatBlock")
      if (reg.test(cmdValue)) {
        this.active()
        this._active = true
      } else {
        this.unActive()
        this._active = false
      }
    }
  }
}
