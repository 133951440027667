import { TTStore } from "@/store"
import tieziDialog from "../../extra/tieziDialog"

// 第一，菜单 class ，Button 菜单继承 BtnMenu class
export default function($, BtnMenu, tstore?: TTStore) {
  return class customMenu extends BtnMenu {
    constructor(editor) {
      // data-title属性表示当鼠标悬停在该按钮上时提示该按钮的功能简述
      const $elem = $(
        `<div class="w-e-menu" data-title="引用帖子">
            <i class="w-e-icon-insert-tiezi"></i>
         </div>`
      )
      super($elem, editor)
    }
    // 菜单点击事件
    clickHandler() {
      // 做任何你想做的事情
      // 可参考【常用 API】文档，来操作编辑器
      // console.log("menuList", this.editor.menus)
      // this.editor.menus.menuList[1].dropList.conf.clickHandler("<h1>")
      tieziDialog(
        { rmAfterDestroy: true, presetData: { tstore } },
        ({ data }) => {
          var entity = JSON.parse(data)

          this.editor.cmd.do(
            "insertHTML",
            `
           <br/><div class='${entity.type}-container'>
          <a class='${entity.type}' href="javascript:void(0);" contenteditable="false" onclick="customLinkgo(this)" data-datas='${data}'>
            <div class='del-icon-box'><img onclick="delLink(this)" width="100%" height="100%" src="http://static-web.ghzs.com/website-static/images/icon_del.png"></div>
            <div class='flex-container'>
              <div class='gh-internal-content img-left'>
                <img class = "image-link" src='${entity.icon}'/>
              </div>
              <div class='gh-internal-content content-right'>
                <p class='content-title'>${entity.title}</p>
                <p class='contents'>${entity.brief}</p>
              </div>
            </div>
          </a>
          </div><p>&nbsp;<br></p>
        `
          )
        }
        // &#8203; 创建一个空白（即 &#8203 字符）选区
      )
    }
    // 菜单是否被激活（如果不需要，这个函数可以空着）
    // 1. 激活是什么？光标放在一段加粗、下划线的文本时，菜单栏里的 B 和 U 被激活，如下图
    // 2. 什么时候执行这个函数？每次编辑器区域的选区变化（如鼠标操作、键盘操作等），都会触发各个菜单的 tryChangeActive 函数，重新计算菜单的激活状态
    tryChangeActive() {
      // 激活菜单
      // 1. 菜单 DOM 节点会增加一个 .w-e-active 的 css class
      // 2. this.this.isActive === true
      this.active()

      // // 取消激活菜单
      // // 1. 菜单 DOM 节点会删掉 .w-e-active
      // // 2. this.this.isActive === false
      // this.unActive()
    }
  }
}
