

























import { Component, Vue, Watch } from "vue-property-decorator"

import updateTdk from "@/utils/tdk"

@Component({
  name: "GameList",
  components: {
    Sticky: () => import("@/components/sticky/sticky.vue"),
    GameCard: () => import("./components/GameCard/GameCard.vue"),
    ColumnCard: () => import("./components/ColumnCard/ColumnCard.vue"),
    HomeTagCard: () => import("./components/HomeTagCard/HomeTagCard.vue"),
    HotContent: () => import("@/views/BbsList/components/HotContent.vue"),
    GameRecommend: () => import("@/components/GameRecommend/GameRecommend.vue"),
    ScanDownload: () => import("@/components/scanDownload/scanDownload.vue"),
    LoadMoreBtn: () => import("@/components/LoadMoreBtn/LoadMoreBtn.vue"),
    LoadMore: () => import("@/components/loadMore/loadMore.vue")
  },
  tdk() {
    return this.$store.state.tdk
  }
})
export default class GameList extends Vue {
  get gameList() {
    return this.$root.$tstore.modules.gameList.getters.list
  }
  get filterTags() {
    return this.$root.$tstore.modules.gameList.getters.filterTags
  }
  get columns() {
    return this.$root.$tstore.modules.home.getters.columns || []
  }

  list = []
  page = 1
  loading = false
  finished = false

  // ssr hook
  serverPrefetch() {
    return this.fetchData()
  }

  beforeMount() {
    if (!this.gameList || !this.columns) {
      this.fetchData()
    } else {
      this.formatData()
    }
  }

  mounted() {
    this.checkTDK()
  }
  activated() {
    this.checkTDK()
  }

  fetchData() {
    this.loading = true
    return Promise.all([
      this.$store.dispatch("tdk/FETCH_TDK", {
        type: "manual",
        _id_or_name: encodeURI("首页")
      }),
      this.$root.$tstore.modules.gameList.actions.FETCH_LIST({
        page: 1
      }),
      this.$root.$tstore.modules.gameList.actions.FETCH_FILTER_TAGS(),
      this.$root.$tstore.modules.home.actions.FETCH_HOMES()
    ])
      .then(() => {
        this.formatData()
        this.setTDKCache()
      })
      .catch(err => {
        // this.$router.push({ name: "404" })
        console.log(err)
      })
      .finally(() => {
        this.loading = false
        updateTdk.call(this)
      })
  }

  formatData() {
    this.list = [...this.columns, ...this.list].concat(
      this.gameList.map(v => {
        if (v.link_game.new_tag_style) {
          v.link_game.new_tag_style = v.link_game.new_tag_style.filter(
            tag => !this.filterTags.includes(tag.name)
          )
        }
        return v
      })
    )

    this.prefetchNextPage()
    this.addIo()
  }

  nextList = []
  async handleLoadMore() {
    this.page++
    if (this.nextList.length > 0) {
      this.list = this.list.concat(this.nextList)
      this.prefetchNextPage()
      return
    }
    const res = await this.$root.$tstore.getters.api.games.homeContentList({
      page: this.page,
      page_size: 15,
      view: "web"
    })
    if (res.data.length === 0) {
      this.finished = true
    } else {
      this.list = this.list.concat(
        res.data.map(v => {
          if (v.link_game.new_tag_style) {
            v.link_game.new_tag_style = v.link_game.new_tag_style.filter(
              tag => !this.filterTags.includes(tag.name)
            )
          }
          return v
        })
      )
      this.prefetchNextPage()

      this.addIo()
    }
  }
  async prefetchNextPage() {
    const nextRes = await this.$root.$tstore.getters.api.games.homeContentList({
      page: this.page + 1,
      page_size: 15,
      view: "web"
    })
    if (nextRes.data.length === 0) {
      this.finished = true
      this.nextList = []
    } else {
      this.nextList = nextRes.data.map(v => {
        if (v.link_game.new_tag_style) {
          v.link_game.new_tag_style = v.link_game.new_tag_style.filter(
            tag => !this.filterTags.includes(tag.name)
          )
        }
        return v
      })
    }
  }

  io: IntersectionObserver = null
  players = []
  addIo() {
    if (this.$isServer) return
    if (this.io) {
      this.io.disconnect()
      this.io = null
      this.players = []
    }
    setTimeout(() => {
      this.io = new IntersectionObserver(
        entries => {
          entries.forEach(item => {
            // isIntersecting是一个Boolean值，判断目标元素当前是否可见
            if (item.isIntersecting) {
              if (item.intersectionRatio >= 0.67) {
                if (!this.players.includes(item.target)) {
                  this.players.push(item.target)
                }
              } else {
                this.players = this.players.filter(v => v !== item.target)
              }
            } else {
              if (this.players.includes(item.target)) {
                this.players = this.players.filter(v => v !== item.target)
              }
            }
          })
        },
        {
          threshold: [0, 0.33, 0.67]
        }
      )

      Array.from(document.querySelectorAll(".game-card")).forEach(card =>
        this.io.observe(card)
      )
    }, 2000)
  }

  @Watch("players")
  onPlayers() {
    if (this.players.length > 0) {
      this.$nextTick(() => {
        const vueGameCardComp = this.players[0].__vue__
        if (vueGameCardComp?.$refs?.dplayerVideo) {
          if (!vueGameCardComp.$refs.dplayerVideo.dplayer) {
            vueGameCardComp.$refs.dplayerVideo.load()
          } else {
            vueGameCardComp.$refs.dplayerVideo.dplayer.volume(0.1, true, true)
            vueGameCardComp.$refs.dplayerVideo.dplayer.play()
          }
        }
      })
    }
  }

  destroyed() {
    if (this.io) {
      this.io.disconnect()
      this.io = null
    }
  }

  /** tdk */
  setTDKCache() {
    const { title, keywords, description } = this.$store.state.tdk
    this.$store.commit("tdk/SET_CACHE", {
      path: this.$route.fullPath,
      tdk: JSON.stringify({ title, keywords, description })
    })
  }
  checkTDK() {
    if (this.$store.state.tdk.caches[this.$route.fullPath]) {
      const tdk = JSON.parse(this.$store.state.tdk.caches[this.$route.fullPath])
      this.$store.commit("tdk/SET_TDK", tdk)
      updateTdk.call(this)
    }
  }
}
