
















/**
 * @description 图片预览
 */
import { Component, Vue, Prop } from "vue-property-decorator"

@Component({
  name: "PreviewImage"
})
export default class PreviewImage extends Vue {
  @Prop({ type: Boolean, default: false }) dialogVisible: Boolean

  isSubmit = false
  isRender = true
  imgUrl = ""
  showCloseIcon = false

  handleClick() {
    this.isSubmit = true
    this.dialogVisible = false
    this.$emit("click")
  }

  handleClose(e) {
    if (e.target.className === "preview-image") {
      this.dialogVisible = false
    }
  }
}
