












import { Component, Vue } from "vue-property-decorator"
import { Swiper, SwiperSlide } from "vue-awesome-swiper"
import { ILink } from "@/api/modules/novel"
import "swiper/swiper-bundle.css"
import SwiperCore, { Autoplay, Pagination, Navigation } from "swiper"
SwiperCore.use([Autoplay, Pagination, Navigation])
@Component({
  name: "NovelSlide",
  components: {
    Swiper,
    SwiperSlide
  }
})
export default class NovelSlide extends Vue {
  swiperOptions = {
    autoplay: {
      delay: 3000
    },
    loop: true,
    pagination: {
      el: ".swiper-pagination",
      type: "bullets",
      clickable: true
    },
    slidesPerview: 1
  }

  get slideList() {
    return this.$root.$tstore.modules.novel.getters.slideList
  }

  // ssr hook
  serverPrefetch() {
    return this.$root.$tstore.modules.novel.actions.FETCH_SLIDE_LIST()
  }

  async beforeMount() {
    if (!this.slideList) {
      await this.$root.$tstore.modules.novel.actions.FETCH_SLIDE_LIST()
    }
  }

  formatUrl(link: ILink) {
    let url = ""
    switch (link.type) {
      case "community_article":
        url = `/bbs/thread-${link.link_seq}`
        break
      case "column":
        url = `/column/${link.link_seq}`
        break
      case "community":
        url = `/bbs/${link.link_seq}`
        break
      case "article":
        url = `/article/${link.link_seq}`
        break
      case "game":
        url = `/${link.link_seq}`
        break
    }
    return url
  }
}
