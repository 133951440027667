











































import { TApi } from "@/api/api"
import { Component, Prop, Vue, Watch } from "vue-property-decorator"

interface IPreset {
  api: TApi
}

@Component({
  name: "GameDialog",
  components: {
    SearchInput: () =>
      import("@/views/Toolkit/components/searchInput/searchInput.vue"),
    Empty: () => import("@/components/empty/empty.vue")
  }
})
export default class GameDialog extends Vue {
  // 通用弹窗的JS数据和方法
  cancel() {
    this.isSubmit = false
    this.dialogVisible = false
  }

  onSubmit() {
    this.isSubmit = true
    this.dialogVisible = false
  }

  dialogDataPresets: IPreset = {
    api: null
  }

  isRender: boolean = true
  isSubmit: boolean = false

  @Prop()
  dialogVisible: boolean = false

  isEmpty = false
  selected = null
  list = []
  async handleSearch(keyword) {
    // console.log(keyword)
    if (!keyword.trim()) {
      return false
    }
    const res = await this.dialogDataPresets.api.games.gameSearch({
      page: 1,
      page_size: 100,
      view: "web",
      keyword: keyword.trim()
    })
    // console.log(res)

    this.isEmpty = res.data.length === 0
    this.list = res.data
  }
  handleSelect(item) {
    this.selected = item
    this.onSubmit()
  }

  // 修复overflow hidden不起作用，修复弹框自动下拉的问题
  // scrollTopVal: number = 0
  // @Watch(`dialogVisible`)
  // onChangeDialogShow(change: any) {
  //   if (change) {
  //     this.scrollTopVal =
  //       window.pageYOffset ||
  //       document.documentElement.scrollTop ||
  //       document.body.scrollTop ||
  //       0
  //     document.body.style.overflow = "hidden"
  //     document.documentElement.style.position = "fixed"
  //     document.body.style.position = "fixed"
  //     document.body.style.width = "100%"
  //     document.documentElement.style.top = -1 * this.scrollTopVal + "px"
  //   } else {
  //     document.body.style.overflow = ""
  //     document.documentElement.style.position = ""
  //     document.body.style.position = ""
  //     window.scrollTo(0, this.scrollTopVal)
  //   }
  // }
}
