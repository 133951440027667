





import { Component, Prop, Vue } from "vue-property-decorator"
import { throttle } from "@/utils/base"
@Component({
  name: "Sticky",
  components: {}
})
export default class Sticky extends Vue {
  @Prop({ type: Number, default: 76 }) fixedTop: number // 距离底部的padding

  $refs: { stickyWrapper: any }
  paddingBottom = 20
  mounted() {
    this.$nextTick(() => {
      if (!this.$isServer) {
        window.addEventListener("scroll", this.scrollHandle, true)
        if (document.querySelector(".footer-component")) {
          this.paddingBottom =
            document.querySelector<HTMLElement>(".footer-component")
              .offsetHeight + 40
        }
        this.$refs.stickyWrapper.style.width =
          this.$refs.stickyWrapper.parentNode.offsetWidth + "px"
      }
    })
  }

  destroyed() {
    window.removeEventListener("scroll", this.scrollHandle, true)
  }
  beforeScrollTop = 0
  fixedScrollTop = 0

  scrollHandle = throttle(this.scrollThrottle, 20, this, true)
  scrollThrottle() {
    try {
      // slot中最后一个节点
      const len = this.$refs.stickyWrapper.children.length
      const lastChildOffset = this.$refs.stickyWrapper.children[
        len - 1
      ].getBoundingClientRect()
      const lastChildOffsetTop = lastChildOffset.top
      const lastChildOffsetBottom = lastChildOffset.bottom
      const lastChildOffsetHeight = lastChildOffset.height
      // 父节点
      const offset = this.$refs.stickyWrapper.getBoundingClientRect()
      const offsetTop = offset.top
      const offsetBottom = offset.bottom
      const offsetHeight = offset.height
      //
      var afterScrollTop = document.scrollingElement.scrollTop
      if (afterScrollTop - this.beforeScrollTop < 0) {
        // 上滑
        if (
          afterScrollTop <= this.fixedScrollTop &&
          this.fixedScrollTop !== 0
        ) {
          this.$refs.stickyWrapper.style.position = "inherit"
          this.$refs.stickyWrapper.style.bottom = "inherit"
          this.$refs.stickyWrapper.style.top = "inherit"
          this.fixedScrollTop = 0
        }
      } else if (afterScrollTop - this.beforeScrollTop > 0) {
        // console.log(window.innerHeight, lastChildOffsetBottom)
        // 下滑
        if (
          window.innerHeight - lastChildOffsetBottom > this.paddingBottom &&
          document.body.offsetHeight > window.innerHeight + afterScrollTop
        ) {
          if (this.fixedScrollTop === 0) {
            this.fixedScrollTop = afterScrollTop
          }
          this.$refs.stickyWrapper.style.position = "fixed"
          this.$refs.stickyWrapper.style.bottom = this.paddingBottom + "px"
        }
        //  console.log(offsetHeight, window.innerHeight)
        if (offsetHeight + this.fixedTop < window.innerHeight) {
          this.$refs.stickyWrapper.style.position = "fixed"
          this.$refs.stickyWrapper.style.top = this.fixedTop + "px"
        }
      }

      this.beforeScrollTop = afterScrollTop
    } catch (error) {
      console.log(error)
    }
  }
}
