import Api from "@/api/api"

export const gameList = {
  namespaced: true,
  state: () => ({
    list: null,
    recommendList: null,
    indexGameList: null,
    videoUrl: null,
    videoOffsetTop: null,
    hotSearchList: null,
    gameSearchList: null,
    filterTags: null,
    // 重构 新增
    gameAbstract: null,
    gameDetail: null,
    gameRating: null,
    gameComments: null,
    gameCommentDetail: null,
    gameCommentReplies: null,
    gameCommentsCache: {}
  }),
  getters: {
    list: state => state.list,
    filterTags: state => state.filterTags,
    recommendList: state => state.recommendList,
    gameAbstract: state => state.gameAbstract,
    gameDetail: state => state.gameDetail,
    gameRating: state => state.gameRating,
    gameComments: state => state.gameComments,
    gameCommentDetail: state => state.gameCommentDetail,
    gameCommentReplies: state => state.gameCommentReplies,
    gameCommentsCache: state => state.gameCommentsCache
  },
  mutations: {
    SET_LIST: (state, { list }) => {
      state.list = list
    },
    SET_RECOMMEND_LIST: (state, { recommendList }) => {
      state.recommendList = recommendList
    },
    SET_INDEXGAME_LIST: (state, { indexGameList }) => {
      state.indexGameList = indexGameList
    },
    SET_VIDEO_URL: (state, { videoUrl, videoOffsetTop }) => {
      state.videoUrl = videoUrl
      state.videoOffsetTop = videoOffsetTop
    },
    SET_HOT_SEARCH_LIST: (state, { hotSearchList }) => {
      state.hotSearchList = hotSearchList
    },
    SET_GAME_SEARCH_LIST: (state, { gameSearchList }) => {
      state.gameSearchList = gameSearchList
    },
    SET_FILTER_TAGS: (state, { filterTags }) => {
      state.filterTags = filterTags
    },
    SET_GAME_ABSTRACT: (state, { gameAbstract }) => {
      state.gameAbstract = gameAbstract
    },
    SET_GAME_DETAIL: (state, { gameDetail }) => {
      state.gameDetail = gameDetail
    },
    SET_GAME_RATING: (state, { gameRating }) => {
      state.gameRating = gameRating
    },
    SET_GAME_COMMENTS: (state, { gameComments }) => {
      state.gameComments = gameComments
    },
    SET_GAME_COMMENT_DETAIL: (state, { gameCommentDetail }) => {
      state.gameCommentDetail = gameCommentDetail
    },
    SET_GAME_COMMENT_REPLIES: (state, { gameCommentReplies }) => {
      state.gameCommentReplies = gameCommentReplies
    },
    SET_GAME_COMMENTS_CACHE: (state, { gameId, data }) => {
      state.gameCommentsCache[gameId] = data
    }
  },
  actions: {
    FETCH_LIST: ({ commit, rootState: { api } }, { page = 1 }) => {
      return api.games
        .homeContentList({ page, page_size: 15, view: "web" })
        .then(res => commit("SET_LIST", { list: res.data }))
    },
    FETCH_RECOMMEND_LIST: ({ commit, rootState: { api } }, {}) => {
      return api.games
        .recommendList({ page: 1, page_size: 10, view: "web" })
        .then(res => commit("SET_RECOMMEND_LIST", { recommendList: res.data }))
    },
    FETCH_INDEXGAME_LIST: ({ commit, rootState: { api } }, {}) => {
      return api.games
        .indexGameList({ page: 1, page_size: 100, view: "web" })
        .then(res => commit("SET_INDEXGAME_LIST", { indexGameList: res.data }))
    },
    FETCH_HOT_SEARCH_LIST: ({ commit, rootState: { api } }, {}) => {
      return api.games
        .hotSearches({ view: "web" })
        .then(res => commit("SET_HOT_SEARCH_LIST", { hotSearchList: res.data }))
    },
    FETCH_GAME_SEARCH_LIST: ({ commit, rootState: { api } }, { keyword }) => {
      return api.games
        .gameSearch({ page: 1, page_size: 100, view: "web", keyword })
        .then(res =>
          commit("SET_GAME_SEARCH_LIST", { gameSearchList: res.data })
        )
    },
    FETCH_FILTER_TAGS: ({ commit, rootState: { api } }) => {
      return api.games
        .filterTags()
        .then(res => commit("SET_FILTER_TAGS", { filterTags: res.data }))
    },

    FETCH_GAME_ABSTRACT: ({ commit, rootState: { api } }, { game_id }) => {
      return api.games
        .detail(game_id, "web_digest")
        .then(res => commit("SET_GAME_ABSTRACT", { gameAbstract: res.data }))
    },
    FETCH_GAME_DETAIL: ({ commit, rootState: { api } }, { game_id }) => {
      return api.games
        .detail(game_id, "web_detail")
        .then(res => commit("SET_GAME_DETAIL", { gameDetail: res.data }))
    },
    FETCH_GAME_RATING: ({ commit, rootState: { api } }, { game_id }) => {
      return api.games
        .gameRating(game_id)
        .then(res =>
          commit("SET_GAME_RATING", { gameRating: res.data.new_star })
        )
    },
    FETCH_GAME_COMMENTS: (
      { commit, rootState: { api } },
      { game_id, params }
    ) => {
      return api.games
        .gameComments(game_id, params)
        .then(res => commit("SET_GAME_COMMENTS", { gameComments: res.data }))
    },
    FETCH_GAME_COMMENT_DETAIL: (
      { commit, rootState: { api } },
      { game_id, comment_id }
    ) => {
      return api.games
        .gameCommentDetail(game_id, comment_id)
        .then(res =>
          commit("SET_GAME_COMMENT_DETAIL", { gameCommentDetail: res.data })
        )
    },
    FETCH_GAME_COMMENT_REPLIES: (
      { commit, rootState: { api } },
      { game_id, comment_id, params }
    ) => {
      return api.games
        .gameCommentReplies(game_id, comment_id, params)
        .then(res =>
          commit("SET_GAME_COMMENT_REPLIES", { gameCommentReplies: res.data })
        )
    }
  }
}
