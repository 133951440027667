import { render, staticRenderFns } from "./Game.vue?vue&type=template&id=406f4d0c&scoped=true"
import script from "./Game.vue?vue&type=script&lang=ts"
export * from "./Game.vue?vue&type=script&lang=ts"
function injectStyles (context) {
  
  var style0 = require("./Game.vue?vue&type=style&index=0&id=406f4d0c&prod&lang=scss&scoped=true")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "406f4d0c",
  "3ff2d977"
  
)

export default component.exports