import { render, staticRenderFns } from "./ArticleCreate.vue?vue&type=template&id=71bb9a5a&scoped=true"
import script from "./ArticleCreate.vue?vue&type=script&lang=ts"
export * from "./ArticleCreate.vue?vue&type=script&lang=ts"
function injectStyles (context) {
  
  var style0 = require("./ArticleCreate.vue?vue&type=style&index=0&id=71bb9a5a&prod&lang=scss&scoped=true")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "71bb9a5a",
  "1fe1d61d"
  
)

export default component.exports