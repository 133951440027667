import { render, staticRenderFns } from "./GameItem.vue?vue&type=template&id=11b2cb66&scoped=true"
import script from "./GameItem.vue?vue&type=script&lang=ts"
export * from "./GameItem.vue?vue&type=script&lang=ts"
function injectStyles (context) {
  
  var style0 = require("./GameItem.vue?vue&type=style&index=0&id=11b2cb66&prod&lang=scss&scoped=true")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "11b2cb66",
  "7878c8de"
  
)

export default component.exports