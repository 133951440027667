































import { IBreadcrumbItem } from "@/components/breadcrumb/extra"
import { Component, Vue, Watch } from "vue-property-decorator"

@Component({
  name: "Search",
  beforeRouteUpdate(to, from, next) {
    if (to.query.type) {
      this.type = to.query.type as string
    }
    this.key[this.type]++

    next()
  },
  components: {
    GameRecommend: () => import("@/components/GameRecommend/GameRecommend.vue"),
    ScanDownload: () => import("@/components/scanDownload/scanDownload.vue"),
    BbsSearch: () => import("./components/BbsSearch/BbsSearch.vue"),
    GameSearch: () => import("./components/GameSearch/GameSearch.vue"),
    Breadcrumb: () => import("@/components/breadcrumb/breadcrumb.vue")
  }
})
export default class Search extends Vue {
  breads: IBreadcrumbItem[] = []

  key = {
    game: 0,
    bbs: 0
  }
  type = "game" //game bbs

  serverPrefetch() {
    this.getTypeFromRoute()
  }

  beforeMount() {
    this.getTypeFromRoute()
  }

  getTypeFromRoute() {
    if (this.$route.query.type) {
      this.type = this.$route.query.type as string
    }
  }

  changeType(type) {
    this.type = type
    this.$router.replace({ path: this.$route.path, query: { type } })
  }

  @Watch("$route.params.keyword", { immediate: true })
  onKeyword(keyword) {
    if (keyword) {
      this.breads = [
        { name: "首页", path: "/" },
        { name: this.$route.params.keyword }
      ]
    }
  }
}
