






































































import { Component, Vue, Watch, Prop } from "vue-property-decorator"
import {
  createTOSClient,
  getImageBlob,
  loadVideo,
  OSS_REGION
} from "@/utils/base"
import videoPosterDLG from "@/components/videoPosterDLG"

export interface IVideoInfo {
  poster: string
  url: string
  format: string
  size: number
  length: number
  width: number
  height: number
}

@Component({
  name: "UploadVideo",
  components: {}
})
export default class UploadVideo extends Vue {
  @Prop({ type: Object, default: () => {} }) propInfo: null

  createVideoInfo() {
    return {
      poster: "",
      url: "",
      format: "",
      size: 0,
      length: 0,
      width: 0,
      height: 0
    }
  }
  videoInfo: IVideoInfo = this.createVideoInfo()
  progressValue = 0

  ossClient = null
  videoPath = ""

  showFail = false
  showEntry = true

  get showVideo() {
    return this.progressValue > 0 || this.videoInfo.poster
  }

  get showPending() {
    return this.progressValue > 0 && !this.videoInfo.poster && !this.showFail
  }

  get showSuccess() {
    return this.videoInfo.poster
  }

  validateVideo(file) {
    if (!file.type.match(/mp4/i)) {
      this.$error("暂时仅支持MP4文件格式~")
      return false
    }
    // 500M
    // if (file.size >= 524288000) {
    //   this.$error("暂时仅支持上传小于500M的视频~")
    //   return false
    // }

    return true
  }

  // 点击上传
  chooseUploadFile(e) {
    this.progressValue = 1
    this.showFail = false
    this.videoInfo = this.createVideoInfo()
    const file = e.target.files.item(0)

    if (!this.validateVideo(file)) {
      return false
    }
    this.uploadFile(file)

    // 清空，防止上传后再上传没有反应
    e.target.value = ""
  }

  async fileDrop(e) {
    e.preventDefault()
    const file = e.dataTransfer.files[0] // 获取到第一个上传的文件对象

    if (!this.validateVideo(file)) {
      return false
    }

    this.uploadFile(file)
  }

  async uploadFile(file) {
    this.videoInfo.size = file.size
    // console.log(file)
    const video: any = await loadVideo(file)
    // console.log(file, video, video.videoWidth, video.videoHeight)
    // console.log(video.duration)
    this.videoInfo.length = parseInt(video.duration)
    this.videoInfo.width = parseInt(video.videoWidth)
    this.videoInfo.height = parseInt(video.videoHeight)
    try {
      const STSRes = await this.$root.$tstore.getters.api.video.getSTSConfig()
      console.log(STSRes)
      const {
        AccessKey,
        SecretKey,
        Region,
        EndPoint,
        SecurityToken,
        Bucket,
        Key,
        Domain
      } = STSRes.data
      const config = {
        accessKeyId: AccessKey,
        accessKeySecret: SecretKey,
        region: Region,
        endpoint: EndPoint,
        stsToken: SecurityToken,
        bucket: Bucket
      }
      const client = createTOSClient(config)

      const uploadPath = Key
      // 分片大小最小为 5MB
      const partSize = 5 * 1024 * 1024

      this.showEntry = false

      const res = await client.uploadFile({
        key: uploadPath,
        file,
        partSize,
        progress: percent => {
          const _percentage = Math.floor(percent * 100)
          this.progressValue = _percentage
        }
      })

      this.ossClient = client
      this.videoPath = uploadPath
      const source = await client.getObjectV2({
        key: uploadPath,
        dataType: "blob",
        process: "video/snapshot,t_1000,f_jpg,w_0,h_0"
      })
      const dataBlob = source.data.content as Blob

      this.videoInfo.url = Domain + Key

      let postFormData = new FormData()
      postFormData.append("file", dataBlob)
      const posterRes = await this.$root.$tstore.getters.api.video.uploadImage(
        "poster",
        postFormData
      )
      this.videoInfo.poster = posterRes.data.url

      this.$emit("videoInfoChange", this.videoInfo)
    } catch (error) {
      console.log(error)
      this.showFail = true
    }
  }

  /** 均匀截取9张 */

  async createSnapshots() {
    this.$loading(true)
    const length = this.videoInfo.length * 1000
    const reqs = []
    const shots = []
    // 1-9
    for (let i = 0; i < 9; i++) {
      let t = Math.floor((i + 1) * (length / 9))
      if (length > 30000) {
        if (i === 0) {
          t = t + 10000
        }
        if (i === 8) {
          t = t - 10000
        }
      }
      reqs[i] = this.ossClient.getObjectV2({
        key: this.videoPath,
        dataType: "blob",
        process: `video/snapshot,t_${t},f_jpg,w_0,h_0`
      })
    }
    const res = await Promise.all(reqs)

    res.forEach((source, i) => {
      const dataBlob = source.data.content as Blob
      shots[i] = URL.createObjectURL(dataBlob)
    })
    this.$loading(false)
    return shots
  }

  async handlePoster() {
    videoPosterDLG(
      {
        rmAfterDestroy: true,
        presetData: {
          uploadImageApi: this.$root.$tstore.getters.api.video.uploadImage,
          selects: await this.createSnapshots()
        }
      },
      async ({ url }) => {
        if (url) {
          let [post_file, err] = await getImageBlob(url)
          let postFormData = new FormData()
          postFormData.append("file", post_file)
          const posterRes = await this.$root.$tstore.getters.api.video.uploadImage(
            "poster",
            postFormData
          )
          this.videoInfo.poster = posterRes.data.url

          this.$emit("videoInfoChange", this.videoInfo)
        }
      }
    )
  }

  @Watch("propInfo")
  onPropInfo(info) {
    if (info) {
      this.videoInfo = info
      if (this.videoInfo.poster) {
        this.showEntry = false
      } else {
        this.showEntry = true
      }
    }
  }

  @Watch("showPending")
  onStart(c) {
    if (c) {
      this.$emit("start")
    }
  }
}
