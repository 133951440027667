
















import { Component, Vue, Prop } from "vue-property-decorator"

@Component({
  name: "HomeTagCard",
  components: {}
})
export default class HomeTagCard extends Vue {
  get tags() {
    return this.$root.$tstore.modules.home.getters.tags
  }

  // ssr hook
  serverPrefetch() {
    return this.$root.$tstore.modules.home.actions.FETCH_HOMES()
  }

  beforeMount() {
    if (!this.tags) {
      this.$root.$tstore.modules.home.actions.FETCH_HOMES()
    }
  }
}
