











import { Component, Vue, Watch } from "vue-property-decorator"
import updateTdk from "@/utils/tdk"

@Component({
  name: "Juhe",
  components: {
    GameItem: () => import("./components/GameItem/GameItem.vue"),
    ArticleListCard: () =>
      import("./components/ArticleListCard/ArticleListCard.vue")
  },
  tdk() {
    return this.$store.state.tdk
  }
})
export default class Juhe extends Vue {
  get originGames() {
    return this.$root.$tstore.modules.basic.getters.juheGameList
  }

  // ssr hook
  serverPrefetch() {
    return this.fetchData()
  }

  beforeMount() {
    if (!this.originGames) {
      this.fetchData()
    }
  }

  async fetchData() {
    return Promise.all([
      this.$root.$tstore.modules.basic.actions.FETCH_JUHE_GAMES(),
      this.$root.$tstore.modules.basic.actions.FETCH_JUHE_ARTICLES(),
      this.$root.$tstore.modules.tdk.actions.FETCH_TDK({
        type: "manual",
        _id_or_name: encodeURI("SEO热门游戏内容聚合页")
      })
    ]).then(() => {
      updateTdk.call(this)
    })
  }
}
