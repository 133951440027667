import { MenuType } from "@/components/menu/extra"
import Vue from "vue"
import Router from "vue-router"

Vue.use(Router)

export default function createRouter() {
  return new Router({
    mode: "history",
    routes: [
      {
        path: "/404",
        name: "404",
        meta: {
          belong: MenuType.none
        },
        component: () => import("@/views/404/404.vue")
      },
      {
        path: "/",
        component: () => import("@/views/Layout/Layout.vue"),
        children: [
          // 首页选择论坛
          {
            path: "bbs",
            name: "bbsList",
            meta: {
              belong: MenuType.bbs
            },
            component: () => import("@/views/BbsList/BbsList.vue")
          },
          // 某游戏论坛
          {
            path: "bbs/:bbs_id(\\d+)",
            meta: {
              keepAlive: true,
              belong: MenuType.none
            },
            component: () => import("@/views/Bbs/Bbs.vue"),
            children: [
              {
                path: "",
                name: "ContentList",
                meta: {
                  keepAlive: true,
                  belong: MenuType.none
                },
                component: () =>
                  import("@/views/Bbs/components/ContentList/ContentList.vue")
              },
              // 内容详情搜索结果页
              {
                path: "search/:bbs_keyword",
                name: "ContentSearchResult",
                meta: {
                  belong: MenuType.none
                },
                component: () =>
                  import(
                    "@/views/Bbs/components/ContentList/ContentSearchResult.vue"
                  )
              }
            ]
          },
          // 发帖子
          {
            path: "bbs/:bbs_id/article/create",
            name: "articleCreate",
            component: () => import("@/views/ArticleCreate/ArticleCreate.vue")
          },
          // 发视频
          {
            path: "bbs/:bbs_id/video/create",
            name: "videoCreate",
            component: () => import("@/views/VideoCreate/VideoCreate.vue")
          },
          // 资讯文章
          {
            path: "/article/:article_id",
            name: "article",
            meta: {
              belong: MenuType.none
            },
            component: () => import("@/views/Article/Article.vue")
          },
          // 社区文章
          {
            path: "/bbs/:bbs_id/article/:article_id",
            name: "communityArticle",
            meta: {
              belong: MenuType.none
            },
            component: () =>
              import("@/views/CommunityArticle/CommunityArticle.vue")
          },
          {
            path: "/bbs/thread-:article_id",
            name: "communityArticleThread",
            meta: {
              belong: MenuType.none
            },
            component: () =>
              import("@/views/CommunityArticle/CommunityArticle.vue")
          },
          // 视频帖
          {
            path: "/bbs/video-:video_id",
            name: "video",
            component: () => import("@/views/Video/Video.vue")
          },
          // 问答帖
          {
            path: "/bbs/question-:ques_id",
            name: "question",
            meta: {
              belong: MenuType.none
            },
            component: () => import("@/views/Question/Question.vue")
          },

          // 登录页
          {
            path: "/login",
            name: "login",
            component: () => import("@/views/Login/Login.vue")
          },
          // 我的个人信息页
          {
            path: "/mine",
            name: "mine",
            meta: {
              belong: MenuType.none
            },
            component: () => import("@/views/Mine/Mine.vue")
          },
          // 搜索某游戏论坛
          {
            path: "/search/:keyword",
            name: "searchResult",
            meta: {
              belong: MenuType.none
            },
            component: () => import("@/views/Search/Search.vue")
          },
          // 游戏列表
          {
            path: "",
            name: "games",
            meta: {
              keepAlive: true,
              belong: MenuType.game
            },
            component: () => import("@/views/GameList/GameList.vue")
          },
          // 游戏详情
          {
            path: "/game/:game_id",
            meta: {
              belong: MenuType.none
            },
            component: () => import("@/views/Game/Game.vue"),
            children: [
              {
                path: "",
                name: "gameDetail",
                meta: {
                  belong: MenuType.none
                },
                component: () =>
                  import("@/views/Game/components/Detail/Detail.vue")
              },
              {
                path: "comments",
                name: "gameComments",
                meta: {
                  belong: MenuType.none
                },
                component: () =>
                  import("@/views/Game/components/Comments/Comments.vue")
              },
              {
                path: "comment/:comment_id",
                name: "gameComment",
                meta: {
                  belong: MenuType.none
                },
                component: () =>
                  import(
                    "@/views/Game/components/CommentDetail/CommentDetail.vue"
                  )
              }
            ]
          },
          {
            path: "/:game_id(\\d+)",
            meta: {
              belong: MenuType.none
            },
            component: () => import("@/views/Game/Game.vue"),
            children: [
              {
                path: "",
                name: "gameDetailNew",
                meta: {
                  belong: MenuType.none
                },
                component: () =>
                  import("@/views/Game/components/Detail/Detail.vue")
              },
              {
                path: "comments",
                name: "gameCommentsNew",
                meta: {
                  belong: MenuType.none
                },
                component: () =>
                  import("@/views/Game/components/Comments/Comments.vue")
              },
              {
                path: "comment/:comment_id",
                name: "gameCommentNew",
                meta: {
                  belong: MenuType.none
                },
                component: () =>
                  import(
                    "@/views/Game/components/CommentDetail/CommentDetail.vue"
                  )
              }
            ]
          },
          // // 标签页
          {
            path: "/tag/:tag_name",
            name: "tag",
            meta: {
              belong: MenuType.none
            },
            component: () => import("@/views/Tag/Tag.vue")
          },
          // 工具箱
          {
            path: "/toolkit",
            name: "toolkit",
            meta: {
              belong: MenuType.toolkit
            },
            component: () => import("@/views/Toolkit/Toolkit.vue")
          },
          {
            path: "/toolkit/search/:toolkitKeyword",
            name: "toolkitSearchResult",
            meta: {
              belong: MenuType.none
            },
            component: () =>
              import("@/views/Toolkit/components/searchResult/searchResult.vue")
          },
          // 联系我们
          {
            path: "/contact",
            name: "contact",
            meta: {
              belong: MenuType.none
            },
            component: () => import("@/views/Contact/Contact.vue")
          },
          //地图
          {
            path: "/map",
            name: "map",
            meta: {
              belong: MenuType.none
            },
            component: () => import("@/views/Map/Map.vue")
          },
          //精选内容
          {
            path: "/jxwd",
            name: "jxwd",
            meta: {
              belong: MenuType.none
            },
            component: () => import("@/views/JXWD/JXWD.vue")
          },
          // 排行榜
          {
            path: "/top",
            name: "tops",
            redirect: "/top/hot"
          },
          {
            path: "/top/:path",
            name: "top",
            meta: {
              keepAlive: true,
              belong: MenuType.top
            },
            component: () => import("@/views/Ranking/Ranking.vue")
          },
          // 新奇
          {
            path: "/column",
            name: "columns",
            meta: {
              keepAlive: true,
              belong: MenuType.column
            },
            component: () => import("@/views/Novel/Novel.vue")
          },
          {
            path: "/column/:column_id",
            name: "column",
            meta: {
              keepAlive: true,
              belong: MenuType.game
            },
            component: () => import("@/views/Column/Column.vue")
          },
          {
            path: "/juhe",
            name: "juhe",
            meta: {
              keepAlive: true
            },
            component: () => import("@/views/Juhe/Juhe.vue")
          }
        ]
      },
      {
        path: "/auth",
        component: () => import("@/views/Auth/Auth.vue"),
        children: [
          {
            path: "qq",
            name: "qq",
            component: () => import("@/views/Auth/QQ/QQ.vue")
          },
          {
            path: "wechat",
            name: "wechat",
            component: () => import("@/views/Auth/Wechat/Wechat.vue")
          },
          {
            path: "weibo",
            name: "weibo",
            component: () => import("@/views/Auth/Weibo/Weibo.vue")
          }
        ]
      }
    ],
    scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition
      } else {
        return { x: 0, y: 0 }
      }
    }
  })
}
