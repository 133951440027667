/**
 * @description 往editor实例注册菜单
 */
function globalRegister(editor, { $, BtnMenu }, tstore) {
  let context_menu = require.context("./", true, /index\.ts$/)
  context_menu.keys().forEach(path => {
    if (path !== "./index.ts" && context_menu(path).default) {
      const menuName = path.split("/")[1]
      editor.menus.extend(
        menuName,
        context_menu(path).default($, BtnMenu, tstore)
      )
    }
  })
}

/**
 * @description 返回菜单顺序
 */
export default function createMenus(editor, { $, BtnMenu }, tstore) {
  globalRegister(editor, { $, BtnMenu }, tstore)

  return [
    "head",
    "customImage",
    "image",
    "customVideo",
    "video",

    "customBold",
    "customItalic",
    "customStrikeThrough",
    "customUnderline",
    // "bold",
    // "italic",
    // "strikeThrough",
    // "underline",
    "quote",
    // "justify",
    "customSplitLine",
    "customJustifyLeft",
    "customJustifyRight",
    "customJustifyCenter",

    // "customH1",
    // "customH2",
    // "customH3",
    // "customH4",
    "insertAnswer",
    "insertTiezi",
    "insertVideo",
    "insertGame"
  ]
}
