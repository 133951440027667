import { render, staticRenderFns } from "./GhEditor.vue?vue&type=template&id=5562cbc9&scoped=true"
import script from "./GhEditor.vue?vue&type=script&lang=ts"
export * from "./GhEditor.vue?vue&type=script&lang=ts"
function injectStyles (context) {
  
  var style0 = require("./GhEditor.vue?vue&type=style&index=0&id=5562cbc9&prod&scoped=true&lang=scss")
if (style0.__inject__) style0.__inject__(context)
var style1 = require("./GhEditor.vue?vue&type=style&index=1&id=5562cbc9&prod&lang=scss")
if (style1.__inject__) style1.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "5562cbc9",
  "1952d92d"
  
)

export default component.exports