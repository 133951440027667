





























































/**
 * @description 游戏礼包
 */
import { Component, Vue, Prop } from "vue-property-decorator"
import downloadDialog, { downloadDialogType } from "@/components/downloadDialog"

@Component({
  name: "DetailGameGift"
})
export default class DetailGameGift extends Vue {
  @Prop({ type: Array, default: () => [] })
  libao: any[]

  get webDetail() {
    return this.$root.$tstore.modules.gameList.getters.gameAbstract
  }

  mounted() {
    this.fetchData()
  }

  libaoStatus = null
  async fetchData() {
    const data = this.libao.map(e => e._id).join("-")
    const res = await this.$root.$tstore.getters.api.games.libao({
      filter: `libao_ids:${data}`
    })
    this.libaoStatus = res.data.map(v => {
      v.ratio = ((v.available / Number(v.total)) * 100).toFixed(2)
      return v
    })
  }

  handlePreviewMore() {
    this.handleDownload()
  }

  handleDownload() {
    downloadDialog(
      {
        presetData: {
          type: downloadDialogType.GAME_DETAIL_LIBAO,
          gameName: this.webDetail.name
        }
      },
      () => {}
    )
  }
}
