import { apiApp, apiBaseUrlV5 } from "@/config"
import { AxiosInstance, AxiosResponse } from "axios"
import { IListParams } from "../api"
// https://git.shanqu.cc/halo/wiki/and-wiki/-/wikis/api/v3.6.6/API-v3.6.6#get-columnscolumn_idgames
// https://git.shanqu.cc/halo/wiki/and-wiki/-/wikis/api/v3.6.6/API-v3.6.6#get-columnscolumn_idsetting
export default function(service: AxiosInstance) {
  return {
    column: {
      columnGames(
        _id: string,
        params: IListParams
      ): Promise<AxiosResponse<MColumn.IColumnGameItem[]>> {
        return service.get(`${apiBaseUrlV5}/columns/${_id}/games?view=web`, {
          params
        })
      },
      detail(_id: string): Promise<AxiosResponse<MColumn.IColumnDetail>> {
        return service.get(`${apiBaseUrlV5}/columns/${_id}`)
      },
      setting(_id: string): Promise<AxiosResponse<MColumn.ISetting>> {
        return service.get(`${apiBaseUrlV5}/columns/${_id}/setting`)
      }
    }
  }
}

export declare module MColumn {
  interface IColumnGameItem {
    _id: string
    _seq: string
    name: string
    name_suffix: string
    category: string
    brief: string
    icon: string
    new_star: number
    new_tag_style: INew_tag_style[]
    home_setting?: IHome_setting
    gallery?: string[]
  }

  interface IHome_setting {
    image: string
    tags: void /* undefined */[]
    text_color: string
    placeholder_color: string
  }

  interface INew_tag_style {
    _id: string
    name: string
    color: string
    background: string
    column: string
    _seq: number
  }

  interface ISetting {
    type: IType
    tag: string
    filter: string
    filter_options: string[]
  }

  interface IType {
    layout: string
    content: string[]
    labels: ILabel[]
  }

  interface ILabel {
    name: string
    label: string[]
  }

  interface IColumnDetail {
    name: string
    notes: string
    creator: string
    created: ICreated
    expire: number
    mender: string
    modified: IModified
    sort: ISort[]
    game: IGame
    display: IDisplay
    index_assign: IIndex_assign[]
    label: void /* undefined */[]
    labels: void /* undefined */[]
    size: ISize
    order: number
    remark: string
    active: boolean
    relation_column_id: string
    multi_select_sort: IMulti_select_sort[]
    last_update: ILast_update
  }

  interface ILast_update {
    $date: I$date_2
  }

  interface I$date_2 {
    $numberLong: string
  }

  interface IMulti_select_sort {
    type: string
    form: string
    time: number
    order: number
  }

  interface ISize {
    index: number
    column: number
  }

  interface IIndex_assign {
    _id: string
    name: string
    name_suffix: string
    icon: string
    first_line: string
    marked_red: boolean
    recommend: boolean
    second_line: string
    start_time: number
    end_time: number
    expire: number
  }

  interface IDisplay {
    align: string
    filter: string
    home: string
    order: boolean
    tag: string
    type: string
    brief: string
    column_name: string
    filter_options: string[]
    game_suffix: string
    horizontal_style: string
    filter_sizes: IFilter_siz[]
    gallery: string
    background: string
  }

  interface IFilter_siz {
    min: number
    max: number
    text: string
  }

  interface IGame {
    upgrade: void /* undefined */[]
    ad: IAd[]
  }

  interface IAd {
    _id: string
    order: number
    expire: number
  }

  interface ISort {
    type: string
    form: string
    time: number
    order: number
  }

  interface IModified {
    $date: I$date_1
  }

  interface I$date_1 {
    $numberLong: string
  }

  interface ICreated {
    $date: I$date
  }

  interface I$date {
    $numberLong: string
  }
}
