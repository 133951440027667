import Vue from "vue"

import {
  Popup,
  Divider,
  List,
  Tabs,
  Tab,
  Sticky,
  Checkbox,
  Field,
  RadioGroup,
  Radio,
  Icon
} from "vant"
import "vant/lib/index.css"

Vue.use(Popup)
  .use(Divider)
  .use(List)
  .use(Tabs)
  .use(Tab)
  .use(Sticky)
  .use(Checkbox)
  .use(Field)
  .use(Radio)
  .use(RadioGroup)
  .use(Icon)

import VueCompositionApi, { createElement } from "@vue/composition-api"
Vue.use(VueCompositionApi)
Vue.prototype.$createElement = createElement
declare module "@vue/composition-api/dist/component/component" {
  interface SetupContext {
    readonly refs: { [key: string]: Vue | Element | Vue[] | Element[] }
  }
}
import { appBoundle, copyText, getParam } from "@/utils/base"
import * as Sentry from "@sentry/browser"
import * as Integrations from "@sentry/integrations"

if (!process.env.isSSR) {
  import("@vant/touch-emulator")
  ;(function(window) {
    import("@/utils/fns")
      .then(fns => {
        window.initVideo = fns.initVideo
        window.judgeVideoStatus = fns.judgeVideoStatus
        window.playCurrentVideo = fns.playCurrentVideo
        window.globalVideoList = []
        window.customLinkgo = fns.customLinkgo
        window.isWebFullScreen = fns.isWebFullScreen
        window.redirect = appBoundle().openApp

        window.initPlaceholderVideo = fns.initPlaceholderVideo
        window.playPlaceholderVideo = fns.playPlaceholderVideo
      })
      .catch(err => {})

    window.copyText = copyText
  })(window)

  // 密钥安装说明就是dsn
  Sentry.init({
    dsn: "https://ce77b949aa73490abfbd058e863bb56a@sentry.shanqu.cc/67",
    beforeSend: event => {
      return event
    },
    // allowUrls: [
    //   /https?:\/\/www\.ghzs\.com/,
    //   /https?:\/\/www\.ghzs666\.com/,
    //   /https?:\/\/m\.ghzs\.com/,
    //   /https?:\/\/m\.ghzs666\.com/,
    //   /https?:\/\/ghzs\.com/,
    //   /https?:\/\/ghzs666\.com/,
    //   /https?:\/\/static-web\.ghzs\.com/
    // ],
    ignoreErrors: [
      /Blocked a frame with origin/,
      /cross-origin frame/,
      /\$ is not defined/,
      /processRandomSelector is not defined/,
      /ucbrowser/i,
      /UC_NEWS_PAUSE_ALL_VIDEOS/,
      /UCReadabilityContext/,
      /vivoNewsDetailPage/,
      /MTTATTRIBUTE_Done/,
      /x5onSkinSwitch/,
      /disconnect/,
      /loadDownloads/,
      /news_page/,
      /Network Error/,
      /_qihoo360_/,
      /MyAppGetLinkHREFAtPoint/,
      /GetImageTagSrcFromPointUseNewFun/,
      /meta\[name=color\]/,
      /isH264Supported/,
      /MyAppGetLinkTitleNameAtPoint/,
      /getAppIcon/,
      /GetHTMLElementsAtPoint/,
      /window\.webkit\.messageHandlers/,
      /Unexpected identifier/,
      /GetImageTagSrcFromPoint/,
      /Can't find variable: _Box_/,
      /SecurityError: The operation is insecure/,
      /timeout of 30000ms exceeded/,
      /Identifier 'start_time_c299c42708e1badb' has already been declared/,
      /SecurityError: Not allowed to access cross-origin stylesheet/,
      /crypto\.getRandomValues() not supported/,
      /undefined is not an object (evaluating 'window\.sogouMobileExplorer\.triggerEvent')/,
      /__360broser_flow_itemclick_callback is not defined/,
      /AbortError: The operation was aborted/,
      /diableNightMode is not defined/,
      /window\.syncCallback is not a function/,
      /Unexpected token \</,
      /Unexpected token \'<\'/,
      /Failed to set the \'currentTime\' property on \'HTMLMediaElement\'/,
      /InvalidAccessError\: The object does not support the operation or argument/,
      /Cannot read property \'postMessage\' of null/,
      /this\.hostIndex\.push is not a function/,
      /The quota has been exceeded/,
      /Unexpected end of input/,
      /window\.syncCallback/,
      /document\.getElementsByTagName\(\"video\"\)\[0\]\.attributes/,
      /tencent_ug_bridge\.callbackFromNative is not a function/,
      /window\.getSelectionForSearch is not a function/,
      /UCShellJava\.sdkEventFire is not a function/,
      /window\.Java\.onPageFinished is not a function/,
      /GM_getValue is not defined/,
      /Unexpected end of script/,
      /Request aborted/,
      /vivo_checkMediaPausedReason is not defined/,
      /diableNightMode is not defined/,
      /Cannot read property \'updateBookMallStatus\' of undefined/,
      /window\.mttLongPressVar\.tagName/,
      /Cannot read property \'highlightRender\' of undefined/,
      /Cannot read property \'playState\' of undefined/,
      /r\.findIndex is not a function/,
      /Can't find variable: isNovelNetPage/,
      /Cannot read property 'doHttpRequest' of null/,
      /Avoided redundant navigation to current location/,
      /e\.asyncFactory\.resolved/
    ],
    enabled: process.env.NODE_ENV === "production",
    integrations: [
      new Integrations.Vue({
        Vue,
        attachProps: true
      })
    ]
  })

  window.addEventListener("error", () => {
    if (process.env.NODE_ENV === "production") {
      try {
        const appChild = document.querySelector("#app")

        const isFail =
          !appChild ||
          (appChild && appChild.children && appChild.children.length === 0)
        if (isFail) {
          const flag = getParam("flag")
          if (flag !== "csr") {
            const { search, href } = window.location
            const redirectURL = search ? href + "&flag=csr" : href + "?flag=csr"
            window.location.replace(redirectURL)
          }
        }
      } catch (error) {
        console.log(error)
      }
    }
  })
}

Vue.prototype.$loading = isLoading => {
  if (isLoading) {
    Vue.prototype.$toast.loading({
      mask: true,
      message: "加载中...",
      overlayStyle: {
        backgroundColor: "rgba(0, 0, 0, 0.1)"
      },
      duration: 0
    })
  } else {
    Vue.prototype.$toast.clear()
  }
}

import { imageIsExist } from "@/utils/base"
//全局注册自定义指令，用于判断当前图片是否能够加载成功，可以加载成功则赋值为img的src属性，否则使用默认图片
Vue.directive("real-img", async function(el, binding) {
  //指令名称为：real-img
  let imgURL = binding.value //获取图片地址
  if (imgURL) {
    let exist = await imageIsExist(imgURL)
    if (exist) {
      el.setAttribute("src", imgURL)
    }
  }
})

import cookies from "js-cookie"
Vue.prototype.$cookies = cookies

import alertMessage from "@/components/common/ghAlert"
Vue.prototype.$message = message => {
  alertMessage({ type: "info", message })
}
Vue.prototype.$error = message => {
  alertMessage({ type: "error", message })
}
Vue.prototype.$success = message => {
  alertMessage({ type: "success", message })
}

import confirmDLG from "@/components/common/confirmDLG"
Vue.prototype.$confirmDLG = (text, submit, cancel) => {
  confirmDLG({ rmAfterDestroy: true, presetData: { text } }, submit, cancel)
}
