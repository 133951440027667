




































import { Component, Prop, Vue, Watch } from "vue-property-decorator"

@Component({
  name: "TagsDLG",
  components: {}
})
export default class TagsDLG extends Vue {
  // 通用弹窗的JS数据和方法
  cancel() {
    this.isSubmit = false
    this.dialogVisible = false
  }

  onSubmit() {
    this.isSubmit = true
    this.dialogVisible = false
  }

  dialogDataPresets = {
    gameName: "",
    tags: []
  }

  isRender: boolean = true
  isSubmit: boolean = false

  @Prop()
  dialogVisible: boolean = false

  // 修复overflow hidden不起作用，修复弹框自动下拉的问题
  scrollTopVal: number = 0
  @Watch(`dialogVisible`)
  onChangeDialogShow(change: any) {
    if (change) {
      this.scrollTopVal =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        0
      document.body.style.overflow = "hidden"
      document.documentElement.style.position = "fixed"
      document.body.style.position = "fixed"
      document.body.style.width = "100%"
      document.documentElement.style.top = -1 * this.scrollTopVal + "px"
    } else {
      document.body.style.overflow = ""
      document.documentElement.style.position = ""
      document.body.style.position = ""
      window.scrollTo(0, this.scrollTopVal)
    }
  }

  tag = ""
  handleTag(name) {
    this.tag = name
    this.onSubmit()
  }
}
