import Vue from "vue"
import dialog from "./GhAlert.vue"

let DialogConstructor = Vue.extend(dialog)

let instance: any

interface IOption {
  type: "info" | "error" | "success"
  message: string
  left?: number
}

const defaultConifg = {
  type: "info",
  message: "",
  left: null
}

const alertMessage = (options: IOption) => {
  if (Vue.prototype.$isServer) return
  const presetData = Object.assign({}, defaultConifg, options)

  if (!instance) {
    instance = new DialogConstructor({
      watch: {
        dialogVisible: {
          handler(change) {
            this.dialogVisible = change
          }
        }
      }
    })

    instance.$mount()

    document.body.appendChild(instance.$el)

    if (!instance.dialogVisible) {
      Vue.nextTick(() => {
        Vue.set(instance, "dialogDataPresets", presetData)
        instance.dialogVisible = true
      })
    }
  } else if (instance && !instance.dialogVisible) {
    Vue.nextTick(() => {
      Vue.set(instance, "dialogDataPresets", presetData)
      instance.dialogVisible = true
    })
  }

  return instance
}

export default alertMessage
