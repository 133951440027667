












/**
 * @description 分类名称
 * 解释 -
 * 首页的分类名称，如：热门论坛/卡牌/二次元等等
 * 右边支持插槽载入，方便后面业务拓展，支持在后边添加内容
 */
import { Component, Vue, Prop } from "vue-property-decorator"

@Component({
  name: "CategoryName"
})
export default class HomeCategoryName extends Vue {
  @Prop({ type: String, default: "" }) name!: string // 标题名称
  @Prop({ type: Number, default: 0 }) num!: number // 数量
}
