




























/**
 * @description 首页侧栏热门内容
 */
import { Component, Vue } from "vue-property-decorator"
import memberDialog from "@/components/memberDialog"
import downloadDialog, { downloadDialogType } from "@/components/downloadDialog"

@Component({
  name: "HotMember"
})
export default class HotMember extends Vue {
  list = []
  loading = true

  beforeMount() {
    this.fetchData()
  }

  async fetchData() {
    try {
      const res = await this.$root.$tstore.state.api.bbs.moderators(
        this.$route.params.bbs_id
      )
      this.list = res.data
      this.loading = false
    } catch (error) {
      this.loading = false
      console.log(error)
    }
  }

  /** 打开成员弹窗 */
  handleMember() {
    memberDialog(
      {
        rmAfterDestroy: false,
        presetData: {
          bbs_id: this.$route.params.bbs_id,
          list: this.list
        }
      },
      () => {}
    )
  }

  // 下载
  handleItem() {
    downloadDialog(
      {
        rmAfterDestroy: false,
        presetData: {
          type: downloadDialogType.BBS
        }
      },
      () => {}
    )
  }
}
