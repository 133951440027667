import { render, staticRenderFns } from "./loadMore.vue?vue&type=template&id=5e20fc92&scoped=true"
import script from "./loadMore.vue?vue&type=script&lang=ts"
export * from "./loadMore.vue?vue&type=script&lang=ts"
function injectStyles (context) {
  
  var style0 = require("./loadMore.vue?vue&type=style&index=0&id=5e20fc92&prod&scoped=true&lang=scss")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "5e20fc92",
  "40c1b886"
  
)

export default component.exports