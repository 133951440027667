




























































































































import { User } from "@/api/modules/user"
import { TTStore } from "@/store"
import { timeago } from "@/utils/base"
import { Component, Prop, Vue, Watch } from "vue-property-decorator"

interface IData {
  brief: string
  communityId: string
  icon: string
  id: string
  title: string
  type: string
}

interface IPreset {
  tstore: TTStore
}
@Component({
  name: "tieziDialog",
  components: {
    NoMoreData: () => import("@/components/noMoreData/noMore.vue")
  }
})
export default class tieziDialog extends Vue {
  timeago = timeago
  // 通用弹窗的JS数据和方法
  cancel() {
    this.isSubmit = false
    this.dialogVisible = false
  }

  onSubmit() {
    this.isSubmit = true
    this.dialogVisible = false
  }

  dialogDataPresets: IPreset = {
    tstore: null
  }

  isRender: boolean = true
  isSubmit: boolean = false

  @Prop()
  dialogVisible: boolean = false

  get userInfo() {
    return this.dialogDataPresets.tstore.modules.userInfo.getters.userInfo
  }

  get api() {
    return this.dialogDataPresets.tstore.state.api
  }

  type = 0
  onTypeChange() {
    if (this.fList.length === 0) {
      this.getFList()
    }
  }

  myList: User.IHistoriesCommunityArticle[] = []
  fList: User.ICommunityArticleCollection[] = []

  async getMyList() {
    const res = await this.api.user.myCommunityArticles(this.userInfo._id)
    this.myList = res.data
  }

  async getFList() {
    const res = await this.api.user.communityArticleCollections(
      this.userInfo._id
    )
    this.fList = res.data
  }

  selected: IData = null
  handleSelect(item: User.IHistoriesCommunityArticle) {
    this.selected = {
      brief: item.brief,
      communityId: item.community._id, // 只有帖子传了
      icon:
        "http://static-web.ghzs.com/website-static/images/icon_new_article.png",
      id: item._id,
      title: item.title,
      type: "community_article"
    }
    this.onSubmit()
  }
  handleSelectArticleCollection(item: User.ICommunityArticleCollection) {
    this.selected = {
      brief: item.brief,
      communityId: item.bbs._id, // 只有帖子传了
      icon:
        "http://static-web.ghzs.com/website-static/images/icon_new_article.png",
      id: item._id,
      title: item.title,
      type: "community_article"
    }
    this.onSubmit()
  }

  @Watch("dialogVisible")
  onShow(c) {
    if (c) {
      this.getMyList()
    }
  }
}
