


















import { Component, Vue, Prop } from "vue-property-decorator"

@Component({
  name: "Des",
  components: {
    Ellipsis: () => import("@/components/Ellipsis/Ellipsis.vue")
  }
})
export default class Des extends Vue {
  @Prop({ type: String, default: "" })
  des: string

  showExpands = false
}
