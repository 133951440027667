import { MBasic, IJuheGameItem, IArticle } from "@/api/modules/basic"
import { IBreadcrumbItem } from "@/components/breadcrumb/extra"
import { ITableData, TableData } from "@/utils/base"
import { ActionContext } from "vuex"
import { IRootState } from ".."
interface IState {
  links: MBasic.FriendlyLink[]
  informations: MBasic.Information[]
  content: string
  maps: MBasic.IMapItem[]
  breads: IBreadcrumbItem[]
  seoChoice: ITableData<MBasic.ISeoChoiceItem>
  juheGameList: IJuheGameItem[]
  juheArticleList: IArticle[]
}

type Context = ActionContext<IState, IRootState>

export const basic = {
  namespaced: true,
  state: () => ({
    links: null,
    informations: null,
    content: null,
    maps: null,
    breads: null,
    seoChoice: new TableData({}),
    juheGameList: null,
    juheArticleList: null
  }),
  getters: {
    links: (state: IState) => state.links,
    informations: (state: IState) => state.informations,
    content: (state: IState) => state.content,
    maps: (state: IState) => state.maps,
    breads: (state: IState) => state.breads,
    seoChoice: (state: IState) => state.seoChoice,
    juheGameList: (state: IState) => state.juheGameList,
    juheArticleList: (state: IState) => state.juheArticleList
  },
  mutations: {
    SET_INFO: (state: IState, { links, informations }) => {
      state.links = links
      state.informations = informations
    },
    SET_CONTENT: (state: IState, { content }) => {
      state.content = content
    },
    SET_MAPS: (state: IState, { maps }) => {
      state.maps = maps
    },
    SET_BREADS: (state: IState, { breads }) => {
      state.breads = breads
    },
    SET_SEO_CHOICE: (state: IState, data: IState["seoChoice"]) => {
      state.seoChoice = Object.assign(state.seoChoice, data)
    },
    SET_JUHE_GAMES: (state: IState, { juheGameList }) => {
      state.juheGameList = juheGameList
    },
    SET_JUHE_ARTICLES: (state: IState, { juheArticleList }) => {
      state.juheArticleList = juheArticleList
    }
  },
  actions: {
    FETCH_INFO: ({ commit, rootState: { api } }: Context) => {
      return api.basic.info().then(res =>
        commit("SET_INFO", {
          links: res.data.friendly_links,
          informations: res.data.informations
        })
      )
    },
    FETCH_CONTENT: ({ commit, rootState: { api } }: Context, { _id }) => {
      return api.basic.getContent(_id).then(res =>
        commit("SET_CONTENT", {
          content: res.data.content
        })
      )
    },
    FETCH_MAPS: ({ commit, rootState: { api } }: Context) => {
      return api.basic.maps().then(res =>
        commit("SET_MAPS", {
          maps: res.data
        })
      )
    },
    FETCH_SEO_CHOICE: ({ commit, rootState: { api } }: Context) => {
      return api.basic.seoChoice({ page: 1, page_size: 20 }).then(res => {
        commit("SET_SEO_CHOICE", {
          data: res.data,
          page: 1,
          page_size: 20,
          finished: parseInt(res.headers.total) < 20,
          total: parseInt(res.headers.total) || 0
        })
      })
    },
    FETCH_JUHE_GAMES: ({ commit, rootState: { api } }: Context) => {
      return api.basic.juheGames().then(res =>
        commit("SET_JUHE_GAMES", {
          juheGameList: res.data
        })
      )
    },
    FETCH_JUHE_ARTICLES: ({ commit, rootState: { api } }: Context) => {
      return api.basic.juheArticles().then(res =>
        commit("SET_JUHE_ARTICLES", {
          juheArticleList: res.data
        })
      )
    }
  }
}
