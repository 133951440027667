
















import { Component, Prop, Vue, Watch } from "vue-property-decorator"

@Component({
  name: "ghAlert"
})
export default class ghAlert extends Vue {
  // 通用弹窗的JS数据和方法
  cancel() {
    this.isSubmit = false
    this.dialogVisible = false
  }

  onSubmit() {
    this.isSubmit = true
    this.dialogVisible = false
  }

  dialogDataPresets = {
    type: "info",
    message: "",
    left: null
  }

  isRender: boolean = true
  isSubmit: boolean = false

  @Prop()
  dialogVisible: boolean = false

  get iconUrl() {
    const { type } = this.dialogDataPresets
    if (type === "error") {
      return require("@/assets/images/icon-toast-警告.png")
    } else if (type === "success") {
      return require("@/assets/images/icon-toast-完成.png")
    } else {
      return require("@/assets/images/icon-toast-提示.png")
    }
  }
  style = ""
  @Watch("dialogVisible")
  onShow(c) {
    if (c) {
      setTimeout(() => {
        this.dialogVisible = false
      }, 2000)
      this.$nextTick(() => {
        if (this.dialogDataPresets.left) {
          this.style = `left:${this.dialogDataPresets.left}px;transform:translateX(0);`
        }
      })
    }
  }
}
