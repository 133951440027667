




import createApi from "@/api/api"
import { Component, Vue } from "vue-property-decorator"

import qqLoginErrorDLG from "@/views/Auth/QQ/qqLoginErrorDLG"

const api = createApi()
@Component({ name: "QQ" })
export default class QQ extends Vue {
  async mounted() {
    if (this.$route.query.code) {
      this.getToken()
    }
  }

  async getToken() {
    try {
      const res = await api.auth.qqLogin({
        code: this.$route.query.code as string,
        redirect_uri: "https://www.ghzs666.com/auth/qq"
      })
      this.$cookies.set("access_token", res.data.access_token.value, {
        expires: res.data.access_token.expire
      })
      this.$cookies.set("refresh_token", res.data.refresh_token.value, {
        expires: res.data.refresh_token.expire
      })
      this.$root.$tstore.actions.UPDATE_API_TOKEN({
        access_token: res.data.access_token.value
      })
      ;(this.$parent as any).getUserInfo()
    } catch (error) {
      console.log(error)
      if (error.response && error.response.data.code === 403004) {
        this.$router.replace("/login")
        setTimeout(() => {
          qqLoginErrorDLG(
            {
              rmAfterDestroy: false,
              presetData: {}
            },
            () => {}
          )
        }, 1000)
      }
    }
  }
}
