

































import { Component, Vue } from "vue-property-decorator"
import updateTdk from "@/utils/tdk"
import { TableData } from "@/utils/base"
import { TLocalStorageTypes } from "@/config"

@Component({
  name: "VideoPage",
  components: {
    Info: () => import("./components/Info.vue"),
    Comments: () => import("./components/Comments.vue"),
    Recommends: () => import("./components/Recommends.vue"),
    GameInfo: () => import("@/views/Article/components/gameInfo.vue"),
    ScanDownload: () => import("@/components/scanDownload/scanDownload.vue"),
    DPlayerVideo: () => import("@/components/DPlayerVideo/index.vue")
  },
  async beforeRouteUpdate(to, from, next) {
    await this.fetchData(to.params.video_id)
    this.reset()
    next()
  },
  tdk() {
    return this.tdk
  }
})
export default class VideoPage extends Vue {
  get gameInfo() {
    const data = {
      icon: "",
      name: "",
      gameId: "",
      bbsId: ""
    }
    if (this.videoDetail.game) {
      data.icon = this.videoDetail.game.icon
      data.name = this.videoDetail.game.name
      data.gameId = this.videoDetail.game._seq + ""
    }
    if (this.videoDetail.bbs) {
      if (this.videoDetail.bbs.icon) {
        data.icon = this.videoDetail.bbs.icon
      }
      if (this.videoDetail.bbs.name) {
        data.name = this.videoDetail.bbs.name
      }

      data.bbsId = this.videoDetail.bbs._seq + ""
    }
    return data
  }

  get tdk() {
    const tdk = { title: null, keywords: null, description: null }
    if (this.videoDetail) {
      tdk.title = `${this.videoDetail.title}-${this.gameInfo.name}视频-光环助手视频`
      tdk.keywords = `${this.videoDetail.title}，${this.gameInfo.name}视频，${this.gameInfo.name}，光环助手视频`
      tdk.description = `${this.gameInfo.name}论坛为您提供${this.gameInfo.name}关于${this.videoDetail.title}的相关视频，在光环助手网页版，有关${this.gameInfo.name}的一切应有尽有，最多最全攻略手游论坛！`
    }
    return tdk
  }

  $refs: { dplayerVideo: any; comments: any }

  isPlaying = false

  options = {
    screenshot: false,
    video: {
      _id: "",
      url: "",
      pic: "",
      type: "auto"
    },
    volume: 0.75
  }
  onVolumn(v) {
    if (!!v) {
      localStorage.setItem(TLocalStorageTypes.VIDEO_DETAIL_VOLUME, v)
      this.$refs.dplayerVideo.dplayer.volume(v, false, true)
    }
  }

  get videoDetail() {
    return this.$root.$tstore.modules.content.getters.videoDetail
  }

  get videoRecommends() {
    return this.$root.$tstore.modules.content.getters.videoRecommends
  }

  get videoComments() {
    return this.$root.$tstore.modules.content.getters.videoComments
  }

  // ssr hook
  serverPrefetch() {
    return this.fetchData()
  }

  beforeMount() {
    if (!this.videoDetail) {
      this.fetchData()
    }
  }
  timer = null
  mounted() {
    this.reset()
  }

  reset() {
    this.timer = setInterval(() => {
      if (this.videoDetail) {
        this.options = {
          screenshot: false,
          video: {
            _id: this.videoDetail._id,
            url: this.videoDetail.url,
            pic: this.videoDetail.poster,
            type: "auto"
          },
          volume: localStorage.getItem(TLocalStorageTypes.VIDEO_DETAIL_VOLUME)
            ? parseFloat(
                localStorage.getItem(TLocalStorageTypes.VIDEO_DETAIL_VOLUME)
              )
            : 0.75
        }
        this.setBreads()
        this.setCommentSort()
        clearInterval(this.timer)
        this.timer = null
      }
    }, 100)
  }

  setBreads() {
    const breads = [{ name: "首页", path: "/" }]
    if (this.videoDetail.bbs) {
      breads.push({
        name: this.videoDetail.bbs.name,
        path: `/bbs/${this.videoDetail.bbs._seq}`
      })
    } else if (this.videoDetail.game) {
      breads.push({
        name: this.videoDetail.game.name,
        path: `/game/${this.videoDetail.game._id}`
      })
    }
    breads.push({
      name: this.videoDetail.title,
      path: ""
    })
    this.$root.$tstore.modules.basic.mutations.SET_BREADS({
      breads
    })
  }
  setCommentSort() {
    this.$refs.comments.reset()
  }

  fetchData(id?) {
    return new Promise(async (resolve, reject) => {
      try {
        await this.$root.$tstore.modules.content.actions.FETCH_VIDEO_DETAIL({
          id: id || this.$route.params.video_id
        })

        updateTdk.call(this)

        resolve("")
      } catch (error) {
        reject(error)
      }
    })
  }

  onLoad(c) {
    // this.image = ""
    ;(this.$el.querySelector(".dplayer-setting") as HTMLElement).style.display =
      "none"
    this.isPlaying = true
    // 这里设置声音会导致播放暂停
    // this.$refs.dplayerVideo.dplayer.volume(0.1, true, true)
  }

  destroyed() {
    this.$root.$tstore.modules.content.mutations.SET_VIDEO_DETAIL(null)
    this.$root.$tstore.modules.content.mutations.SET_VIDEO_COMMENTS(
      new TableData({})
    )
    this.$root.$tstore.modules.content.mutations.SET_VIDEO_RECOMMENDS(null)
    this.$root.$tstore.modules.basic.mutations.SET_BREADS({ breads: null })
  }
}
