



























































import { Component, Prop, Vue, Watch } from "vue-property-decorator"

@Component({
  name: "videoPosterDLG",
  components: {
    uploadImage: () => import("./components/uploadImage/UploadImage.vue")
  }
})
export default class videoPosterDLG extends Vue {
  $refs: { uploadImageComp: any }
  // 通用弹窗的JS数据和方法
  cancel() {
    this.isSubmit = false
    this.dialogVisible = false
  }

  onSubmit() {
    this.isSubmit = true
    this.dialogVisible = false
  }

  dialogDataPresets = {
    uploadImageApi: null,
    selects: []
  }

  isRender: boolean = true
  isSubmit: boolean = false

  @Prop()
  dialogVisible: boolean = false

  type = 0 // 0 选择封面 / 1 上传封面
  onTypeChange() {}

  selectedIdx = null
  fileName = ""
  lastUrl = ""
  finish() {
    if (this.type === 0) {
      this.lastUrl = this.dialogDataPresets.selects[this.selectedIdx]
      this.onSubmit()
    } else {
      // console.log(this.$refs.uploadImageComp)
      // 获取
      this.$refs.uploadImageComp.$refs.cropper.getCropBlob(async blob => {
        // console.log(blob)
        let file = new window.File([blob], this.fileName, {
          type: blob.type
        })
        // console.log(file)
        let formData = new FormData()
        formData.append("file", file)
        const posterRes = await this.dialogDataPresets.uploadImageApi(
          "poster",
          formData
        )
        // console.log(posterRes)
        this.lastUrl = posterRes.data.url
        this.onSubmit()
      })
    }
  }

  onFileName(name) {
    this.fileName = name
  }

  handleReUpload() {
    // console.log(this.$refs.uploadImageComp.offsetTop)
    this.$refs.uploadImageComp.$refs.imageInput.click()
  }
  isUploaded = false
  onUploaded() {
    this.isUploaded = true
  }

  // 修复overflow hidden不起作用，修复弹框自动下拉的问题
  scrollTopVal: number = 0
  @Watch(`dialogVisible`)
  onChangeDialogShow(change: any) {
    if (change) {
      this.scrollTopVal =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        0
      document.body.style.overflow = "hidden"
      document.documentElement.style.position = "fixed"
      document.body.style.position = "fixed"
      document.body.style.width = "100%"
      document.documentElement.style.top = -1 * this.scrollTopVal + "px"
    } else {
      document.body.style.overflow = ""
      document.documentElement.style.position = ""
      document.body.style.position = ""
      window.scrollTo(0, this.scrollTopVal)
    }
  }
}
