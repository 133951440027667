import { render, staticRenderFns } from "./UserDialog.vue?vue&type=template&id=7c56e1a2&scoped=true"
import script from "./UserDialog.vue?vue&type=script&lang=ts"
export * from "./UserDialog.vue?vue&type=script&lang=ts"
function injectStyles (context) {
  
  var style0 = require("./UserDialog.vue?vue&type=style&index=0&id=7c56e1a2&prod&scoped=true&lang=scss")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "7c56e1a2",
  "bb0891ca"
  
)

export default component.exports