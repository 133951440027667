import { NTagSearch } from "@/api/api"
import { ActionContext } from "vuex"
import { IRootState } from ".."

interface IState {
  tags: NTagSearch.IItem[]
  tagName: string
}

type Context = ActionContext<IState, IRootState>

export const game = {
  namespaced: true,
  state: () => ({
    tags: null,
    tagName: ""
  }),
  getters: {
    tags: (state: IState) => state.tags,
    tagName: (state: IState) => state.tagName
  },
  mutations: {
    SET_TAGS: (state, { data }) => {
      state.tags = data
    },
    SET_TAG_NAME: (state, { name }) => {
      state.tagName = name
    }
  },
  actions: {
    FETCH_TAGS: ({ commit, rootState: { api } }: Context, params) => {
      return new Promise(async (resolve, reject) => {
        try {
          const filterTagsResp = await api.games.filterTags()
          const tagsResp = await api.games.tags(params)

          commit("SET_TAGS", {
            data: tagsResp.data.map(v => {
              if (v.new_tag_style) {
                v.new_tag_style = v.new_tag_style.filter(
                  tag => !filterTagsResp.data.includes(tag.name)
                )
              }
              return v
            })
          })

          resolve("")
        } catch (error) {
          reject(error)
        }
      })
    }
  }
}
