








































/**
 * @description 游戏权限列表弹窗
 */
import { Component, Vue, Prop } from "vue-property-decorator"

@Component({
  name: "PreviewImage"
})
export default class PreviewImage extends Vue {
  @Prop({ type: Boolean, default: false }) dialogVisible: Boolean

  get newDetail() {
    return this.$store.state.game.newDetail
  }

  isSubmit = false
  isRender = true
  list = []
  gameInfo = {
    icon: "",
    name: "",
    version: ""
  }

  onSubmit() {
    this.isSubmit = true
    this.dialogVisible = false
  }
}
