import { render, staticRenderFns } from "./answerDialog.vue?vue&type=template&id=b55270ea&scoped=true"
import script from "./answerDialog.vue?vue&type=script&lang=ts"
export * from "./answerDialog.vue?vue&type=script&lang=ts"
function injectStyles (context) {
  
  var style0 = require("./answerDialog.vue?vue&type=style&index=0&id=b55270ea&prod&scoped=true&lang=scss")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "b55270ea",
  "0ffab121"
  
)

export default component.exports