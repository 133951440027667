// 第一，菜单 class ，Button 菜单继承 BtnMenu class
export default function($, BtnMenu, tstore?) {
  return class customSplitLine extends BtnMenu {
    constructor(editor) {
      // data-title属性表示当鼠标悬停在该按钮上时提示该按钮的功能简述
      const $elem = $(
        `<div class="w-e-menu" data-title="分割线">
            <i class="w-e-icon-splitline"></i>
         </div>`
      )
      super($elem, editor)
      this._active = false
    }

    /**
     * 菜单点击事件
     */
    clickHandler() {
      const editor = this.editor
      const range = editor.selection.getRange()

      const $selectionElem = editor.selection.getSelectionContainerElem()
      if (!$selectionElem?.length) return

      const $DomElement = $($selectionElem.elems[0])
      const $tableDOM = $DomElement.parentUntil(
        "TABLE",
        $selectionElem.elems[0]
      )
      const $imgDOM = $DomElement.children()

      // 禁止在代码块中添加分割线
      if ($DomElement.getNodeName() === "CODE") return
      // 禁止在表格中添加分割线
      if ($tableDOM && $($tableDOM.elems[0]).getNodeName() === "TABLE") return

      // 禁止在图片处添加分割线
      if (
        $imgDOM &&
        $imgDOM.length !== 0 &&
        $($imgDOM.elems[0]).getNodeName() === "IMG" &&
        !range?.collapsed // 处理光标在 img 后面的情况
      ) {
        return
      }

      this.createSplitLine()
    }
    /**
     * 创建 splitLine
     */
    createSplitLine(): void {
      // 防止插入分割线时没有占位元素的尴尬
      let splitLineDOM: string = `<hr/><p data-we-empty-p=""><br></p>`
      // 火狐浏览器不需要br标签占位
      if (window.GhzsUa && window.GhzsUa.UA.isFirefox) {
        splitLineDOM = "<hr/><p></p>"
      }
      this.editor.cmd.do("insertHTML", splitLineDOM)
    }
    /**
     * 尝试修改菜单激活状态
     */
    tryChangeActive(): void {}
  }
}
