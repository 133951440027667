import { render, staticRenderFns } from "./GhAlert.vue?vue&type=template&id=23552b69&scoped=true"
import script from "./GhAlert.vue?vue&type=script&lang=ts"
export * from "./GhAlert.vue?vue&type=script&lang=ts"
function injectStyles (context) {
  
  var style0 = require("./GhAlert.vue?vue&type=style&index=0&id=23552b69&prod&scoped=true&lang=scss")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "23552b69",
  "ece8ed36"
  
)

export default component.exports