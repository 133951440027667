





































import { IBreadcrumbItem } from "@/components/breadcrumb/extra"
import { Component, Vue } from "vue-property-decorator"
import updateTdk, { formatTDK } from "@/utils/tdk"
@Component({
  name: "Column",
  components: {
    Breadcrumb: () => import("@/components/breadcrumb/breadcrumb.vue"),
    GameItem: () => import("./components/GameItem.vue"),
    LoadMore: () => import("@/components/loadMore/loadMore.vue"),
    NoMoreData: () => import("@/components/noMoreData/noMore.vue"),
    LoadMoreBtn: () => import("@/components/LoadMoreBtn/LoadMoreBtn.vue")
  },
  tdk() {
    return this.$store.state.tdk
  }
})
export default class Column extends Vue {
  get breads(): IBreadcrumbItem[] {
    return [
      { name: "首页", path: "/" },
      { name: "新奇", path: "/column" },
      { name: this.$root.$tstore.modules.column.getters.columnName }
    ]
  }

  loading = false
  page = 1
  page_size = 20
  handleLoadMore() {
    this.page++
    this.getColumnGames()
  }

  sort = "position:1"
  sortOpts = [
    { label: "推荐", value: "position:1" },
    { label: "最新", value: "publish:-1" }
  ]
  onSortChange(c) {
    this.page = 1
    this.resetList()
    this.getColumnGames()
  }

  get showFilter() {
    return this.$root.$tstore.modules.column.getters.showFilter
  }

  get list() {
    return this.$root.$tstore.modules.column.getters.list
  }

  get finished() {
    return this.$root.$tstore.modules.column.getters.finished
  }

  fetchData() {
    return Promise.all([
      this.$root.$tstore.modules.column.actions.FETCH_COLUMN(
        this.$route.params.column_id
      ),
      this.$root.$tstore.modules.tdk.actions.FETCH_TDK({
        type: "column",
        _id_or_name: this.$route.params.column_id
      })
    ])
      .then(() => {
        this.$store.commit(
          "tdk/SET_TDK",
          formatTDK(this.$store.state.tdk, [
            {
              replaceKey: "{专题名称}",
              replaceVal: this.$root.$tstore.modules.column.getters.columnName
            }
          ])
        )
      })
      .finally(() => {
        updateTdk.call(this)
      })
  }

  // ssr hook
  serverPrefetch() {
    return this.fetchData()
  }

  activated() {
    if (!this.list) {
      this.fetchData()
    }
  }

  deactivated() {
    this.resetList()
    this.sort = "position:1"
    this.$root.$tstore.modules.column.mutations.SET_SHOW_FILTER({
      showFilter: null
    })
  }

  resetList() {
    this.$root.$tstore.modules.column.mutations.SET_COLUMN({
      list: null,
      finished: null
    })
  }

  async getColumnGames() {
    this.loading = true
    const res = await this.$root.$tstore.getters.api.column.columnGames(
      this.$route.params.column_id,
      { page: this.page, page_size: this.page_size, sort: this.sort }
    )

    const list = this.list ? this.list.concat(res.data) : res.data

    this.$root.$tstore.modules.column.mutations.SET_COLUMN({
      list,
      finished: list.length >= parseInt(res.headers.total)
    })
    this.loading = false
  }
}
