
























import { debounce } from "@/utils/base"
import { Component, Vue, Prop, Watch } from "vue-property-decorator"

@Component({
  name: "SearchInput",
  components: {}
})
export default class SearchInput extends Vue {
  @Prop({ type: String }) propKeyword: string
  @Prop({ type: String, default: "搜索工具内容" }) placeholder: string
  @Prop({ type: Boolean, default: false }) autoSearch: boolean

  searchIcon: string = require("@/assets/images/icon-搜索-搜索框内置.png")
  clearIcon: string = require("@/assets/images/icon-清除文本.png")

  get showClearIcon() {
    return this.keyword?.length > 0
  }

  keyword = ""
  handleSearch() {
    this.$emit("search", this.keyword)
  }
  handleClear() {
    this.keyword = ""
    // this.$emit("search", this.keyword)
  }

  @Watch("propKeyword", { immediate: true })
  onPropKeyword(c) {
    this.keyword = c
  }

  keywordChange = debounce(this, this.handleSearch, 400)
}
