import { render, staticRenderFns } from "./LoadMoreBtn.vue?vue&type=template&id=0ec4b745&scoped=true"
import script from "./LoadMoreBtn.vue?vue&type=script&lang=ts"
export * from "./LoadMoreBtn.vue?vue&type=script&lang=ts"
function injectStyles (context) {
  
  var style0 = require("./LoadMoreBtn.vue?vue&type=style&index=0&id=0ec4b745&prod&scoped=true&lang=scss")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "0ec4b745",
  "7be74b3e"
  
)

export default component.exports