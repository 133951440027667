










/**
 * @description 返回顶部
 */
import { Component, Vue, Prop } from "vue-property-decorator"
import {
  scrollToTop,
  getScrollEventTarget,
  ScrollElement
} from "@/utils/scroll"

@Component({
  name: "Backtop"
})
export default class Backtop extends Vue {
  @Prop({ type: Number, default: 40 }) bottom!: number // 距离低部的距离
  @Prop({ type: String, default: "app" }) bindId!: string // 节点id

  scrollElement: ScrollElement = null
  opacity = 0

  handleClick() {
    this.$emit("handleClick")
    this.scrollElement = getScrollEventTarget(
      document.querySelector(`#${this.bindId}`)
    )
    scrollToTop(this.scrollElement)
  }
}
