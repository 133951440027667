import { render, staticRenderFns } from "./VideoList.vue?vue&type=template&id=274bc099&scoped=true"
import script from "./VideoList.vue?vue&type=script&lang=ts"
export * from "./VideoList.vue?vue&type=script&lang=ts"
function injectStyles (context) {
  
  var style0 = require("./VideoList.vue?vue&type=style&index=0&id=274bc099&prod&lang=scss&scoped=true")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "274bc099",
  "11b5e48c"
  
)

export default component.exports