















































































/**
 * @description 视频
 */
import { Component, Vue } from "vue-property-decorator"
import downloadDialog, { downloadDialogType } from "@/components/downloadDialog"
import { Swiper, SwiperSlide } from "vue-awesome-swiper"
import "swiper/swiper-bundle.css"
@Component({
  name: "DetailVideos",
  components: {
    HaloImg: () => import("@/components/image/image.vue"),
    Swiper,
    SwiperSlide,
    DPlayerVideo: () => import("@/components/DPlayerVideo/index.vue")
  }
})
export default class DetailVideos extends Vue {
  get newDetail() {
    return this.$root.$tstore.modules.gameList.getters.gameDetail
  }

  get video() {
    const item = this.newDetail.detail_tab.find(v => v.type === "video")
    return item ? item.video : null
  }

  get webDetail() {
    return this.$root.$tstore.modules.gameList.getters.gameAbstract
  }

  showDPlayer = false
  showPrevious = false
  showNext = true

  videos: any[] = null
  mounted() {
    this.getVideoDetail()
  }

  getVideoDetail() {
    Promise.all(
      this.video.map(v => {
        return this.$root.$tstore.getters.api.games.video(v._id)
      })
    ).then(result => {
      this.videos = result.map((res: any) => {
        return {
          ...res.data,
          isPlay: false,
          option: {
            screenshot: false,
            video: {
              _id: res.data._id,
              url: res.data.url,
              pic: res.data.poster,
              type: "auto"
            }
          }
        }
      })
      this.$nextTick(() => {
        this.showDPlayer = true
      })
    })
  }

  handlePlayer(index) {
    const el: any = this.$refs[`dplayerVideo${index}`][0]
    el.load()
    el.dplayer.play()
    this.videos[index].isPlay = true
  }

  swiperOptions = {
    width: 632, // 整个容器宽度
    speed: 500, // 轮播速度
    slidesPerView: "auto", // 根据子元素自适应
    slidesPerGroup: 1, // 一次移动几个
    spaceBetween: 12 // 元素之间的间隔
  }

  get swiper() {
    return (this.$refs.mySwiper as any).swiperInstance
  }
  // 上一张图片
  handlePrevious() {
    this.swiper.slidePrev()
  }

  // 下一张图片
  handleNext() {
    this.swiper.slideNext()
  }

  // 改变前
  transitionStart(e) {
    this.showPrevious = e.translate < 0
    this.showNext = e.translate > -64 || e.activeIndex < this.video.length - 2
  }

  // 下载
  handleDownloadGame() {
    downloadDialog({
      presetData: {
        type: downloadDialogType.GAME_DETAIL,
        gameName: this.webDetail.name
      }
    })
  }
}
