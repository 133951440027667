import { render, staticRenderFns } from "./Videos.vue?vue&type=template&id=8f53a6e4&scoped=true"
import script from "./Videos.vue?vue&type=script&lang=ts"
export * from "./Videos.vue?vue&type=script&lang=ts"
function injectStyles (context) {
  
  var style0 = require("./style.scss?vue&type=style&index=0&id=8f53a6e4&prod&scoped=true&lang=scss&external")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "8f53a6e4",
  "07cd2200"
  
)

export default component.exports