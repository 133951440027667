



















































































































































































































import { Component, Vue, Watch } from "vue-property-decorator"
import downloadDialog, { downloadDialogType } from "@/components/downloadDialog"
import { timeago, formatURLInHTML } from "@/utils/base"
import previewImage from "@/components/previewImage"
import { IQuesCommentItem } from "@/api/modules/question"

interface IListItem extends IQuesCommentItem {
  list_reply: any[]
  isExpand: boolean
  timeago: string
}

@Component({
  name: "Comment",
  components: {
    LoadMore: () => import("@/components/loadMore/loadMore.vue"),
    NoMoreData: () => import("@/components/noMoreData/noMore.vue"),
    Footer: () => import("@/components/footer/footer.vue"),
    LoadMoreBtn: () => import("@/components/LoadMoreBtn/LoadMoreBtn.vue"),
    CommentInput: () => import("@/components/CommentInput")
  }
})
export default class Comment extends Vue {
  formatURLInHTML = formatURLInHTML
  get detail() {
    return this.$root.$tstore.modules.ques.getters.detail
  }

  get commentList() {
    return this.$root.$tstore.modules.ques.getters.commentList
  }

  // ssr hook
  serverPrefetch() {
    return this.fetchData()
  }

  beforeMount() {
    this.loading = true
    if (this.commentList) {
      this.formatData()
    } else {
      this.fetchData()
    }
  }

  fetchData() {
    return this.$root.$tstore.modules.ques.actions
      .FETCH_COMMENT_LIST({
        ques_id: this.$route.params.ques_id,
        params: {
          view: "bbs_web",
          page: this.page,
          page_size: this.pageSize,
          sort: this.sort
        }
      })
      .then(this.formatData)
      .finally(() => {
        this.loading = false
      })
  }
  formatData() {
    if (this.commentList && this.commentList.length === 0) {
      this.finished = true
    }
    this.list = this.list.concat(
      this.commentList.map(v => {
        if (v.attached && !v.list_reply) {
          v.list_reply = v.attached
        }
        if (v.list_reply && v.list_reply.length > 3) {
          v.isExpand = false
        }
        v.timeago = timeago(v.time * 1000)
        return v
      })
    )

    if (!this.$isServer) {
      this.prefetchNextPage()
    }
  }

  destroyed() {
    this.$root.$tstore.modules.ques.mutations.SET_COMMENT_LIST({
      commentList: null
    })
  }

  page = 1
  pageSize = 20
  loading = false
  finished = false
  list: IListItem[] = []
  nextList: IListItem[] = []
  handleLoadMore() {
    this.page++
    if (this.nextList.length > 0) {
      this.list = this.list.concat(this.nextList)
      this.prefetchNextPage()
      return
    }
    this.fetchComments()
  }

  async fetchComments() {
    const res = await this.$root.$tstore.getters.api.question.comments(
      this.$route.params.ques_id,
      {
        view: "bbs_web",
        page: this.page,
        page_size: this.pageSize,
        sort: this.sort
      }
    )
    if (res.data.length === 0) {
      this.finished = true
    } else {
      this.list = this.list.concat(
        res.data.map((v: IListItem) => {
          if (v.list_reply && v.list_reply.length > 3) {
            v.isExpand = false
          }
          v.timeago = timeago(v.time * 1000)
          return v
        })
      )
      this.prefetchNextPage()
    }
  }
  async prefetchNextPage() {
    const nextRes = await this.$root.$tstore.getters.api.question.comments(
      this.$route.params.ques_id,
      {
        view: "bbs_web",
        page: this.page + 1,
        page_size: this.pageSize,
        sort: this.sort
      }
    )
    if (nextRes.data.length === 0) {
      this.finished = true
      this.nextList = []
    } else {
      this.nextList = nextRes.data.map((v: IListItem) => {
        if (v.list_reply && v.list_reply.length > 3) {
          v.isExpand = false
        }
        v.timeago = timeago(v.time * 1000)
        return v
      })
    }
  }

  sortType = 1 // 1 -1
  get sort() {
    return `time.create:${this.sortType}`
  }

  handleDownload() {
    downloadDialog(
      {
        rmAfterDestroy: false,
        presetData: {
          type: downloadDialogType.BBS
        }
      },
      () => {}
    )
  }

  @Watch("sortType")
  onType(c) {
    this.page = 1
    this.list = []
    this.nextList = []
    this.loading = true
    this.finished = false
    this.fetchComments()
  }

  handleMore(item) {
    item.isExpand = true
    this.$forceUpdate()
  }

  handleItem(src) {
    previewImage({
      imgUrl: src
    })
  }

  getStyles(src) {
    return {
      backgroundImage: `url(${src})`
    }
  }
}
