












































/**
 * @description 资讯攻略
 */
import { Component, Vue } from "vue-property-decorator"
import { TimestampFormat } from "@/utils/base"
import downloadDialog, { downloadDialogType } from "@/components/downloadDialog"
import { createArticleLink } from "@/config"
import { Swiper, SwiperSlide } from "vue-awesome-swiper"
import "swiper/swiper-bundle.css"
@Component({
  name: "DetailInfoStrategy",
  components: {
    HaloImg: () => import("@/components/image/image.vue"),
    Swiper,
    SwiperSlide
  }
})
export default class DetailInfoStrategy extends Vue {
  TimestampFormat = TimestampFormat
  get newDetail() {
    return this.$root.$tstore.modules.gameList.getters.gameDetail
  }

  get article() {
    const item = this.newDetail.detail_tab.find(v => v.type === "article")
    return item ? item.article.slice(0, 10) : null
  }

  get webDetail() {
    return this.$root.$tstore.modules.gameList.getters.gameAbstract
  }

  swiperOptions = {
    width: 632, // 整个容器宽度
    speed: 500, // 轮播速度
    slidesPerView: "auto", // 根据子元素自适应
    slidesPerGroup: 2, // 一次移动几个
    spaceBetween: 12 // 元素之间的间隔
  }

  get swiper() {
    return (this.$refs.mySwiper as any).swiperInstance
  }

  showPrevious = false
  showNext = true

  handleItem(id) {
    // 跳转文章
    window.open(`${window.location.origin}/article/${id}`, "_blank")
  }

  // 上一张图片
  handlePrevious() {
    this.swiper.slidePrev()
  }

  // 下一张图片
  handleNext() {
    this.swiper.slideNext()
  }

  // 改变前
  transitionStart(e) {
    this.showPrevious = e.translate < 0
    this.showNext =
      e.translate > -124 || e.activeIndex < this.article.length - 3 // 可视区域占三个位
  }

  // 下载
  handleDownloadGame() {
    downloadDialog(
      {
        presetData: {
          type: downloadDialogType.GAME_DETAIL,
          gameName: this.webDetail.name
        }
      },
      () => {}
    )
  }
}
