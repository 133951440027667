














































/**
 * @description 侧栏-热门内容
 */
import { MHome } from "@/api/api"
import { Component, Vue } from "vue-property-decorator"

@Component({
  name: "HotContent"
})
export default class HotContent extends Vue {
  get list() {
    return this.$root.$tstore.modules.home.getters.contents
  }

  // ssr hook
  serverPrefetch() {
    return this.$root.$tstore.modules.home.actions.FETCH_HOMES()
  }

  beforeMount() {
    if (!this.list) {
      this.$root.$tstore.modules.home.actions.FETCH_HOMES()
    }
  }

  createLink(item: MHome.IContent) {
    if (item.bbs) {
      return `/bbs/thread-${item._seq}`
    } else {
      return `/article/${item._seq}`
    }
  }
}
