import { render, staticRenderFns } from "./RelatedVersion.vue?vue&type=template&id=393f5278&scoped=true"
import script from "./RelatedVersion.vue?vue&type=script&lang=ts"
export * from "./RelatedVersion.vue?vue&type=script&lang=ts"
function injectStyles (context) {
  
  var style0 = require("./RelatedVersion.vue?vue&type=style&index=0&id=393f5278&prod&scoped=true&lang=scss")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "393f5278",
  "01a4bd40"
  
)

export default component.exports