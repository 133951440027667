











































/**
 * @description 相关游戏
 */
import { Component, Vue } from "vue-property-decorator"
import downloadDialog, { downloadDialogType } from "@/components/downloadDialog"

@Component({
  name: "DetailRelateGame",
  components: {
    HaloImg: () => import("@/components/image/image.vue")
  }
})
export default class DetailRelateGame extends Vue {
  get webDetail() {
    return this.$root.$tstore.modules.gameList.getters.gameAbstract
  }
  get newDetail() {
    return this.$root.$tstore.modules.gameList.getters.gameDetail
  }
  get related_version() {
    const item = this.newDetail.detail_tab.find(
      v => v.type === "related_version"
    )
    return item.related_version.map(v => {
      v.new_game_tag = v.new_game_tag.slice(0, 3)
      return v
    })
  }

  handlePreviewMore() {
    this.handleDownload()
  }

  handleDownload() {
    downloadDialog({
      presetData: {
        type: downloadDialogType.GAME_DETAIL,
        gameName: this.webDetail.name
      }
    })
  }
}
