import { render, staticRenderFns } from "./CustomColumn.vue?vue&type=template&id=99453dc6&scoped=true"
import script from "./CustomColumn.vue?vue&type=script&lang=ts"
export * from "./CustomColumn.vue?vue&type=script&lang=ts"
function injectStyles (context) {
  
  var style0 = require("./CustomColumn.vue?vue&type=style&index=0&id=99453dc6&prod&scoped=true&lang=scss")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "99453dc6",
  "50cd20c6"
  
)

export default component.exports