
















































import { throttle } from "@/utils/base"
import { Component, Vue, Prop, Watch } from "vue-property-decorator"

@Component({
  name: "GameCard",
  components: {
    DPlayerVideo: () => import("@/components/DPlayerVideo/index.vue")
  }
})
export default class GameCard extends Vue {
  $refs: { dplayerVideo: any; videoWrapper: HTMLElement }

  @Prop({ type: Object }) data

  image = ""
  poster = ""
  video = ""
  name = ""
  tags = []
  desc = ""
  score = ""
  display_content = "image"
  bgc = ""
  game_id = ""
  _seq = ""

  options = {
    screenshot: false,
    video: {
      _id: "",
      url: "",
      pic: "",
      type: "auto"
    }
  }

  scrollHandle = throttle(this.scrollThrottle, 200, this, true)
  scrollThrottle() {
    const offset = this.$refs.videoWrapper.getBoundingClientRect() // vue中，使用this.$el获取当前组件的根元素
    const offsetTop = offset.top
    const offsetBottom = offset.bottom
    const offsetHeight = offset.height

    // 进入可视区域
    if (offsetTop <= window.innerHeight && offsetBottom >= 0) {
      // console.log("进入可视区域")

      if (offsetBottom < 127 || window.innerHeight - offsetTop < 127) {
        // console.log("小于等于三分之二")

        this.$store.commit("gameList/SET_VIDEO_URL", {
          videoUrl: null,
          videoOffsetTop: null
        })
      } else if (window.innerHeight - offsetTop > 254) {
        // console.log("大于三分之二")

        if (!this.$store.state.gameList.videoUrl) {
          this.$store.commit("gameList/SET_VIDEO_URL", {
            videoUrl: this.options.video.url,
            videoOffsetTop: offsetTop
          })
          if (!this.$refs.dplayerVideo.dplayer) {
            this.$refs.dplayerVideo.load()
          } else {
            this.$refs.dplayerVideo.dplayer.play()
          }
        } else if (offsetTop < this.$store.state.gameList.videoOffsetTop) {
          this.$store.commit("gameList/SET_VIDEO_URL", {
            videoUrl: this.options.video.url,
            videoOffsetTop: offsetTop
          })
          if (!this.$refs.dplayerVideo.dplayer) {
            this.$refs.dplayerVideo.load()
          } else {
            this.$refs.dplayerVideo.dplayer.play()
          }
        }
      }
    } else {
      // console.log("移出可视区域", this.options.video._id)
    }
  }

  onLoad(c) {
    this.image = ""
    ;(this.$el.querySelector(
      ".dplayer-controller"
    ) as HTMLElement).style.display = "none"
    // 这里设置声音会导致播放暂停
    // this.$refs.dplayerVideo.dplayer.volume(0.1, true, true)
  }

  @Watch("data", { immediate: true })
  onData(c) {
    if (c) {
      const { link_game, display_content, recommend_text } = c
      this.display_content = display_content
      this.game_id = link_game._id
      this.name = link_game.name
      this.desc = recommend_text || link_game.brief
      this.score = link_game.new_star
      this.tags = link_game.new_tag_style
        ? link_game.new_tag_style.map(v => ({ name: v.name, _seq: v._seq }))
        : []
      if (display_content === "image") {
        this.image = link_game.home_setting?.image
        this.bgc = link_game.home_setting?.placeholder_color
      }
      this._seq = link_game._seq
    }
  }

  mounted() {
    const { link_game, display_content } = this.data
    if (display_content === "video") {
      this.options.video._id = link_game.top_video?.video_id
      this.options.video.pic = link_game.top_video?.poster
      this.options.video.url = link_game.top_video?.url
      this.image = link_game.home_setting?.image
      this.bgc = link_game.home_setting?.placeholder_color
    }

    this.$nextTick(() => {
      if (!IntersectionObserver && this.options.video.url && !this.$isServer) {
        window.addEventListener("scroll", this.scrollHandle, true)
      }
    })
  }

  destroyed() {
    if (this.options.video.url) {
      window.removeEventListener("scroll", this.scrollHandle, true)
    }
  }

  handleTag(name) {
    this.$router.push(`/tag/${name}`)
  }
}
