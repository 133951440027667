













































/**
 * @description 侧栏内容-热门论坛
 */
import { Component, Vue, Prop } from "vue-property-decorator"

@Component({
  name: "HotBbs"
})
export default class HotBbs extends Vue {
  // 热门论坛列表
  get list() {
    return this.$root.$tstore.modules.bbs.getters.hotBbs
  }

  // ssr hook
  serverPrefetch() {
    return this.fetchData()
  }

  mounted() {
    if (!this.list) {
      this.fetchData()
    }
  }

  fetchData() {
    return this.$root.$tstore.modules.bbs.actions.FETCH_HOT_BBS()
  }

  formatHot(hot: number) {
    return hot.toString().length >= 5
      ? hot.toString().slice(0, hot.toString().length - 4) + "万"
      : hot
  }
}
