























































import { Component, Vue } from "vue-property-decorator"
import updateTdk from "@/utils/tdk"
@Component({
  name: "GameSearchList",
  components: {
    Empty: () => import("@/components/empty/empty.vue"),
    LoadMore: () => import("@/components/loadMore/loadMore.vue")
  },
  tdk() {
    return this.metaInfo
  }
})
export default class GameSearchList extends Vue {
  get metaInfo() {
    return {
      title: `查看 ${this.$route.params.keyword}的搜索结果 | 要好玩，上光环！`,
      description: `光环助手为您找到以下和 ${this.$route.params.keyword}相关的游戏和论坛内容，更多 ${this.$route.params.keyword}内容，尽在光环`,
      keywords: `${this.$route.params.keyword} `
    }
  }

  loading = false
  showNone = false
  list = []
  // ssr hook
  serverPrefetch() {
    return this.fetchData()
  }

  beforeMount() {
    this.fetchData()
  }

  fetchData() {
    this.loading = true
    if (!this.$store.state.gameList.filterTags) {
      return Promise.all([
        this.$store.dispatch("gameList/FETCH_GAME_SEARCH_LIST", {
          keyword: this.$route.params.keyword
        }),
        this.$store.dispatch("gameList/FETCH_FILTER_TAGS")
      ])
        .then(this.formatData)
        .catch(() => {
          // this.$router.push({ name: "404" })
        })
        .finally(() => {
          this.loading = false
          updateTdk.call(this)
        })
    }
    return this.$store
      .dispatch("gameList/FETCH_GAME_SEARCH_LIST", {
        keyword: this.$route.params.keyword
      })
      .then(this.formatData)
      .catch(() => {
        // this.$router.push({ name: "404" })
      })
      .finally(() => {
        this.loading = false
        updateTdk.call(this)
      })
  }

  formatData() {
    try {
      this.list = this.$store.state.gameList.gameSearchList.map(v => {
        if (v.new_tag_style) {
          v.new_tag_style = v.new_tag_style.filter(
            tag => !this.$store.state.gameList.filterTags.includes(tag.name)
          )
        }
        return v
      })
    } catch (error) {
      console.log(error)
    }
    // console.log(this.list)
    if (this.list.length === 0) {
      this.showNone = true
    }
  }

  destroyed() {
    this.$store.commit("gameList/SET_GAME_SEARCH_LIST", {
      gameSearchList: null
    })
  }

  handleTag(name) {
    this.$router.push(`/tag/${name}`)
  }
}
