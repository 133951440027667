
























































































































































































































import { Component, Vue } from "vue-property-decorator"
import downloadDialog, { downloadDialogType } from "@/components/downloadDialog"
import { DateFotmat, formatURLInHTML } from "@/utils/base"
import updateTdk, { formatTDK } from "@/utils/tdk"
import { IBreadcrumbItem } from "@/components/breadcrumb/extra"

@Component({
  name: "GameComments",
  components: {
    HaloImg: () => import("@/components/image/image.vue"),
    LoadMore: () => import("@/components/loadMore/loadMore.vue"),
    NoMoreData: () => import("@/components/noMoreData/noMore.vue"),
    Ellipsis: () => import("@/components/Ellipsis/Ellipsis.vue"),
    LoadMoreBtn: () => import("@/components/LoadMoreBtn/LoadMoreBtn.vue"),
    Breadcrumb: () => import("@/components/breadcrumb/breadcrumb.vue")
  },
  beforeRouteUpdate(to, from, next) {
    this.initData(to.query)
    this.fetchData()
    next()
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === "gameComment") {
      this.saveCache()
    }
    next()
  },
  //进入该页面时，用之前保存的滚动位置赋值
  beforeRouteEnter(to, from, next) {
    next()
  },
  tdk() {
    return this.$store.state.tdk
  }
})
export default class GameComments extends Vue {
  formatURLInHTML = formatURLInHTML

  top = 0
  saveCache() {
    this.top = document.scrollingElement.scrollTop
    this.$root.$tstore.modules.gameList.mutations.SET_GAME_COMMENTS_CACHE({
      gameId: this.$route.params.game_id,
      data: this.$data
    })
  }
  getCache() {
    const data = this.commentsCache[this.$route.params.game_id]
    Object.keys(data).forEach(key => {
      this[key] = data[key]
    })
    if (!this.$isServer) {
      setTimeout(() => {
        window.scrollTo(0, this.top)
      }, 100)
    }
  }

  breads: IBreadcrumbItem[] = []

  get commentsCache() {
    return this.$root.$tstore.modules.gameList.getters.gameCommentsCache
  }

  get comments() {
    return this.$root.$tstore.modules.gameList.getters.gameComments
  }

  // 等级
  get gameRating() {
    return this.$root.$tstore.modules.gameList.getters.gameRating
  }

  // 游戏摘要（主站内容）
  get webDetail() {
    return this.$root.$tstore.modules.gameList.getters.gameAbstract
  }

  // 游戏详情
  get newDetail() {
    return this.$root.$tstore.modules.gameList.getters.gameDetail
  }
  rating = null
  tabIndex = 0
  tabsOption = Array(4)
  selectedFilterType = 0 // 0:默认  1:筛选
  showFilterTool = false
  stars = ["0%", "0%", "0%", "0%", "0%"]
  filterOption = {
    0: {
      data: [
        {
          name: "默认",
          value: null
        },
        {
          name: "热门",
          value: "vote：-1"
        },
        {
          name: "最新",
          value: "time:-1"
        }
      ],
      label: "默认",
      index: null,
      value: null
    }, // 默认
    1: {
      data: [
        {
          name: "全部评论",
          value: null
        },
        {
          name: "1星",
          value: `star:1`
        },
        {
          name: "2星",
          value: `star:2`
        },
        {
          name: "3星",
          value: `star:3`
        },
        {
          name: "4星",
          value: `star:4`
        },
        {
          name: "5星",
          value: `star:5`
        }
      ],
      label: "筛选",
      index: null,
      value: null
    } // 星星
  }
  loading = false
  finished = false
  page = 1
  page_size = 30
  list = []

  // ssr hook
  serverPrefetch() {
    return this.fetchData()
  }

  beforeMount() {
    if (this.commentsCache && this.commentsCache[this.$route.params.game_id]) {
      this.getCache()
    } else if (this.comments) {
      this.formatData()
    } else {
      this.fetchData()
    }
    this.prefetchNextPage()
  }

  destroyed() {
    this.$root.$tstore.modules.gameList.mutations.SET_GAME_COMMENTS({
      gameComments: null
    })
    this.$root.$tstore.modules.gameList.mutations.SET_GAME_RATING({
      gameRating: null
    })
  }

  // 初始化数据
  initData(params?) {
    params = params || this.$route.query

    const TYPE_DATA = this.filterOption[0].data
    this.filterOption[0].index = this.getFilterOptionIndex(params, "type")
    this.filterOption[0].value = TYPE_DATA[this.filterOption[0].index].value
    this.filterOption[0].label = TYPE_DATA[this.filterOption[0].index].name

    const STAR_DATA = this.filterOption[1].data
    this.filterOption[1].index = this.getFilterOptionIndex(params, "star")
    this.filterOption[1].value = STAR_DATA[this.filterOption[1].index].value
    this.filterOption[1].label = STAR_DATA[this.filterOption[1].index].name

    this.page = 1
    this.list = []
    this.nextList = []
    this.finished = false
  }

  getFilterOptionIndex(params, name) {
    return (
      (params[`${name}`] !== null &&
        params[`${name}`] !== undefined &&
        params[`${name}`] !== "null" &&
        params[`${name}`] !== "undefined" &&
        Number(params[`${name}`])) ||
      0
    )
  }

  // 请求数据
  fetchData() {
    this.$loading(true)
    this.loading = true
    return Promise.all([
      this.$root.$tstore.modules.gameList.actions.FETCH_GAME_COMMENTS({
        game_id: this.$route.params.game_id,
        params: {
          page: 1,
          page_size: this.page_size
        }
      }),
      this.$root.$tstore.modules.gameList.actions.FETCH_GAME_RATING({
        game_id: this.$route.params.game_id
      }),
      this.$store.dispatch("tdk/FETCH_SUB_TDK", {
        type: "game",
        _id_or_name: this.$route.params.game_id,
        sub_type: "comment"
      })
    ])
      .then(() => {
        this.formatData()
        this.$store.commit(
          "tdk/SET_TDK",
          formatTDK(this.$store.state.tdk, [
            {
              replaceKey: "{游戏名称}",
              replaceVal: this.webDetail.name
            }
          ])
        )
      })
      .catch(err => {
        console.log(err)
        // this.$router.push("/")
      })
      .finally(() => {
        this.$loading(false)
        updateTdk.call(this)
        this.loading = false
      })
  }

  // 格式数据
  formatData() {
    this.tabsOption = []
    this.tabsOption.push("详情")
    if (
      this.newDetail &&
      this.newDetail.zone_tab &&
      this.newDetail.zone_tab.style === "link"
    ) {
      this.tabsOption.push("专区")
    }
    this.tabsOption.push("评论")
    if (
      this.newDetail &&
      this.newDetail.bbs_tab &&
      this.newDetail.bbs_tab.type === "bbs_detail"
    ) {
      this.tabsOption.push("论坛")
    }

    this.tabIndex = this.tabsOption.findIndex(e => e === "评论")

    this.list = this.list.concat(
      this.comments.map(v => {
        if (typeof v.time === "number") {
          v.time = DateFotmat(new Date(v.time * 1000), "yyyy-MM-dd hh:mm")
        }
        v.isExpand = false
        return v
      })
    )

    if (this.gameRating) {
      this.rating = this.gameRating
      const star = this.rating
      const count = star.one + star.two + star.three + star.four + star.five
      const one = (star.one / count) * 100
      const two = (star.two / count) * 100
      const three = (star.three / count) * 100
      const four = (star.four / count) * 100
      const five = (star.five / count) * 100
      this.stars = [`${five}%`, `${four}%`, `${three}%`, `${two}%`, `${one}%`]
    }

    //breads
    this.breads = [
      { name: "首页", path: "/" },
      { name: this.webDetail.name, path: `/${this.webDetail._seq}` },
      { name: "评论" }
    ]
  }

  // 切换tab
  onTypeChange(val, title) {
    switch (title) {
      case "详情":
        this.$router.push(`/${this.$route.params.game_id}`)
        break
      case "专区":
        window.location.href = this.newDetail.zone_tab.link
        break
      case "评论":
        break
      case "论坛":
        this.$router.push(`/bbs/${this.newDetail.bbs_tab.link_seq}`)
        break
    }
  }

  // 点击选择器
  handleFilterTool(index) {
    if (index === this.selectedFilterType && this.showFilterTool) {
      this.showFilterTool = false
      return
    }
    this.selectedFilterType = index
    this.showFilterTool = true
  }

  // 选中选择器中的某一些
  handleFilterItem(item, index) {
    this.filterOption[this.selectedFilterType].value = item.value
    this.filterOption[this.selectedFilterType].label = item.name
    this.filterOption[this.selectedFilterType].index = index

    const TYPE = this.filterOption[0].index || 0
    const STAE = this.filterOption[1].index || 0

    const params = this.$route.query
    // 防止重复路由
    if (
      params["type"] &&
      params["star"] &&
      params["type"] === TYPE.toString() &&
      params["star"] === STAE.toString()
    ) {
      this.showFilterTool = false
      return
    }

    this.$router.replace(`${this.$route.path}?type=${TYPE}&star=${STAE}`)
    this.showFilterTool = false
  }

  // 下载
  handleDownloadGame() {
    const isLogin = this.$store.getters["userInfo/isLogin"]
    if (isLogin) {
      downloadDialog({
        presetData: {
          type: downloadDialogType.GAME_DETAIL,
          gameName: this.webDetail.name
        }
      })
    } else {
      this.$router.push("/login")
    }
  }
  nextList = []
  async handleLoadMore() {
    this.page++
    if (this.nextList.length > 0) {
      this.list = this.list.concat(this.nextList)
      this.prefetchNextPage()
      return
    }
    const res = await this.$root.$tstore.getters.api.games.gameComments(
      this.$route.params.game_id,
      {
        page: this.page,
        page_size: this.page_size,
        sort: this.filterOption[0].value, // time:-1最新 vote：-1 热门
        filter: this.filterOption[1].value // star:1~5
      }
    )
    if (res.data.length === 0) {
      this.finished = true
    } else {
      this.list = this.list.concat(
        res.data.map(v => {
          if (typeof v.time === "number") {
            v.time = DateFotmat(new Date(v.time * 1000), "yyyy-MM-dd hh:mm")
          }

          v.isExpand = false
          return v
        })
      )
      this.prefetchNextPage()
    }

    this.loading = false
  }
  async prefetchNextPage() {
    const nextRes = await this.$root.$tstore.getters.api.games.gameComments(
      this.$route.params.game_id,
      {
        page: this.page + 1,
        page_size: this.page_size,
        sort: this.filterOption[0].value, // time:-1最新 vote：-1 热门
        filter: this.filterOption[1].value // star:1~5
      }
    )
    if (nextRes.data.length === 0) {
      this.finished = true
      this.nextList = []
    } else {
      this.nextList = nextRes.data.map(v => {
        if (typeof v.time === "number") {
          v.time = DateFotmat(new Date(v.time * 1000), "yyyy-MM-dd hh:mm")
        }
        v.isExpand = false
        return v
      })
    }
  }

  // 查看某一项全部文本内容
  handleExpandAll(index) {
    this.list[index].isExpand = true
  }
}
