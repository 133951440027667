






































import { Component, Vue, Watch } from "vue-property-decorator"
import updateTdk from "@/utils/tdk"

@Component({
  name: "BbsRecommendContents",
  components: {
    CardItem: () => import("@/components/bbsCardItem/bbsCardItem.vue"),
    LoadMoreBtn: () => import("@/components/LoadMoreBtn/LoadMoreBtn.vue"),
    NoMoreData: () => import("@/components/noMoreData/noMore.vue"),
    VideoCard: () => import("@/views/Bbs/components/ContentList/VideoCard.vue")
  },
  tdk() {
    return this.$store.state.tdk
  }
})
export default class BbsRecommendContents extends Vue {
  get recommendContents() {
    return this.$root.$tstore.modules.bbs.getters.recommendContents
  }

  // ssr hook
  serverPrefetch() {
    return this.fetchData()
  }

  beforeMount() {
    if (this.recommendContents && this.recommendContents.data.length === 0) {
      this.fetchData()
    } else {
      this.$store
        .dispatch("tdk/FETCH_TDK", {
          type: "manual",
          _id_or_name: encodeURI("论坛集合页")
        })
        .finally(() => {
          updateTdk.call(this)
        })
      this.checkNextPage()
    }
  }

  fetchData() {
    return Promise.all([
      this.$root.$tstore.modules.bbs.actions.FETCH_RECOMMEND_CONTENTS(),
      this.$store.dispatch("tdk/FETCH_TDK", {
        type: "manual",
        _id_or_name: encodeURI("论坛集合页")
      })
    ])
      .then(() => {
        this.checkNextPage()
      })
      .finally(() => {
        updateTdk.call(this)
      })
  }

  createUrl(item) {
    return `/bbs/thread-${item._seq || item._id}`
  }

  /** 客户端请求数据 */
  nextList = []
  checkNextPage() {
    if (this.nextList.length === 0) {
      this.$root.$tstore.modules.bbs.mutations.SET_RECOMMEND_CONTENTS({
        page: this.recommendContents.page + 1
      })
      this.getContentList()
    }
  }
  async getContentList() {
    const res = await this.$root.$tstore.getters.api.bbs.recommendContents({
      page: this.recommendContents.page,
      page_size: this.recommendContents.page_size,
      sort: "time.comment:-1"
    })
    if (res.data.length === 0) {
      this.$root.$tstore.modules.bbs.mutations.SET_RECOMMEND_CONTENTS({
        finished: true
      })
      this.nextList = []
    } else {
      this.nextList = res.data
    }
  }
  handleLoadMore() {
    this.$root.$tstore.modules.bbs.mutations.SET_RECOMMEND_CONTENTS({
      data: this.recommendContents.data.concat(this.nextList),
      page: this.recommendContents.page + 1
    })
    this.getContentList()
  }
}
