































































































import { Component, Vue, Watch } from "vue-property-decorator"

import downloadDialog, { downloadDialogType } from "@/components/downloadDialog"
import { timeago } from "@/utils/base"

@Component({
  name: "Comment",
  components: {
    LoadMore: () => import("@/components/loadMore/loadMore.vue"),
    NoMoreData: () => import("@/components/noMoreData/noMore.vue"),
    LoadMoreBtn: () => import("@/components/LoadMoreBtn/LoadMoreBtn.vue"),
    CommentInput: () => import("@/components/CommentInput")
  }
})
export default class Comment extends Vue {
  get count() {
    return this.$root.$tstore.modules.article.getters.count
  }

  get commentList() {
    return this.$root.$tstore.modules.article.getters.commentList
  }

  // ssr hook
  serverPrefetch() {
    return this.fetchData()
  }
  async beforeMount() {
    if (this.commentList) {
      this.formatData()
    } else {
      await this.fetchData()
    }
    this.prefetchNextPage()
  }
  fetchData() {
    this.loading = true
    return new Promise((resolve, reject) => {
      Promise.all([
        this.$root.$tstore.modules.article.actions.FETCH_COMMENT_LIST({
          article_id: this.$route.params.article_id,
          params: {
            page: this.page,
            pageSize: this.pageSize
          }
        }),
        this.$root.$tstore.modules.article.actions.FETCH_COUNT({
          article_id: this.$route.params.article_id
        })
      ])
        .then(() => {
          this.formatData()
          resolve("")
        })
        .finally(() => {
          this.loading = false
        })
    })
  }
  formatData() {
    this.list = this.list.concat(
      this.commentList.map(v => {
        if (v.list_reply && v.list_reply.length > 3) {
          v.isExpand = false
        }
        v.timeago = timeago(v.time * 1000)
        return v
      })
    )
  }

  destroyed() {
    this.$root.$tstore.modules.article.mutations.SET_COMMENT_LIST({
      commentList: null
    })
  }

  page = 1
  pageSize = 20
  loading = false
  finished = false
  list = []
  nextList = []
  async handleLoadMore() {
    this.page++
    if (this.nextList.length > 0) {
      this.list = this.list.concat(this.nextList)
      this.prefetchNextPage()
      return
    }
    const res = await this.$root.$tstore.getters.api.article.comments(
      this.$route.params.article_id,
      {
        page: this.page,
        page_size: this.pageSize
      }
    )
    if (res.data.length === 0) {
      this.finished = true
    } else {
      this.list = this.list.concat(
        res.data.map(v => {
          if (v.list_reply && v.list_reply.length > 3) {
            v.isExpand = false
          }
          v.timeago = timeago(v.time * 1000)
          return v
        })
      )
    }
  }
  async prefetchNextPage() {
    const nextRes = await this.$root.$tstore.getters.api.article.comments(
      this.$route.params.article_id,
      {
        page: this.page + 1,
        page_size: this.pageSize
      }
    )
    if (nextRes.data.length === 0) {
      this.finished = true
      this.nextList = []
    } else {
      this.nextList = nextRes.data.map(v => {
        if (v.list_reply && v.list_reply.length > 3) {
          v.isExpand = false
        }
        v.timeago = timeago(v.time * 1000)
        return v
      })
    }
  }

  handleDownload() {
    downloadDialog(
      {
        rmAfterDestroy: false,
        presetData: {
          type: downloadDialogType.BBS
        }
      },
      () => {}
    )
  }

  @Watch("commentList")
  onCommentList(c) {
    if (c && c.length === 0) {
      this.finished = true
    }
  }
}
