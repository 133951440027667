



























































































































































































































































/**
 * @description 详情顶部
 */
import { Component, Vue, Prop } from "vue-property-decorator"
import downloadDialog, { downloadDialogType } from "@/components/downloadDialog"
import { TimestampFormat } from "@/utils/base"
import TagsDLG from "@/components/tagsDLG"

@Component({
  name: "DetailHeader",
  components: {
    HaloImg: () => import("@/components/image/image.vue"),
    DPlayerVideo: () => import("@/components/DPlayerVideo/index.vue")
  }
})
export default class DetailHeader extends Vue {
  downloadDialogType = downloadDialogType
  TimestampFormat = TimestampFormat

  @Prop({ type: Boolean, default: false }) showHeaderFixed?: boolean

  // 游戏摘要（内容主站）
  get webDetail() {
    return this.$root.$tstore.modules.gameList.getters.gameAbstract
  }

  // 游戏详情
  get newDetail() {
    return this.$root.$tstore.modules.gameList.getters.gameDetail
  }

  get ranking() {
    return this.newDetail.ranking || null
  }

  get topVideoOpts() {
    if (this.newDetail) {
      if (this.newDetail.top_video) {
        return {
          screenshot: false,
          video: {
            _id: this.newDetail.top_video.video_id,
            url: this.newDetail.top_video.url,
            pic: this.newDetail.top_video.poster,
            type: "auto"
          }
        }
      }
    }
    return {}
  }

  isPlay = false
  timer = null

  get downloadStatusText() {
    let text = "下载"
    if (this.webDetail.download_status) {
      switch (this.webDetail.download_status) {
        case "on":
          text = "下载"
          break
        case "off":
          text = this.webDetail.download_off_text || "下载"
          break
        case "appointment":
          text = "预约"
          break
        case "demo":
          text = "试玩"
          break
        case "link":
          text = this.webDetail.h5_link.text || "下载"
          break
      }
    }
    return text
  }

  mounted() {
    this.isPlay = true
    // 头部视频自动播放
    this.timer = setInterval(() => {
      if (this.$refs.dplayerVideo) {
        this.$refs.dplayerVideo.load()
        this.$refs.dplayerVideo.dplayer.volume(0.1, true, true)
        this.$refs.dplayerVideo.dplayer.play()
        clearInterval(this.timer)
      }
    }, 100)
  }

  handleBack() {
    this.$router.go(-1)
  }

  $refs: { dplayerVideo: any; videoWrapper: HTMLElement; headerFixed: any }

  // 视频结束后重新播放
  videoEnded() {
    this.$refs.dplayerVideo.dplayer.play()
  }

  // 下载
  handleDownloadGame(type = 0) {
    downloadDialog({
      presetData: {
        type,
        gameName: this.webDetail.name
      }
    })
  }

  handleMoreTags() {
    TagsDLG(
      {
        rmAfterDestroy: true,
        presetData: {
          gameName: this.webDetail.name,
          tags: this.newDetail.new_tag_style
        }
      },
      ({ tag }) => {
        this.$router.push(`/tag/${tag}`)
      }
    )
  }
}
