import { render, staticRenderFns } from "./UpdateText.vue?vue&type=template&id=7082849e&scoped=true"
import script from "./UpdateText.vue?vue&type=script&lang=ts"
export * from "./UpdateText.vue?vue&type=script&lang=ts"
function injectStyles (context) {
  
  var style0 = require("./UpdateText.vue?vue&type=style&index=0&id=7082849e&prod&scoped=true&lang=scss")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "7082849e",
  "13298080"
  
)

export default component.exports