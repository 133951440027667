













































import { MBbsContent } from "@/api/api"
import { Component, Vue, Watch, Prop } from "vue-property-decorator"

@Component({
  name: "QuesList",
  beforeRouteLeave(to, from, next) {
    this.$root.$tstore.modules.content.mutations.RESET_QUES()
    next()
  },
  components: {
    CardItem: () => import("@/components/bbsCardItem/bbsCardItem.vue"),
    LoadMore: () => import("@/components/loadMore/loadMore.vue"),
    NoMoreData: () => import("@/components/noMoreData/noMore.vue"),
    Img: () => import("@/components/image/image.vue"),
    Empty: () => import("@/components/empty/empty.vue"),
    LoadMoreBtn: () => import("@/components/LoadMoreBtn/LoadMoreBtn.vue")
  }
})
export default class QuesList extends Vue {
  @Prop({ type: String, default: "" }) sort

  get ques() {
    return this.$root.$tstore.modules.content.getters.ques
  }

  loading = false

  nextList = []

  beforeMount() {
    if (this.ques.data.length === 0) {
      this.getList()
    }
  }

  /** 客户端请求数据 */
  async getList() {
    this.loading = true
    const res = await this.$root.$tstore.getters.api.content.questionsList(
      this.$route.params.bbs_id,
      {
        sort: this.sort,
        page: this.ques.page,
        page_size: this.ques.page_size
      }
    )

    if (res.data.length > 0) {
      this.$root.$tstore.modules.content.mutations.SET_QUES({
        data: this.ques.data.concat(res.data),
        page: this.ques.page + 1
      })
      this.prefetchNextPage()
    } else {
      this.$root.$tstore.modules.content.mutations.SET_QUES({
        finished: true
      })
    }
    this.loading = false
  }

  handleLoadMore() {
    this.$root.$tstore.modules.content.mutations.SET_QUES({
      data: this.ques.data.concat(this.nextList),
      page: this.ques.page + 1
    })
    this.prefetchNextPage()
  }

  async prefetchNextPage() {
    const nextRes = await this.$root.$tstore.getters.api.content.questionsList(
      this.$route.params.bbs_id,
      {
        sort: this.sort,
        page: this.ques.page,
        page_size: this.ques.page_size
      }
    )
    if (nextRes.data.length === 0) {
      this.$root.$tstore.modules.content.mutations.SET_QUES({
        finished: true
      })
      this.nextList = []
    } else {
      this.nextList = nextRes.data
    }
  }

  @Watch("sort")
  async onSort(_) {
    if (_) {
      this.$root.$tstore.modules.content.mutations.RESET_QUES()
      this.nextList = []
      this.loading = true
      await this.getList()
      this.$root.$tstore.modules.content.mutations.SET_QUES({
        data: this.ques.data.concat(this.nextList),
        page: this.ques.page + 1
      })
      this.loading = false
    }
  }
}
