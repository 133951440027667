




















/**
 * @description 省略文案、展开全部
 */
import { Component, Vue, Prop } from "vue-property-decorator"

@Component({
  name: "TextEllipsis"
})
export default class TextEllipsis extends Vue {
  @Prop({ type: String, default: "" }) text: string // 文案内容
  @Prop({ type: Number, default: 22 }) lineHeight!: number // 行高，以px为单位
  @Prop({ type: Number, default: 3 }) lineClamp!: number // 行数
  @Prop({ type: Boolean, default: false }) isExpand!: boolean // 是否展开全部
  @Prop({ type: String, default: "全部" }) expandChar!: string // 展开文案

  showExpand = false

  mounted() {
    // 检查文本展开
    this.$nextTick(() => {
      const _text: any = this.$refs.textEllipsis
      const _height = (_text && _text.offsetHeight) || 0
      // 如果默认展开全部则直接展开全部，反之根据实际高度判断是否展开
      if (!this.isExpand) {
        this.showExpand = _height > this.lineHeight * this.lineClamp
      }
    })
  }

  handleExpandAll() {
    this.showExpand = false
    this.$emit("click")
  }
}
