











































import { IBreadcrumbItem } from "@/components/breadcrumb/extra"
import { Component, Vue } from "vue-property-decorator"
import updateTdk, { formatTDK } from "@/utils/tdk"

@Component({
  name: "Tag",
  components: {
    GameRecommend: () => import("@/components/GameRecommend/GameRecommend.vue"),
    ScanDownload: () => import("@/components/scanDownload/scanDownload.vue"),
    FooterCard: () => import("@/components/footerCard/footerCard.vue"),
    GameItem: () => import("./components/GameItem.vue"),
    Breadcrumb: () => import("@/components/breadcrumb/breadcrumb.vue"),
    Empty: () => import("@/components/empty/empty.vue"),
    LoadMore: () => import("@/components/loadMore/loadMore.vue")
  },
  beforeRouteUpdate(to, from, next) {
    if (to.params) {
      this.sort = "download:-1"
      this.fetchData(to.params.tag_name)
    }
    next()
  },
  tdk() {
    return this.$store.state.tdk
  }
})
export default class Tag extends Vue {
  breads: IBreadcrumbItem[] = [{ name: "首页", path: "/" }]

  loading = false

  sort = "download:-1"
  tabsOptions = [
    { label: "推荐", value: "download:-1" },
    { label: "最新", value: "publish:-1" }
  ]

  get tags() {
    return this.$root.$tstore.modules.game.getters.tags
  }

  handleBack() {
    this.$router.go(-1)
  }

  // ssr hook
  serverPrefetch() {
    return this.fetchData()
  }

  async beforeMount() {
    if (!this.tags) {
      try {
        await this.fetchData()
      } catch (error) {
        this.$router.push({ name: "404" })
      }
    }
    this.breads = [
      { name: "首页", path: "/" },
      { name: this.$root.$tstore.modules.game.getters.tagName }
    ]
  }

  fetchData(tag?: string) {
    return new Promise(async (resolve, reject) => {
      try {
        await this.$root.$tstore.modules.game.actions.FETCH_TAGS({
          filter: `tag_seq:${tag || this.$route.params?.tag_name}`,
          sort: this.sort,
          view: "web"
        })

        const tdkResp = await this.$root.$tstore.getters.api.tdk.typeSeo(
          "tag",
          encodeURI(tag || this.$route.params?.tag_name)
        )
        const { keyword, description, title, name } = tdkResp.data
        this.$root.$tstore.modules.tdk.mutations.SET_TDK(
          formatTDK({ keywords: keyword, description, title }, [
            {
              replaceKey: "{标签名称}",
              replaceVal: name
            }
          ])
        )
        updateTdk.call(this)

        this.$root.$tstore.modules.game.mutations.SET_TAG_NAME({ name })

        this.breads = [{ name: "首页", path: "/" }, { name }]

        resolve("")
      } catch (error) {
        reject(error)
      }
    })
  }

  async onSort() {
    this.loading = true
    await this.$root.$tstore.modules.game.actions.FETCH_TAGS({
      filter: this.$route.params.tag_name,
      sort: this.sort,
      view: "web"
    })
    this.loading = false
  }

  destroyed() {
    this.$root.$tstore.modules.game.mutations.SET_TAGS({ data: null })
  }
}
