




























/**
 * @description 扫码下载右边侧栏的视图组件
 */
import { Component, Vue, Prop } from "vue-property-decorator"
// import shareDialog from "../shareDialog"
import downloadDialog, { downloadDialogType } from "@/components/downloadDialog"

@Component({
  name: "ScanDownload"
})
export default class ScanDownload extends Vue {
  handleShare() {
    // shareDialog(
    //   {
    //     rmAfterDestroy: false,
    //     presetData: {}
    //   },
    //   () => {}
    // )
    downloadDialog(
      {
        rmAfterDestroy: false,
        presetData: {
          type: downloadDialogType.COMMON
        }
      },
      () => {}
    )
  }
}
