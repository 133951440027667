





































/**
 * @description 游戏攻略专区
 */
import { Component, Vue, Prop, Watch } from "vue-property-decorator"

@Component({
  name: "GameZone",
  components: {
    Img: () => import("@/components/image/image.vue")
  }
})
export default class GameZone extends Vue {
  get bbs() {
    return this.$store.state.bbs.bbs
  }
  show = true
  bannerUrl = ""

  async getImage(gameId) {
    const res = await this.$root.$tstore.getters.api.bbs.gameZoneImage(gameId)
    this.bannerUrl = res.data
  }

  /** 跳转到专区 */
  handleGoZone() {
    window.location.href = this.bbs.zone_tab.link
  }

  @Watch("bbs.game_id")
  onGameId(gameId) {
    if (gameId) {
      this.getImage(gameId)
    }
  }
}
