import Vue from "vue"
import Dialog from "./Dialog.vue"

let DialogConstructor = Vue.extend(Dialog)

let instance
let timer

const defaultConifg = {
  rmAfterDestroy: true,
  list: [],
  gameInfo: {}
}

const removableDialog = options => {
  const mergeOptions = Object.assign({}, defaultConifg, options)

  const { rmAfterDestroy, list, gameInfo } = mergeOptions

  if (!instance) {
    instance = new DialogConstructor({
      watch: {
        dialogVisible: {
          handler(change) {
            this.dialogVisible = change

            // 关闭之后销毁实例
            if (rmAfterDestroy) {
              if (change === false) {
                this.$nextTick(() => {
                  instance.isRender = false
                  this.$nextTick(() => {
                    instance.$destroy()
                    instance = null
                  })
                })
              }
            }
          }
        }
      }
    })

    instance.$mount()

    document.body.appendChild(instance.$el)

    if (!instance.dialogVisible || (instance && !instance.dialogVisible)) {
      Vue.nextTick(() => {
        Vue.set(instance, "list", list)
        Vue.set(instance, "gameInfo", gameInfo)
        instance.dialogVisible = true
      })
    }
  }

  return instance
}

export default removableDialog
