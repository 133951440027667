





































import { Component, Vue, Prop } from "vue-property-decorator"
import downloadDialog, { downloadDialogType } from "@/components/downloadDialog"

@Component({
  name: "Top",
  components: {
    Ellipsis: () => import("@/components/Ellipsis/Ellipsis.vue"),
    CustomColumn: () => import("./CustomColumn/CustomColumn.vue")
  }
})
export default class Top extends Vue {
  @Prop({ type: Array, default: () => [] })
  top: any[]

  showExpands = false
  // 游戏摘要（主站内容）
  get webDetail() {
    return this.$root.$tstore.modules.gameList.getters.gameAbstract
  }
  // 全版本下载
  handleDownload() {
    downloadDialog({
      presetData: {
        type: downloadDialogType.GAME_DETAIL,
        gameName: this.webDetail.name
      }
    })
  }
}
