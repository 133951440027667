import { IHotBbsItem, IRecommendContentItem } from "@/api/api"
import { ITableData, TableData } from "@/utils/base"
import { ActionContext } from "vuex"
import { IRootState } from ".."

interface IState {
  bbs: any
  categories: any
  recommends: any
  contentSearchList: any
  gameSearchList: any
  bbsName: any
  HotContent: any
  videoDraftList: IVideoDraft[]
  officialBbsList: IOfficialBbsItem[]
  recommendContents: ITableData<IRecommendContentItem>
  hotBbs: IHotBbsItem[]
  sections: ISectionItem[]
}

type Context = ActionContext<IState, IRootState>

export const bbs = {
  namespaced: true,
  state: () => ({
    bbs: null, // 某游戏论坛
    categories: null, // 论坛分类 （已作废）
    recommends: null, // 推荐列表 （已作废）
    contentSearchList: null, // 内容搜索列表
    gameSearchList: null, // 游戏搜索列表
    bbsName: null, // 用作seo时记录是否有点击过论坛
    HotContent: null, // 热门内容
    videoDraftList: null, // 视频贴草稿箱
    officialBbsList: null, // 官方论坛列表
    recommendContents: new TableData({}), // 首页帖子列表
    hotBbs: null,
    sections: null
  }),
  getters: {
    categories: state => state.categories,
    bbs: state => state.bbs,
    videoDraftList: state => state.videoDraftList,
    officialBbsList: state => state.officialBbsList,
    recommendContents: (state: IState) => state.recommendContents,
    hotBbs: (state: IState) => state.hotBbs,
    sections: (state: IState) => state.sections
  },
  mutations: {
    SET_BBS: (state, { bbs }) => {
      state.bbs = bbs
    },
    SET_BBS_CATEGORIES: (state, { list }) => {
      state.categories = list
    },
    SET_RECOMMENDS: (state, { list }) => {
      state.recommends = list
    },
    SET_GAME_SEARCH: (state, { list }) => {
      state.gameSearchList = list
    },
    SET_CONTENT_SEARCH: (state, { list }) => {
      state.contentSearchList = list
    },
    SET_BBS_NAME: (state, { bbsName }) => {
      state.bbsName = bbsName
    },
    SET_HOT_COMTENT: (state, { list }) => {
      state.HotContent = list
    },
    SET_VIDEO_DRAFT_LIST: (state, { videoDraftList }) => {
      state.videoDraftList = videoDraftList
    },
    SET_OFFICIAL_BBS_LIST: (state, { officialBbsList }) => {
      state.officialBbsList = officialBbsList
    },
    SET_RECOMMEND_CONTENTS: (state, data: IState["recommendContents"]) => {
      state.recommendContents = Object.assign(state.recommendContents, data)
    },
    SET_HOT_BBS: (state, { list }) => {
      state.hotBbs = list
    },
    SET_SECTIONS: (state, { list }) => {
      state.sections = list
    }
  },
  actions: {
    FETCH_BBS: ({ commit, rootState: { api } }, { id }) => {
      return api.bbs
        .detail(id)
        .then(res => commit("SET_BBS", { bbs: res.data }))
    },
    FETCH_BBS_CATEGORIES: ({ commit, rootState: { api } }) => {
      return api.bbs
        .categories({
          view: "bbs_web"
        })
        .then(res => commit("SET_BBS_CATEGORIES", { list: res.data }))
    },
    FETCH_RECOMMENDS: ({ commit, rootState: { api } }) => {
      return api.bbs
        .recommends()
        .then(res => commit("SET_RECOMMENDS", { list: res.data }))
    },
    FETCH_GAME_SEARCH: (
      { commit, rootState: { api } },
      { keyword, bbs_id, type, page }
    ) => {
      return api.content
        .searchName({
          keyword,
          bbs_id,
          type,
          page
        })
        .then(res => commit("SET_GAME_SEARCH", { list: res.data }))
    },
    FETCH_CONTENT_SEARCH: (
      { commit, rootState: { api } },
      { keyword, bbs_id, type, page }
    ) => {
      return api.content
        .search({
          keyword,
          bbs_id,
          type,
          page
        })
        .then(res => commit("SET_CONTENT_SEARCH", { list: res.data }))
    },
    FETCH_HOT_COMTENT: ({ commit, rootState: { api } }) => {
      return api.content.hotContent().then(res => {
        commit("SET_HOT_COMTENT", { list: res.data })
      })
    },
    FETCH_VIDEO_DRAFT_LIST: (
      { commit, rootState: { api } }: Context,
      { user_id }
    ) => {
      return api.video.drafts.list(user_id).then(res => {
        commit("SET_VIDEO_DRAFT_LIST", { list: res.data })
      })
    },
    FETCH_OFFICIAL_BBS: ({ commit, rootState: { api } }) => {
      return api.bbs
        .officialList()
        .then(res =>
          commit("SET_OFFICIAL_BBS_LIST", { officialBbsList: res.data })
        )
    },
    FETCH_RECOMMEND_CONTENTS: ({ commit, rootState: { api } }) => {
      return api.bbs
        .recommendContents({ page: 1, page_size: 30, sort: "time.comment:-1" })
        .then(res => {
          commit("SET_RECOMMEND_CONTENTS", {
            data: res.data,
            page: 1,
            page_size: 30,
            finished: res.data.length < 30,
            total: res.headers.total || 0
          })
        })
    },
    FETCH_HOT_BBS: ({ commit, rootState: { api } }) => {
      return api.bbs
        .hotBbs()
        .then(res => commit("SET_HOT_BBS", { list: res.data }))
    },
    FETCH_SECTIONS: ({ commit, rootState: { api } }, { bbs_id }) => {
      return api.bbs
        .sectionsList(bbs_id)
        .then(res => commit("SET_SECTIONS", { list: res.data }))
    }
  }
}

interface IVideoDraft {
  _id: string
  title: string
  poster: string
  url: string
  local_path: string
  format: string
  size: number
  length: number
  des: string
  game_id: string
  bbs_id: string
  type: string
  tag_activity_id: string
  tag_activity_name: string
  original: string
  source: string
  user: User
  time: Time
  video_info: Videoinfo
}
interface Videoinfo {
  width: number
  height: number
}
interface Time {
  create: number
  update: number
}
interface User {
  _id: string
  name: string
  icon: string
}

interface IOfficialBbsItem {
  _id: string
  name: string
  icon: string
  me: IMe
}

interface IMe {
  is_follow_bbs: boolean
}

interface ISectionItem {
  icon: string
  icon_type: string
  name: string
  permissions: string
  _id: string
}
