

























































































































































/**
 * @description 搜索结果卡片
 */
import { Component, Vue, Prop } from "vue-property-decorator"
import { timeago } from "@/utils/base"

@Component({
  name: "ResultCardItem",
  filters: {
    format: (value: number) => timeago(value * 1000)
  },
  components: {
    HaloImage: () => import("@/components/image/image.vue")
  }
})
export default class ResultCardItem extends Vue {
  @Prop({ type: String, default: "" }) username: string // 用户名称
  @Prop({ type: String, default: "" }) avatar: string // 用户头像
  @Prop({ type: String, default: "" }) auth: string // 个人认证
  @Prop({ type: String, default: "" }) title: string // 标题
  @Prop({ type: String, default: "" }) desc: string // 描述
  @Prop({ type: Number, default: null }) time: number // 时间
  @Prop({ type: Number, default: 0 }) vote: number // 点赞量
  @Prop({ type: Number, default: 0 }) comment_count: number // 时间
  @Prop({ type: Array, default: () => [] }) images!: any // 图片
  @Prop({ type: Array, default: () => [] }) images_info!: any // 图片尺寸信息
  @Prop({ type: Array, default: () => [] }) videos!: any // 视频
  @Prop({ type: String, default: "" }) badge!: string // 用户徽章
  @Prop({ type: String, default: "" }) type: string // 类型

  get isQues() {
    return this.type === "bbs_question" || this.type === "question"
  }

  get _videos() {
    const imgLen = 3 - this.images.length
    const end = imgLen < 0 ? 0 : imgLen
    return this.videos.slice(0, end)
  }

  // 是否是长图
  isLongImg(index: number) {
    if (this.images_info[index]) {
      let width
      let height
      width = this.images_info[index].width
      height = this.images_info[index].height
      const scale = height / width
      if (scale > 2) {
        return true
      }
    }

    return false
  }

  // 图片适配
  getSuitImgSize(index: number, type?: string) {
    let w = 120
    let h = 120
    let width
    let height

    if (type === "videos") {
      const vIndex: number = index - this.images.length
      if (this.videos[vIndex]) {
        width = this.videos[vIndex].width
        height = this.videos[vIndex].height
      }
    } else {
      if (this.images_info[index]) {
        width = this.images_info[index].width
        height = this.images_info[index].height
      }
    }
    // 图片数量很多，则固定尺寸：120*120
    // 图片只有一张则计算比例，按照比例展示
    if (this.images.length + this.videos.length === 1) {
      const scale = height / width
      if (scale > 18 / 9) {
        // 比例 18:9
        w = 248
        h = 330
      } else if (scale <= 3 / 2) {
        // 比例 3:2
        w = 248
        h = 164
      } else if (scale > 3 / 2 && scale <= 18 / 9) {
        // 比例 3:2 ～ 18:9
        w = 248
        h = 248
      }
    }
    return {
      width: `${w}px`,
      height: `${h}px`
    }
  }
}
