






























import { Component, Vue } from "vue-property-decorator"

@Component({
  name: "Error",
  components: {
    Header: () => import("@/components/header/header.vue"),
    BackTop: () => import("@/components/backtop/backtop.vue"),
    Menu: () => import("@/components/menu/menu.vue"),
    Footer: () => import("@/components/footer/footer.vue"),
    GameRecommend: () => import("@/components/GameRecommend/GameRecommend.vue"),
    ScanDownload: () => import("@/components/scanDownload/scanDownload.vue")
  }
})
export default class Error extends Vue {}
