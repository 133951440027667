

























/**
 * @description 更新开服
 */
import { Component, Vue } from "vue-property-decorator"
import { TimestampFormat } from "@/utils/base"
import downloadDialog, { downloadDialogType } from "@/components/downloadDialog"

@Component({
  name: "Server"
})
export default class Server extends Vue {
  TimestampFormat = TimestampFormat
  get newDetail() {
    return this.$root.$tstore.modules.gameList.getters.gameDetail
  }

  get calendar() {
    const item = this.newDetail.detail_tab.find(v => v.type === "server")
    return item ? item.server : null
  }

  get webDetail() {
    return this.$root.$tstore.modules.gameList.getters.gameAbstract
  }

  handlePreviewMore() {
    downloadDialog({
      rmAfterDestroy: false,
      presetData: {
        type: downloadDialogType.GAME_DETAIL,
        gameName: this.webDetail.name
      }
    })
  }
}
