





















































import { downloadLog } from "@/utils/log"
import { Component, Prop, Vue } from "vue-property-decorator"
import { downloadDialogType } from "."

interface IDialogDataPresets {
  type: downloadDialogType
  gameName: string
  tipText: string
}

@Component({
  name: "downloadDialog"
})
export default class downloadDialog extends Vue {
  // 通用弹窗的JS数据和方法
  cancel() {
    this.isSubmit = false
    this.dialogVisible = false
  }

  onSubmit() {
    this.isSubmit = true
    this.dialogVisible = false
  }

  dialogDataPresets: IDialogDataPresets = {
    type: downloadDialogType.COMMON, // 0：下载该游戏   1：体验完成功能
    gameName: "",
    tipText: ""
  }

  isRender: boolean = true
  isSubmit: boolean = false

  @Prop()
  dialogVisible: boolean = false

  get oneRowTitle() {
    const { type } = this.dialogDataPresets
    return (
      type === downloadDialogType.COMMON ||
      type === downloadDialogType.GAME_DETAIL ||
      type === downloadDialogType.BBS
    )
  }

  downloadDialogType = downloadDialogType
  title_text = {
    [downloadDialogType.COMMON]: "安装光环助手",
    [downloadDialogType.GAME_DETAIL_DOWNLOAD]: "请安装光环助手后下载该游戏",
    [downloadDialogType.GAME_DETAIL]: "请安装光环助手体验完整功能",
    [downloadDialogType.GAME_DETAIL_LIBAO]: "请安装光环助手领取该礼包",
    [downloadDialogType.BBS]: "请安装光环助手，参与论坛讨论互动"
  }

  // 下载软件apk
  handleDownload() {
    downloadLog()
    window.open("https://download.ghzs.com/ghzs?channel=GH_112", "_blank")
    this.onSubmit()
  }

  // // 用户协议
  // jumpUserPolicy() {
  //   window.open("https://resource.ghzs.com/page/user_protocol/disclaimer.html")
  // }

  // // 隐私政策
  // jumpPrivacyPolicy() {
  //   window.open(
  //     "https://resource.ghzs.com/page/privacy_policies/privacy_policies.html"
  //   )
  // }

  jumpGHZS() {
    window.open("https://www.ghzs.com/")
  }
}
