import { render, staticRenderFns } from "./Slide.vue?vue&type=template&id=72d9d9d8&scoped=true"
import script from "./Slide.vue?vue&type=script&lang=ts"
export * from "./Slide.vue?vue&type=script&lang=ts"
function injectStyles (context) {
  
  var style0 = require("./Slide.vue?vue&type=style&index=0&id=72d9d9d8&prod&lang=scss&scoped=true")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "72d9d9d8",
  "7259d593"
  
)

export default component.exports