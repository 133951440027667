

























import { Component, Vue, Prop } from "vue-property-decorator"
import downloadDialog from "@/components/downloadDialog"
@Component({
  name: "Operation"
})
export default class Operation extends Vue {
  get userInfo() {
    return this.$root.$tstore.modules.userInfo.getters.userInfo
  }

  auth() {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await this.$root.$tstore.getters.api.auth.permissions(
          this.userInfo._id,
          "bbs"
        )
        if (res.data.post) {
          resolve(true)
        } else {
          reject(false)
        }
      } catch (error) {
        console.log(error)
        if (error.response && error.response.data.code === 401001) {
          if (this.$cookies.get("refresh_token")) {
            this.$root.$tstore.getters.api.auth
              .refreshToken(this.$cookies.get("refresh_token"))
              .then(res => {
                this.$cookies.set("access_token", res.data.access_token.value, {
                  expires: res.data.access_token.expire
                })
                this.$cookies.set(
                  "refresh_token",
                  res.data.refresh_token.value,
                  {
                    expires: res.data.refresh_token.expire
                  }
                )
                this.$root.$tstore.modules.userInfo.actions.FETCH_USER_INFO()
              })
              .catch(err => {
                if (err.response && err.response.data.code === 400401) {
                  this.$error("登录状态已过期")
                  this.$root.$tstore.modules.userInfo.mutations.SET_USER_INFO({
                    userInfo: null
                  })
                }
              })
          } else {
            this.$error("登录状态已过期")
            this.$root.$tstore.modules.userInfo.mutations.SET_USER_INFO({
              userInfo: null
            })
          }
        } else {
          reject(error)
        }
      }
    })
  }

  async createArticle() {
    if (!this.userInfo) {
      this.$error("请先登录")
      this.$router.push(`/login`)
      return
    }
    try {
      await this.auth()
      this.$router.push(`/bbs/${this.$route.params.bbs_id}/article/create`)
    } catch (error) {
      if (error === false) {
        this.$error("网页端发布功能暂时未对全部用户开放～")
        downloadDialog(
          {
            rmAfterDestroy: false,
            presetData: {}
          },
          () => {}
        )
      }
    }
  }

  async createVideo() {
    if (!this.userInfo) {
      this.$error("请先登录")
      this.$router.push(`/login`)
      return
    }
    try {
      await this.auth()
      this.$router.push(`/bbs/${this.$route.params.bbs_id}/video/create`)
    } catch (error) {
      if (error === false) {
        this.$error("网页端发布功能暂时未对全部用户开放～")
        downloadDialog(
          {
            rmAfterDestroy: false,
            presetData: {}
          },
          () => {}
        )
      }
    }
  }
}
