import { Datum, IToolkitList } from "@/api/modules/toolkit"
import { ActionContext } from "vuex"
import { IRootState } from ".."
interface IState {
  list: IToolkitList[] | Datum[]
  searchList: Datum[]
}

type Context = ActionContext<IState, IRootState>

export const toolkit = {
  namespaced: true,
  state: () => ({
    list: null,
    searchList: null
  }),
  getters: {
    list: (state: IState) => state.list,
    searchList: (state: IState) => state.searchList
  },
  mutations: {
    SET_LIST: (state: IState, { list }) => {
      state.list = list
    },
    SET_SEARCH_LIST: (state: IState, { searchList }) => {
      state.searchList = searchList
    }
  },
  actions: {
    FETCH_LIST: ({ commit, rootState: { api } }: Context) => {
      return api.toolkit
        .list({ view: "category" })
        .then(res => commit("SET_LIST", { list: res.data }))
    },
    FETCH_SEARCH_LIST: (
      { commit, rootState: { api } }: Context,
      { keyword }
    ) => {
      return api.toolkit
        .listByKeyword(keyword)
        .then(res => commit("SET_SEARCH_LIST", { searchList: res.data }))
    }
  }
}
