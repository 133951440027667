const justifyType = "right"
const SPECIAL_NODE_LIST = ["LI"]
const SPECIAL_TOP_NODE_LIST = ["BLOCKQUOTE"]

// 第一，菜单 class ，Button 菜单继承 BtnMenu class
export default function($, BtnMenu, tstore?) {
  return class customJustifyRight extends BtnMenu {
    constructor(editor) {
      // data-title属性表示当鼠标悬停在该按钮上时提示该按钮的功能简述
      const $elem = $(
        `<div class="w-e-menu" data-title="右对齐">
            <i class="w-e-icon-alignright"></i>
         </div>`
      )
      super($elem, editor)
      this._active = false
    }

    /**
     * 菜单点击事件
     */
    clickHandler() {
      const editor = this.editor
      const selection = editor.selection
      const $selectionElem = selection.getSelectionContainerElem()

      // 保存选区
      selection.saveRange()

      // 获取顶级元素
      const $elems = editor.selection.getSelectionRangeTopNodes()
      if ($selectionElem?.length) {
        // list 在chrome下默认多包裹一个 p，导致不能通过顶层元素判断，所以单独加个判断
        if (
          this.isSpecialNode($selectionElem, $elems[0]) ||
          this.isSpecialTopNode($elems[0])
        ) {
          const el = this.getSpecialNodeUntilTop($selectionElem, $elems[0])
          if (el === null) return

          $(el).css("text-align", justifyType)
        } else {
          $elems.forEach(el => {
            el.css("text-align", justifyType)
          })
        }
      }
      //恢复选区
      selection.restoreSelection()
    }
    /**
     * 获取选区中的特殊元素，如果不存在，则直接返回顶层元素子元素
     * @param el DomElement
     * @param topEl DomElement
     */
    getSpecialNodeUntilTop(el, topEl) {
      let parentNode: Node | null = el.elems[0]
      const topNode = topEl.elems[0]
      // 可能出现嵌套的情况，所以一级一级向上找，是否是特殊元素
      while (parentNode !== null) {
        if (SPECIAL_NODE_LIST.indexOf(parentNode?.nodeName) !== -1) {
          return parentNode
        }
        // 如果再到 top 元素之前还没找到特殊元素，直接返回元素
        if (parentNode.parentNode === topNode) {
          return parentNode
        }
        parentNode = parentNode.parentNode
      }
      return parentNode
    }

    /**
     * 当选区元素或者顶层元素是某些特殊元素时，只需要修改子元素的对齐样式的元素
     * @param el DomElement
     * @param topEl DomElement
     */
    isSpecialNode(el, topEl) {
      // 如果以后有类似的元素要这样处理，直接修改这个数组即可
      const parentNode = this.getSpecialNodeUntilTop(el, topEl)

      if (parentNode === null) return false

      return SPECIAL_NODE_LIST.indexOf(parentNode.nodeName) !== -1
    }
    /**
     * 当选区 top 元素为某些特殊元素时，只需要修改子元素的对齐样式的元素
     * @param el DomElement
     */
    isSpecialTopNode(topEl) {
      if (topEl === null) return false

      return SPECIAL_TOP_NODE_LIST.indexOf(topEl.elems[0]?.nodeName) !== -1
    }
    /**
     * 尝试修改菜单激活状态
     */
    tryChangeActive(): void {}
  }
}
