import { apiApp, apiBaseUrlV5 } from "@/config"
import { AxiosInstance, AxiosResponse } from "axios"

export default function(service: AxiosInstance) {
  return {
    basic: {
      info(): Promise<AxiosResponse<MBasic.IInfo>> {
        return service.get(`${apiApp}/web_app/basis?view=pc`)
      },
      getContent(_id: string): Promise<AxiosResponse<{ content: string }>> {
        return service.get(`${apiApp}/web_app/basis/informations/${_id}`)
      },
      maps(): Promise<AxiosResponse<MBasic.IMapItem[]>> {
        return service.get(`${apiApp}/web_app/basis/map`)
      },
      settings(): Promise<AxiosResponse<MBasic.ISetting>> {
        return service.get(`${apiApp}/settings`)
      },
      gameSearch(keyword: string): Promise<AxiosResponse<string[]>> {
        return service.get(
          `${apiBaseUrlV5}/games:search?type=game&view=name&page_size=10`,
          { params: { keyword } }
        )
      },
      seoChoice(params: {
        page: number
        page_size: number
      }): Promise<AxiosResponse<MBasic.ISeoChoiceItem[]>> {
        return service.get(
          `${apiApp}/web_app/seo_choice?page=${params.page},${params.page_size}`
        )
      },
      // 聚合页
      juheGames(): Promise<AxiosResponse<IJuheGameItem[]>> {
        return service.get(`${apiApp}/web_app/seo_hot_game`)
      },
      juheArticles(): Promise<AxiosResponse<IArticle[]>> {
        return service.get(`${apiApp}/web_app/seo_hot_game_article`)
      }
    }
  }
}

export declare module MBasic {
  export interface FriendlyLink {
    url: string
    title: string
  }

  export interface Information {
    _id: string
    title: string
  }

  export interface IInfo {
    friendly_links: FriendlyLink[]
    informations: Information[]
  }

  export interface Link {
    type: string
    link: string
    link_seq: string
  }

  export interface Content {
    title: string
    link: Link
    url?: string
  }

  export interface IMapItem {
    field: string
    contents: Content[]
  }

  interface ISetting {
    search: ISearch
  }

  interface ISearch {
    default: string[]
  }

  export interface ISeoChoiceItem {
    _id: string
    _seq: number
    content: string
    img: string[]
    time: number
    game_id: string
    title: string
  }
}

export interface IJuheGameItem {
  _id: string
  artilce: {
    publishOn: string
    _id: string
    title: string
    _seq: number
  }[]
  game_id: string
  game_info: {
    _id: string
    icon: string
    name: string
    subtitle: string
    _seq: number
    type_tag: {
      _id: string
      _seq: number
      name: string
      new_style: {
        color: string
        background: string
        column: string
      }
    }[]
  }
}

export interface IArticle {
  _id: string
  time: number
  title: string
  _seq: number
  link: {
    type: "community_article" | "article"
    text: string
    link: string
    link_seq: number
    link_community: {
      _id: string
      _seq: number
      name: string
    }
  }
}
