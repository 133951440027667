import { render, staticRenderFns } from "./QuesList.vue?vue&type=template&id=9de8df94&scoped=true"
import script from "./QuesList.vue?vue&type=script&lang=ts"
export * from "./QuesList.vue?vue&type=script&lang=ts"
function injectStyles (context) {
  
  var style0 = require("./QuesList.vue?vue&type=style&index=0&id=9de8df94&prod&lang=scss&scoped=true")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "9de8df94",
  "5d0c820d"
  
)

export default component.exports