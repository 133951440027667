















































import { Component, Prop, Vue, Watch } from "vue-property-decorator"

@Component({
  name: "GameDialog",
  components: {
    SearchInput: () =>
      import("@/views/Toolkit/components/searchInput/searchInput.vue"),
    Empty: () => import("@/components/empty/empty.vue")
  }
})
export default class GameDialog extends Vue {
  // 通用弹窗的JS数据和方法
  cancel() {
    this.isSubmit = false
    this.dialogVisible = false
  }

  onSubmit() {
    this.isSubmit = true
    this.dialogVisible = false
  }

  dialogDataPresets = {}

  isRender: boolean = true
  isSubmit: boolean = false

  @Prop()
  dialogVisible: boolean = false

  isEmpty = false
  selected = null
  list = []
  async handleSearch(keyword) {
    // console.log(keyword)
    if (!keyword.trim()) {
      return false
    }
    const res = await this.$tstore.getters.api.games.gameSearch({
      page: 1,
      page_size: 100,
      view: "web",
      keyword: keyword.trim()
    })
    // console.log(res)

    this.isEmpty = res.data.length === 0
    this.list = res.data
  }
  handleSelect(item) {
    this.selected = item
    this.onSubmit()
  }
  handleSelectNoGame() {
    this.selected = null
    this.onSubmit()
  }
}
