































import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import updateTdk, { formatTDK } from "@/utils/tdk"
import { IBreadcrumbItem } from "@/components/breadcrumb/extra"

@Component({
  name: "Detail",
  components: {
    DetailHeader: () => import("./components/DetailHeader/DetailHeader.vue"),
    DetailTab: () => import("./components/DetailTab/DetailTab.vue"),
    Breadcrumb: () => import("@/components/breadcrumb/breadcrumb.vue")
  },
  beforeRouteUpdate(to, from, next) {
    this.fetchData()
    next()
  },
  tdk() {
    return this.$store.state.tdk
  }
})
export default class Detail extends Vue {
  breads: IBreadcrumbItem[] = []

  // 游戏摘要（主站内容）
  get webDetail() {
    return this.$root.$tstore.modules.gameList.getters.gameAbstract
  }

  // 游戏详情
  get newDetail() {
    return this.$root.$tstore.modules.gameList.getters.gameDetail
  }

  get video() {
    return this.$store.state.game.detailTab.video || []
  }

  showHeaderFixed = false
  tabIndex = 0
  tabsOption = []

  // ssr hook
  serverPrefetch() {
    return this.fetchData()
  }

  beforeMount() {
    this.fetchData()
  }

  mounted() {
    this.$nextTick(() => {
      window.scrollTo(0, 0)
      window.addEventListener("scroll", this.handleScroll, true)
    })
  }

  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll, true)
  }

  hasDetailTab(type) {
    return this.newDetail.detail_tab.filter(tab => tab.type === type).length > 0
  }

  handleScroll() {
    let scrollTop =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop
    this.showHeaderFixed = scrollTop > 535
  }

  onTypeChange(val, title) {
    switch (title) {
      case "详情":
        break
      case "专区":
        window.location.href = this.newDetail.zone_tab.link
        break
      case "评论":
        this.$router.push(this.$route.path + `/comments`)
        break
      case "论坛":
        this.$router.push(`/bbs/${this.newDetail.bbs_tab.link_seq}`)
        break
    }
  }

  /**
   * 请求数据
   */
  fetchData() {
    return Promise.all([
      this.$store.dispatch("tdk/FETCH_TDK", {
        type: "game",
        _id_or_name: this.$route.params.game_id
      })
    ])
      .then(() => {
        this.formatTdk()
      })
      .catch(err => {
        console.log(err)
        // this.$router.push("/")
      })
      .finally(() => {
        updateTdk.call(this)
      })
  }

  formatTdk() {
    let desc =
      this.newDetail.detail_tab.filter(v => v.type === "des").length > 0
        ? this.newDetail.detail_tab
            .filter(v => v.type === "des")[0]
            .des.substr(0, 50)
        : ""

    this.$store.commit(
      "tdk/SET_TDK",
      formatTDK(this.$store.state.tdk, [
        {
          replaceKey: "{游戏名称}",
          replaceVal: this.webDetail.name
        },
        {
          replaceKey: "{下载按钮文案}",
          replaceVal: this.downloadStatusText
        },
        {
          replaceKey: "{游戏简介}",
          replaceVal: desc
        }
      ])
    )
  }

  /**
   * 处理数据
   */
  formatData() {
    this.tabsOption = []
    this.tabsOption.push("详情")
    if (
      this.newDetail &&
      this.newDetail.zone_tab &&
      this.newDetail.zone_tab.style === "link"
    ) {
      this.tabsOption.push("专区")
    }

    if (this.webDetail.show_comment) {
      this.tabsOption.push("评论")
    }

    if (
      this.newDetail &&
      this.newDetail.bbs_tab &&
      this.newDetail.bbs_tab.type === "bbs_detail"
    ) {
      this.tabsOption.push("论坛")
    }

    this.tabIndex = this.tabsOption.findIndex(e => e === "详情")

    //breads
    this.breads = [{ name: "首页", path: "/" }]
    if (this.webDetail.new_tag_style && this.webDetail.new_tag_style[0]) {
      this.breads.push({
        name: this.webDetail.new_tag_style[0].name,
        path: `/tag/${this.webDetail.new_tag_style[0]._seq}`
      })
    }
    this.breads.push({ name: this.webDetail.name })
  }

  handleBack() {
    this.$router.go(-1)
  }

  get loaded() {
    return this.webDetail && this.newDetail
  }

  @Watch("loaded", { immediate: true })
  onLoaded(c) {
    if (c) {
      this.formatData()
    }
  }

  get downloadStatusText() {
    let text = "下载"
    if (this.webDetail.download_status) {
      switch (this.webDetail.download_status) {
        case "on":
          text = "下载"
          break
        case "off":
          text = this.webDetail.download_off_text || "下载"
          break
        case "appointment":
          text = "预约"
          break
        case "demo":
          text = "试玩"
          break
        case "link":
          text = this.webDetail.h5_link.text || "下载"
          break
      }
    }
    return text
  }
}
