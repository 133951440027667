





























































import { Component, Vue, Prop, Watch } from "vue-property-decorator"
import Dialog from "./components/Dialog"
import { DateFotmat } from "@/utils/base"

@Component({
  name: "Info",
  components: {
    CustomColumn: () => import("../Top/CustomColumn/CustomColumn.vue")
  }
})
export default class DetailCategoryName extends Vue {
  @Prop({ type: Array, default: () => [] })
  dataProp: any[]

  data = {
    manufacturer: "",
    version: "",
    size: "",
    time: "",
    permissions: [],
    privacy_policy_url: "",
    customColumn: []
  }

  @Watch("dataProp", { immediate: true })
  onData(data) {
    if (data && data[0] && data[0].info) {
      const {
        manufacturer,
        version,
        size,
        permissions,
        privacy_policy_url,
        update_time
      } = data[0].info

      this.data.manufacturer = manufacturer
      this.data.version = version
      this.data.size = size
      this.data.permissions = permissions
      this.data.privacy_policy_url = privacy_policy_url
      this.data.time = DateFotmat(new Date(update_time * 1000), "yyyy-MM-dd")

      data.forEach(item => {
        if (item.type === "custom_column") {
          this.data.customColumn.push(item.custom_column)
        }
      })
    }
  }

  handlePrivacy() {
    window.open(this.data.privacy_policy_url, "_blank")
  }

  handlePermission() {
    Dialog({
      list: this.data.permissions,
      gameInfo: {
        name: this.$store.state.gameList.gameAbstract?.name,
        icon: this.$store.state.gameList.gameAbstract?.icon,
        version: this.data.version
      }
    })
    // permissionDialog(
    //   {
    //     rmAfterDestroy: false,
    //     presetData: {
    //       game_name: this.$store.state.gameList.gameAbstract?.name,
    //       game_icon: this.$store.state.gameList.gameAbstract?.icon,
    //       version: this.data.version,
    //       permissions: this.data.permissions
    //     }
    //   },
    //   () => {}
    // )
  }
}
