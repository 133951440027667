





































import { Component, Vue } from "vue-property-decorator"

@Component({
  name: "ToolkitSearchResult",
  components: {
    Empty: () => import("@/components/empty/empty.vue"),
    SearchInput: () => import("../searchInput/searchInput.vue"),
    CardInnerList: () => import("../cardInnerList/cardInnerList.vue"),
    GameRecommend: () => import("@/components/GameRecommend/GameRecommend.vue"),
    ScanDownload: () => import("@/components/scanDownload/scanDownload.vue")
  },
  beforeRouteUpdate(to, from, next) {
    this.fetchData(to.params.toolkitKeyword)
    next()
  }
})
export default class ToolkitSearchResult extends Vue {
  get originList() {
    return this.$root.$tstore.modules.toolkit.getters.searchList
  }

  list = []
  // ssr hook
  serverPrefetch() {
    return this.fetchData(this.$route.params.toolkitKeyword)
  }

  beforeMount() {
    if (!this.originList) {
      this.fetchData(this.$route.params.toolkitKeyword)
    } else {
      this.formatData()
    }
  }
  beforeDestroy() {
    this.$root.$tstore.modules.toolkit.mutations.SET_SEARCH_LIST({
      searchList: null
    })
  }

  fetchData(keyword) {
    return this.$root.$tstore.modules.toolkit.actions
      .FETCH_SEARCH_LIST({
        keyword
      })
      .then(this.formatData)
      .catch(err => {
        console.log(err)
      })
      .finally(() => {
        // updateTdk.call(this)
      })
  }

  formatData() {
    this.list = JSON.parse(JSON.stringify(this.originList))
  }

  handleSearch(keyword) {
    if (keyword.trim()) {
      const path = "/toolkit/search/" + keyword.trim()
      if (this.$route.fullPath !== path) {
        this.$router.replace(path)
      }
    }
  }

  handleBack() {
    this.$router.go(-1)
  }
}
