import { render, staticRenderFns } from "./Server.vue?vue&type=template&id=6c64ea1c&scoped=true"
import script from "./Server.vue?vue&type=script&lang=ts"
export * from "./Server.vue?vue&type=script&lang=ts"
function injectStyles (context) {
  
  var style0 = require("./Server.vue?vue&type=style&index=0&id=6c64ea1c&prod&scoped=true&lang=scss")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "6c64ea1c",
  "074c70a0"
  
)

export default component.exports