












































































import { Component, Vue, Watch } from "vue-property-decorator"

@Component({
  name: "ContentSearchResult",
  beforeRouteUpdate(to, from, next) {
    this.keyword = to.params.bbs_keyword || ""
    this.fetchData()
    next()
  },

  components: {
    CardItem: () => import("@/components/bbsCardItem/bbsCardItem.vue"),
    LoadMore: () => import("@/components/loadMore/loadMore.vue"),
    NoMoreData: () => import("@/components/noMoreData/noMore.vue"),
    Img: () => import("@/components/image/image.vue"),
    Empty: () => import("@/components/empty/empty.vue"),
    CategroyName: () => import("../CategroyName.vue")
  }
})
export default class Content extends Vue {
  get list() {
    return this.$store.state.bbs.contentSearchList
  }

  get isQues() {
    return this.$route.query.type && this.$route.query.type === "question"
  }

  keyword = ""

  // ssr hook
  serverPrefetch() {
    const { bbs_keyword } = this.$route.params
    if (bbs_keyword) {
      this.keyword = bbs_keyword
    }
    return this.fetchData()
  }

  mounted() {
    this.keyword = this.$route.params.bbs_keyword || ""
    if (!this.list) {
      this.fetchData()
    }
  }

  // 请求数据
  fetchData() {
    return this.$store.dispatch("bbs/FETCH_CONTENT_SEARCH", {
      keyword: this.keyword,
      type: this.$route.query.type || "community_article",
      bbs_id: this.$route.params.bbs_id
    })
  }

  destroyed() {
    this.$store.commit("bbs/SET_CONTENT_SEARCH", { list: null })
  }

  // 搜索
  handleSearch() {
    if (this.keyword.trim().length === 0) return
    const { bbs_id, bbs_keyword } = this.$route.params
    const URL = `/bbs/${bbs_id}/search/${this.keyword}`
    if (bbs_keyword !== this.keyword) {
      this.$router.replace(URL)
    }
  }

  // 清除内容搜索框
  handleClear() {
    this.keyword = ""
  }

  // 取消内容搜索
  handleCancelSearch() {
    this.$router.back()
  }

  createUrl(row) {
    if (row._seq) {
      if (this.isQues) {
        return `/bbs/question-${row._seq}`
      } else {
        return `/bbs/thread-${row._seq}`
      }
    } else {
      if (this.isQues) {
        return `/bbs/question-${row._id}`
      } else {
        return `/bbs/thread-${row._id}`
      }
    }
  }
}
