import { createApp } from "./main"
import Cookies from "js-cookie"
const { app, router, $tstore, api } = createApp({
  access_token: Cookies.get("access_token")
})

if (window.__INITIAL_STATE__) {
  $tstore.store.replaceState(window.__INITIAL_STATE__)

  $tstore.store.state.api = api
}

router.onReady(() => {
  app.$mount("#app")
})
