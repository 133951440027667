











































































































import { Component, Vue, Watch } from "vue-property-decorator"

import updateTdk, { formatTDK } from "@/utils/tdk"

import downloadDialog, { downloadDialogType } from "@/components/downloadDialog"
import shareDialog from "@/components/shareDialog"
import { timeago } from "@/utils/base"
import { IBreadcrumbItem } from "@/components/breadcrumb/extra"

@Component({
  name: "Question",
  components: {
    Content: () => import("@/views/CommunityArticle/components/content.vue"),
    Comment: () => import("./components/comment.vue"),
    GameInfo: () => import("@/views/Article/components/gameInfo.vue"),
    ScanDownload: () => import("@/components/scanDownload/scanDownload.vue"),
    HotContent: () => import("@/views/BbsList/components/HotContent.vue"),
    Breadcrumb: () => import("@/components/breadcrumb/breadcrumb.vue")
  },
  tdk() {
    return this.$store.state.tdk
  },
  beforeRouteUpdate(to, from, next) {
    this.breads = [{ name: "论坛首页", path: "/bbs" }]
    next()
  }
})
export default class Question extends Vue {
  breads: IBreadcrumbItem[] = [{ name: "论坛首页", path: "/bbs" }]

  get publishTime() {
    return this.detail.time?.create
      ? timeago(this.detail.time?.create * 1000)
      : ""
  }

  get detail() {
    return this.$root.$tstore.modules.ques.getters.detail
  }

  // ssr hook
  serverPrefetch() {
    return this.fetchData()
  }

  beforeMount() {
    if (!this.detail) {
      this.fetchData()
    } else {
      this.formatData()
    }
  }

  fetchData() {
    return new Promise(async (resolve, reject) => {
      try {
        await this.$root.$tstore.modules.ques.actions.FETCH_DETAIL({
          ques_id: this.$route.params.ques_id
        })
        this.formatData()

        await this.$store.dispatch("tdk/FETCH_SUB_TDK", {
          type: "bbs",
          _id_or_name: this.$route.params.ques_id,
          sub_type: "question"
        })

        this.$store.commit(
          "tdk/SET_TDK",
          formatTDK(this.$store.state.tdk, [
            {
              replaceKey: "{问题标题}",
              replaceVal: this.detail.title
            },
            {
              replaceKey: "{问题描述}",
              replaceVal: this.detail.content
                .replace(/<\/?.+?\/?>/g, "")
                .replace(/\n/g, "")
                .replace(/&nbsp/g, "")
                .substr(0, 50)
            },
            {
              replaceKey: "{游戏名称}",
              replaceVal: this.detail.bbs?.game?.name || ""
            }
          ])
        )

        updateTdk.call(this)

        resolve("")
      } catch (error) {
        reject(error)
      }
    })
  }

  formatData() {
    this.detail.content = this.detail.content.replace(
      /https:\/\/resource.ghzs.com\/image\/community\/5b694f242924bcf82441b3fc\.png/g,
      "http://static-web.ghzs.com/website-static/images/icon_article.png"
    )
    this.detail.content = this.detail.content.replace(
      /https:\/\/resource.ghzs.com\/image\/community\/575fa87d8ab49e12658b4cds\.png/g,
      "http://static-web.ghzs.com/website-static/images/icon_reply.png"
    )
    this.detail.content = this.detail.content.replace(
      /ghzhushou:\/\/[^("|')]*/g,
      match => {
        return `javascript:void(0); redirect('${match}')`
      }
    )

    this.breads.push({
      name: this.detail.bbs.name,
      path: `/bbs/${this.detail.bbs._seq}`
    })
    this.breads.push({
      name: this.detail.title
    })
  }

  destroyed() {
    this.$root.$tstore.modules.ques.mutations.SET_DETAIL({ detail: null })
  }

  handleShare() {
    shareDialog(
      {
        rmAfterDestroy: false,
        presetData: {}
      },
      () => {}
    )
  }

  handleDownload() {
    downloadDialog(
      {
        rmAfterDestroy: false,
        presetData: {
          type: downloadDialogType.BBS
        }
      },
      () => {}
    )
  }

  // 监听路由变化，有变化则重新加载数据
  @Watch("$route")
  routerUpdate(to) {
    this.$root.$tstore.modules.ques.mutations.SET_DETAIL({ detail: null })
    this.fetchData()
  }
}
