import { render, staticRenderFns } from "./Content.vue?vue&type=template&id=2379bda2&scoped=true"
import script from "./Content.vue?vue&type=script&lang=ts"
export * from "./Content.vue?vue&type=script&lang=ts"
function injectStyles (context) {
  
  var style0 = require("./Content.vue?vue&type=style&index=0&id=2379bda2&prod&scoped=true&lang=scss")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "2379bda2",
  "c475a5f4"
  
)

export default component.exports