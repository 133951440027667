







































import { Component, Vue, Prop, Watch } from "vue-property-decorator"
import downloadDialog, { downloadDialogType } from "@/components/downloadDialog"
import { redirectAppLinkInHTML } from "@/utils/base"
@Component({
  name: "Top",
  components: {
    Ellipsis: () => import("@/components/Ellipsis/Ellipsis.vue")
  }
})
export default class Top extends Vue {
  @Prop({ type: Object, default: () => {} })
  custom_column: any

  data = {
    nameIcon: "",
    name: "",
    linkText: "",
    rightText: "",
    des: "",
    tags: [],
    show_des_row_num: 0,
    tag_des: ""
  }

  showExpands = false

  isHide = false

  redirectAppLinkInHTML = redirectAppLinkInHTML

  // 游戏摘要（主站内容）
  get webDetail() {
    return this.$root.$tstore.modules.gameList.getters.gameAbstract
  }
  // 全版本下载
  handleDownload() {
    downloadDialog({
      presetData: {
        type: downloadDialogType.GAME_DETAIL,
        gameName: this.webDetail.name
      }
    })
  }

  @Watch("custom_column", { immediate: true })
  onData(data) {
    if (data) {
      // 隐藏部分自定义栏目
      if (
        (data.info_tag && data.info_tag.length > 0) ||
        (!data.name_icon && !data.des)
      ) {
        this.isHide = true
        return
      }

      this.data.nameIcon = data.name_icon
      this.data.name = data.name
      this.data.linkText = data.name_link?.value || ""
      this.data.rightText = data.link?.value || ""
      this.data.des = data.des
      this.data.tag_des = data.tag_des

      this.data.show_des_row_num = data.show_des_row_num

      this.data.tags = data.info_tag
    }
  }
}
