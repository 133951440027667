



import { Component, Vue, Prop, Watch } from "vue-property-decorator"

@Component({
  name: "Content",
  components: {}
})
export default class Content extends Vue {
  @Prop({ type: String, default: "" })
  content

  @Prop({ type: Array, default: () => [] })
  videos

  destroyed() {
    Array.isArray(window.globalVideoList) &&
      window.globalVideoList.forEach(obj => {
        if (obj._player) {
          obj._player.destroy()
        }
      })
    window.globalVideoList = null
  }

  checkImg() {
    const imgs = document.querySelector("#editor").querySelectorAll("img")
    imgs.forEach(img => {
      if (img.src) {
        var _img = new Image()
        _img.src = img.src
        //判断图片大小 如果等于0就是加载失败
        if (
          (_img.width === 0 || _img.height === 0) &&
          !img.src.includes("?x-oss-process=image/format,webp")
        ) {
          img.src = img.src + "?x-oss-process=image/format,webp"
        }
      }
    })
  }

  @Watch("content", { immediate: true })
  onContent(c) {
    if (c && !this.$isServer) {
      setTimeout(() => {
        if (typeof window !== "undefined" && window.initVideo) {
          window.initVideo()
          window.judgeVideoStatus(this.videos)
          window.initPlaceholderVideo()
        }
        this.checkImg()
      }, 100)
    }
  }
}
