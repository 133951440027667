// 格式化时间
export function DateFotmat(date, fmt = "yyyy-MM-dd") {
  let o = {
    "M+": date.getMonth() + 1, //月份
    "d+": date.getDate(), //日
    "h+": date.getHours(), //小时
    "m+": date.getMinutes(), //分
    "s+": date.getSeconds(), //秒
    "q+": Math.floor((date.getMonth() + 3) / 3), //季度
    S: date.getMilliseconds() //毫秒
  }

  if (/(y+)/.test(fmt))
    fmt = fmt.replace(
      RegExp.$1,
      (date.getFullYear() + "").substr(4 - RegExp.$1.length)
    )

  for (const k in o) {
    if (new RegExp("(" + k + ")").test(fmt))
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
      )
  }

  return fmt
}
/**
 * 格式化时间戳
 * @param timestamp {number} - 时间戳
 * @param fmt {string} - 返回格式
 * @returns {timestamp}
 */
export function TimestampFormat(
  timestamp: number,
  fmt = "yyyy-MM-dd hh:mm:ss"
): string {
  let _timestamp
  // 第一步：检查date值的长度，长度10位则乘以1000，换成带毫秒的格式
  if (!timestamp) return ""
  if (typeof timestamp === "number" && timestamp.toString().length === 10) {
    timestamp = timestamp * 1000
  }
  // 第二步：转为原生的Date对象
  _timestamp = new Date(timestamp)
  // 第三步：格式化
  let o = {
    "M+": _timestamp.getMonth() + 1, //月份
    "d+": _timestamp.getDate(), //日
    "h+": _timestamp.getHours(), //小时
    "m+": _timestamp.getMinutes(), //分
    "s+": _timestamp.getSeconds(), //秒
    "q+": Math.floor((_timestamp.getMonth() + 3) / 3), //季度
    S: _timestamp.getMilliseconds() //毫秒
  }

  if (/(y+)/.test(fmt))
    fmt = fmt.replace(
      RegExp.$1,
      (_timestamp.getFullYear() + "").substr(4 - RegExp.$1.length)
    )

  for (const k in o) {
    if (new RegExp("(" + k + ")").test(fmt))
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
      )
  }

  // 第四步：检查时长
  let compareTime = (Date.now() - timestamp) / 1000
  const _just = 60 // 一分钟内显示秒
  const _minute = 60 * 60 // 一小时内显示分钟
  const _hour = 3600 * 24 // 一天内显示小时
  const _day = 3600 * 24 * 7 // 七天内显示天数
  const _week = 3600 * 24 * 30 // 一个月内显示星期
  const _month = 3600 * 24 * 30 * 3 // 三个月后显示具体时间
  if (compareTime < _just) {
    return "刚刚"
  } else if (compareTime >= _just && compareTime < _minute) {
    return Math.floor(compareTime / _just) + "分钟前"
  } else if (compareTime >= _minute && compareTime < _hour) {
    return Math.floor(compareTime / _minute) + "小时前"
  } else if (compareTime >= _hour * 24 && compareTime < _day) {
    return Math.floor(compareTime / _hour) + "天前"
  } else if (compareTime >= _day && compareTime < _week) {
    return Math.floor(compareTime / _day) + "周前"
  } else if (compareTime >= _week && compareTime < _month) {
    return Math.floor(compareTime / _week) + "个月前"
  } else {
    return fmt
  }
}

/**
 * 防抖节流
 * @param {*} action 回调
 * @param {*} delay 等待的时间
 * @param {*} context this指针
 * @param {Boolean} iselapsed 是否等待上一次,也就是iselapsed为true，则为节流
 * @returns {Function}
 */
export function throttle(action, delay, context, iselapsed) {
  let timeout = null
  let lastRun = 0
  return function() {
    if (timeout) {
      if (iselapsed) {
        return
      } else {
        clearTimeout(timeout)
        timeout = null
      }
    }
    let elapsed = Date.now() - lastRun
    // eslint-disable-next-line
    let args = arguments
    if (iselapsed && elapsed >= delay) {
      runCallback()
    } else {
      timeout = setTimeout(runCallback, delay)
    }
    /**
     * 执行回调
     */
    function runCallback() {
      lastRun = Date.now()
      timeout = false
      action.apply(context, args)
    }
  }
}

/**
 * @param context
 * @param fn
 * @param delay
 */
export function debounce(context, fn, delay = 300) {
  let timer = null
  let self = context || this
  function doEvent(...args) {
    if (timer) {
      clearTimeout(timer)
    }

    timer = setTimeout(() => {
      fn.apply(self, args)
    }, delay)
  }
  return doEvent
}

/**
 * 立即执行模式的防抖
 * @param cb - 回调函数
 * @param delay - 延迟时间
 */
export function IIFEDebounce(cb: Function, delay: number = 500) {
  let timeout = null
  return (function() {
    if (timeout) clearTimeout(timeout)
    timeout = setTimeout(cb, delay)
  })()
}

export function copyText(text, callback) {
  var textarea = document.createElement("textarea")
  textarea.style.position = "fixed" // 防止页面滚动
  textarea.style.opacity = "0"
  textarea.style.left = "-9999px" // 确保元素在视口外
  document.body.appendChild(textarea)
  textarea.value = text
  textarea.select()
  textarea.setSelectionRange(0, textarea.value.length) // 兼容移动端

  try {
    if (document.execCommand("copy")) {
      Vue.prototype.$toast(`已复制`)
      typeof callback === "function" && callback()
    } else {
      window.alert("当前系统不支持复制操作，请自行复制。")
    }
  } catch (err) {
    window.alert("复制失败，请手动复制。")
  }

  document.body.removeChild(textarea)
}

function factory() {
  var __cur_page_visible__ = true
  var timer = null

  function event() {
    __cur_page_visible__ = !document.hidden
  }

  function addEvent() {
    document.addEventListener("visibilitychange", event)
  }

  function removeEvent() {
    document.removeEventListener("visibilitychange", event)
  }

  function openApp(scheme?, failURL?, delay = 1200) {
    if (timer) {
      clearTimeout(timer)
    }

    var ifr = document.createElement("iframe")
    ifr.src = scheme ? scheme : "ghzhushou://"
    ifr.style.display = "none"
    document.body.appendChild(ifr)
    var start_time = Date.now()
    timer = setTimeout(() => {
      document.body.removeChild(ifr)
      var end_time = Date.now()
      if (__cur_page_visible__ && end_time - start_time < 1500) {
        if (typeof failURL === "function") {
          failURL && failURL()
        } else {
          window.location = failURL ? failURL : "https://www.ghzs.com"
        }
      }
    }, delay)
  }

  return {
    __cur_page_visible__,
    addEvent,
    removeEvent,
    openApp
  }
}

export function appBoundle() {
  return factory()
}

/**
 * @param dateTimeStamp 毫秒
 */
export function timeago(dateTimeStamp) {
  var minute = 1000 * 60 //把分，时，天，周，半个月，一个月用毫秒表示
  var hour = minute * 60
  var day = hour * 24
  var week = day * 7
  var halfamonth = day * 15
  var month = day * 30
  var now = new Date().getTime() //获取当前时间毫秒
  var nowYear = new Date().getFullYear() // 此刻年份
  var year = new Date(dateTimeStamp).getFullYear() // 时间戳年份
  var diffValue = now - dateTimeStamp //时间差

  if (diffValue < 0) {
    return
  }
  var result
  var minC = diffValue / minute //计算时间差的分，时，天，周，月
  var hourC = diffValue / hour
  var dayC = diffValue / day
  var weekC = diffValue / week
  var monthC = diffValue / month
  if (year < nowYear) {
    result = DateFotmat(new Date(dateTimeStamp), "yyyy-MM-dd")
  } else if (year >= nowYear) {
    result = DateFotmat(new Date(dateTimeStamp), "MM-dd")
  } else if (dayC > 1 && dayC <= 7) {
    result = " " + dayC.toFixed(0) + "天前"
  } else if (dayC === 1) {
    result = "昨天"
  } else if (hourC >= 1 && hourC <= 23) {
    result = " " + hourC.toFixed(0) + "小时前"
  } else if (minC >= 1 && minC <= 59) {
    result = " " + minC.toFixed(0) + "分钟前"
  } else if (diffValue >= 0 && diffValue <= minute) {
    result = "刚刚"
  } else {
    var datetime = new Date()
    datetime.setTime(dateTimeStamp)
    var Nyear = datetime.getFullYear()
    var Nmonth =
      datetime.getMonth() + 1 < 10
        ? "0" + (datetime.getMonth() + 1)
        : datetime.getMonth() + 1
    var Ndate =
      datetime.getDate() < 10 ? "0" + datetime.getDate() : datetime.getDate()
    // var Nhour =
    //   datetime.getHours() < 10 ? "0" + datetime.getHours() : datetime.getHours()
    // var Nminute =
    //   datetime.getMinutes() < 10
    //     ? "0" + datetime.getMinutes()
    //     : datetime.getMinutes()
    // var Nsecond =
    //   datetime.getSeconds() < 10
    //     ? "0" + datetime.getSeconds()
    //     : datetime.getSeconds()
    result = Nyear + "-" + Nmonth + "-" + Ndate
  }
  return result
}

/**
 * 检测图片是否存在
 * @param url
 */
export const imageIsExist = function(url) {
  return new Promise(resolve => {
    var img = new Image()
    img.onload = function() {
      if (img.complete === true) {
        resolve(true)
        img = null
      }
    }
    img.onerror = function() {
      resolve(false)
      img = null
    }
    img.src = url
  })
}

export function isPC() {
  if (!process.env.isSSR) {
    const userAgent = navigator.userAgent
    const _isPC = !/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i.test(
      userAgent
    )
    return _isPC
  } else {
    return false
  }
}

import { TLocalStorageTypes } from "@/config"
import TOS from "@volcengine/tos-sdk"
export const OSS_REGION = "oss-cn-shenzhen"
interface ossConfig {
  region: string
  accessKeyId: string
  accessKeySecret: string
  bucket: string
  stsToken: string
}

export function createTOSClient(config) {
  return new TOS(config)
}
import axios from "axios"
import Vue from "vue"
export function getImageBlob(url: string) {
  return axios
    .get(url, {
      responseType: "blob"
    })
    .then(res => {
      let file = new File(
        [res.data],
        ("poster-" + Math.random()).substring(2) + ".jpg",
        { type: "image/jpeg" }
      )
      return [file, null]
    })
    .catch(err => {
      return [null, err]
    })
}

export const loadVideo = file =>
  new Promise((resolve, reject) => {
    try {
      let video = document.createElement("video")
      video.preload = "metadata"

      video.onloadedmetadata = function() {
        resolve(this)
      }

      video.onerror = function() {
        reject("Invalid video. Please select a video file.")
      }

      video.src = window.URL.createObjectURL(file)
    } catch (e) {
      reject(e)
    }
  })

export const secondToDate = result => {
  // var h =
  //   Math.floor(result / 3600) < 10
  //     ? "0" + Math.floor(result / 3600)
  //     : Math.floor(result / 3600)
  var m =
    Math.floor((result / 60) % 60) < 10
      ? "0" + Math.floor((result / 60) % 60)
      : Math.floor((result / 60) % 60)
  var s =
    Math.floor(result % 60) < 10
      ? "0" + Math.floor(result % 60)
      : Math.floor(result % 60)
  return (result = m + ":" + s)
}

/** 均匀截取9张 */
/**
 * @description 均匀截取9张
 * @param url 视频url
 * @param length 视频长度 毫秒
 */
export const createSnapshots = (url: string, length: number) => {
  const shots = []
  // 1-9
  for (let i = 0; i < 9; i++) {
    let t = Math.floor((i + 1) * (length / 9))
    if (length > 30000) {
      if (i === 0) {
        t = t + 10000
      }
      if (i === 8) {
        t = t - 10000
      }
    }
    shots[i] = `${url}?x-oss-process=video/snapshot,t_${t},f_jpg,m_fast`
  }
  return shots
}

export const createSnapshotRandomTimes = (length: number) => {
  return Array.from({ length: 9 }, (_, i) => {
    let t = Math.floor((i + 1) * (length / 9))
    if (length > 30000) {
      if (i === 0) {
        t = t + 10000
      }
      if (i === 8) {
        t = t - 10000
      }
    }
    return t
  })
}
/**
 * @description 获取某个节点距离窗口的左边距
 * @param obj DOM
 */
export const getAbsLeft = obj => {
  var l = obj.offsetLeft
  while (obj.offsetParent !== null) {
    obj = obj.offsetParent
    l += obj.offsetLeft
  }
  return l
}

export function getParam(name) {
  const results = window.location.href.match(
    new RegExp("[?&]" + name + "=([^&#]*)")
  )
  if (results === null) {
    return ""
  } else {
    return decodeURI(results[1]) || ""
  }
}

/** 图片压缩，默认同比例压缩
 *  @param {Object} fileObj
 *  图片对象
 *  回调函数有一个参数，base64的字符串数据
 */
export function compress(fileObj): Promise<Blob> {
  return new Promise((resolve, reject) => {
    try {
      const image = new Image()
      image.src = URL.createObjectURL(fileObj)
      image.onload = function() {
        const that = this
        // 默认按比例压缩
        let w = (that as HTMLImageElement).width
        let h = (that as HTMLImageElement).height

        const scale = w / h
        if (scale > 1) {
          if (w > 16384) {
            w = 16383
            h = w / scale
          }
        } else {
          if (h > 16384) {
            h = 16383
            w = h * scale
          }
        }
        // oss max 16777216 ,这里限制 16000
        if (w * h > 16000000) {
          const newScale = Math.sqrt(16000000 / (w * h))
          w = Math.floor(w * newScale)
          h = Math.floor(h * newScale)
        }

        let quality = 0.9 // 默认图片质量为0.7
        // 生成canvas
        const canvas = document.createElement("canvas")
        const ctx = canvas.getContext("2d")
        // 创建属性节点
        const anw = document.createAttribute("width")
        anw.nodeValue = w + ""
        const anh = document.createAttribute("height")
        anh.nodeValue = h + ""
        canvas.setAttributeNode(anw)
        canvas.setAttributeNode(anh)
        ctx.drawImage(that as HTMLImageElement, 0, 0, w, h)
        // 图像质量
        if (fileObj.quality && fileObj.quality <= 1 && fileObj.quality > 0) {
          quality = fileObj.quality
        }
        // quality值越小，所绘制出的图像越模糊
        const data = canvas.toDataURL(fileObj.type, quality)
        // 压缩完成执行回调
        const newFile = convertBase64UrlToBlob(data)
        resolve(newFile)
      }
    } catch (e) {
      reject(e)
    }
  })
}
function convertBase64UrlToBlob(urlData) {
  const bytes = window.atob(urlData.split(",")[1]) // 去掉url的头，并转换为byte
  // 处理异常,将ascii码小于0的转换为大于0
  const ab = new ArrayBuffer(bytes.length)
  const ia = new Uint8Array(ab)
  for (let i = 0; i < bytes.length; i++) {
    ia[i] = bytes.charCodeAt(i)
  }
  return new Blob([ab], { type: "image/png" })
}

//DOM没有提供insertAfter()方法
export function insertAfter(newElement, targetElement) {
  var parent = targetElement.parentNode
  if (parent.lastChild === targetElement) {
    // 如果最后的节点是目标元素，则直接添加。因为默认是最后
    parent.appendChild(newElement)
  } else {
    parent.insertBefore(newElement, targetElement.nextSibling)
    //如果不是，则插入在目标元素的下一个兄弟节点 的前面。也就是目标元素的后面
  }
}

export interface ITableData<T> {
  page?: number
  page_size?: number
  total?: number
  finished?: boolean
  data?: T[]
}

export class TableData<T> {
  // 字段
  page: number
  page_size: number
  total: number
  finished: boolean
  loading: boolean
  data: T[]

  // 构造函数
  constructor({
    page = 1,
    page_size = 30,
    total = 0,
    finished = false,
    data = []
  }: ITableData<T>) {
    this.page = page
    this.page_size = page_size
    this.total = total
    this.finished = finished
    this.data = data
  }

  // 方法
  loadTable() {}
}

/**
 * @description 自动播放
 * @param classTag 必须是vue组件的根节点
 */
export class AutoPlayIo {
  // 字段
  classTag: string
  io = null
  players = []
  // 构造函数
  constructor({ classTag }) {
    this.classTag = classTag
  }

  // 方法
  addIo() {
    if (this.io) {
      this.io.disconnect()
      this.io = null
      this.players = []
    }
    setTimeout(() => {
      this.io = new IntersectionObserver(
        entries => {
          entries.forEach(item => {
            // isIntersecting是一个Boolean值，判断目标元素当前是否可见
            if (item.isIntersecting) {
              if (item.intersectionRatio >= 0.67) {
                if (!this.players.includes(item.target)) {
                  this.players.push(item.target)
                  this.onPlayers()
                }
              } else {
                this.players = this.players.filter(v => v !== item.target)
                this.onPlayers()
              }
            } else {
              if (this.players.includes(item.target)) {
                this.players = this.players.filter(v => v !== item.target)
                this.onPlayers()
              }
            }
          })
        },
        {
          threshold: [0, 0.33, 0.67]
        }
      )

      Array.from(document.querySelectorAll("." + this.classTag)).forEach(card =>
        this.io.observe(card)
      )
    }, 2000)
  }

  onPlayers() {
    setTimeout(() => {
      if (this.players.length > 0) {
        const vueGameCardComp = this.players[0].__vue__
        if (vueGameCardComp?.$refs?.dplayerVideo) {
          if (!vueGameCardComp.$refs.dplayerVideo.dplayer) {
            vueGameCardComp.$refs.dplayerVideo.load()
          } else {
            if (localStorage.getItem(TLocalStorageTypes.VIDEO_LIST_VOLUME)) {
              vueGameCardComp.$refs.dplayerVideo.dplayer.volume(
                parseFloat(
                  localStorage.getItem(TLocalStorageTypes.VIDEO_LIST_VOLUME)
                ),
                true,
                true
              )
            } else {
              vueGameCardComp.$refs.dplayerVideo.dplayer.volume(0.1, true, true)
            }
            // vueGameCardComp.$refs.dplayerVideo.dplayer.volume(0.1, true, true)
            vueGameCardComp.$refs.dplayerVideo.dplayer.seek(0)
            vueGameCardComp.$refs.dplayerVideo.dplayer.play()
          }
        }
      }
    }, 0)
  }

  remove() {
    if (this.io) {
      this.io.disconnect()
      this.io = null
      this.players = null
    }
  }
}

export const redirectAppLinkInHTML = html => {
  return html.replace(/ghzhushou:\/\/[^("|')]*/g, match => {
    return `javascript:void(0); redirect('${match}')`
  })
}

/**
 * 判断一个字符串是否是以 http 或 https 开头的URL
 * @param url - 要验证的字符串
 * @returns 如果是以 http 或 https 开头的URL返回 true，否则返回 false
 */
export function isHttpURL(url: string): boolean {
  return url.startsWith("http://") || url.startsWith("https://")
}

// https://jira.shanqu.cc/browse/GHZSCY-5428
// 内容带有{{url}}格式，{{url}}在客户端、内容站网页端 需用蓝色展示且点击后需在APP内跳转该链接
// 内容带有###内容###格式，###内容###在客户端、内容站网页端需用蓝色展示且点击后需复制且toast提示复制成功；
export const formatURLInHTML = html => {
  let h = html.replace(/{{(.*?)}}/g, match => {
    const text = match.replace(/{{|}}/g, "")
    if (text && isHttpURL(text)) {
      return `<a style="color: #007AFF;" onclick="javascript:void(0);" href="${text}">${text}</a>`
    } else {
      return `<span style="color: #007AFF;" onclick="javascript:void(0); copyText('${text}')">${text}</span>`
    }
  })
  return h.replace(/###(.*?)###/g, match => {
    return `<span style="color: #007AFF;" onclick="javascript:void(0); copyText('${match.replace(
      /###/g,
      ""
    )}')">${match.replace(/###/g, "")}</span>`
  })
}
