









import { Component, Vue } from "vue-property-decorator"
import animationData from "@/assets/lottie/loading.json"
@Component({
  name: "LoadMore",
  components: { lottie: () => import("@/components/lottie/lottie.vue") }
})
export default class LoadMore extends Vue {
  defaultOptions = { animationData: animationData }
}
