





























































































































import { Component, Vue, Watch } from "vue-property-decorator"
import updateTdk, { formatTDK } from "@/utils/tdk"
import downloadDialog, { downloadDialogType } from "@/components/downloadDialog"
import { IBreadcrumbItem } from "@/components/breadcrumb/extra"
@Component({
  name: "Bbs",
  components: {
    ScanDownload: () => import("@/components/scanDownload/scanDownload.vue"),
    HotMember: () => import("./components/HotMember.vue"),
    GameZone: () => import("./components/GameZone.vue"),
    HotContent: () => import("@/views/BbsList/components/HotContent.vue"),
    Operation: () => import("./components/Operation.vue"),
    Img: () => import("@/components/image/image.vue"),
    Breadcrumb: () => import("@/components/breadcrumb/breadcrumb.vue"),
    OfficialBbsDetail: () => import("./components/OfficialBbsDetail.vue"),
    ContentList: () => import("./components/ContentList/ContentList.vue")
  },
  tdk() {
    return this.$store.state.tdk
  }
})
export default class Bbs extends Vue {
  breads: IBreadcrumbItem[] = [{ name: "论坛首页", path: "/bbs" }]

  get bbs() {
    return this.$store.state.bbs.bbs
  }

  isExpand: boolean = false

  // ssr hook
  serverPrefetch() {
    return this.fetchData()
  }

  activated() {
    if (!this.bbs) {
      this.fetchData()
    } else {
      this.formatData()
    }
  }

  deactivated() {
    this.breads = [{ name: "论坛首页", path: "/bbs" }]
    this.$store.commit("bbs/SET_BBS", { bbs: null })
  }

  fetchData() {
    return new Promise(async (res, rej) => {
      try {
        await this.$store.dispatch("bbs/FETCH_BBS", {
          id: this.$route.params.bbs_id
        })
        await this.$root.$tstore.modules.bbs.actions.FETCH_SECTIONS({
          bbs_id: this.$route.params.bbs_id
        })
        await this.$store.dispatch("tdk/FETCH_TDK", {
          type: "bbs",
          _id_or_name: this.$route.params.bbs_id
        })

        this.formatData()

        this.$store.commit(
          "tdk/SET_TDK",
          formatTDK(this.$store.state.tdk, [
            {
              replaceKey: "{论坛名称}",
              replaceVal: this.bbs.name
            },
            {
              replaceKey: "{游戏名称}",
              replaceVal: this.bbs.game?.name || ""
            }
          ])
        )

        updateTdk.call(this)
        res("")
      } catch (error) {
        rej(error)
      }
    })
  }

  formatData() {
    if (this.bbs.top) {
      this.bbs.top = this.bbs.top.filter(
        v => v.type === "community_article" || v.type === "article"
      )
    }
    this.$store.commit("bbs/SET_BBS_NAME", {
      bbsName: this.bbs.bbs_id
    })
    this.breads.push({ name: this.bbs.name })
  }

  createTopUrl(item) {
    if (item.type === "community_article") {
      return `/bbs/${item.link_community._id}/article/${item.link}`
    } else if (item.type === "article") {
      return `/article/${item.link}`
    }
    return ""
  }

  handleFollow() {
    downloadDialog(
      {
        rmAfterDestroy: false,
        presetData: {
          type: downloadDialogType.BBS
        }
      },
      () => {}
    )
  }

  // 页面销毁
  destroyed() {
    // this.$store.commit("bbs/SET_BBS", { bbs: null })
  }
}
