




































import { INovelColumnItemData } from "@/api/modules/novel"
import { Component, Vue, Prop } from "vue-property-decorator"

@Component({
  name: "NovelColumn",
  components: {}
})
export default class NovelColumn extends Vue {
  @Prop({ type: Array, default: () => [] }) list: INovelColumnItemData[]
  @Prop({ type: [String, Number], default: "" }) column_id: string
  @Prop({ type: String, default: "" }) column_name: string
}
