






































import { Component, Vue, Watch } from "vue-property-decorator"

@Component({
  name: "OfficialList",
  components: {}
})
export default class OfficialList extends Vue {
  get list() {
    return this.$root.$tstore.modules.bbs.getters.officialBbsList
  }

  // ssr hook
  serverPrefetch() {
    return this.fetchData()
  }

  beforeMount() {
    if (!this.list) {
      this.fetchData()
    }
  }
  fetchData() {
    return this.$root.$tstore.modules.bbs.actions.FETCH_OFFICIAL_BBS()
  }
  formatHot(hot: number) {
    return hot.toString().length >= 5
      ? hot.toString().slice(0, hot.toString().length - 4) + "万"
      : hot
  }
}
