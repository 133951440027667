







































































import { Component, Vue, Prop } from "vue-property-decorator"

import previewImage from "@/components/previewImage"
import { MColumn } from "@/api/modules/column"
import TagsDLG from "@/components/tagsDLG"

@Component({
  name: "GameItem",
  components: {}
})
export default class GameItem extends Vue {
  @Prop({ type: Object, default: "" }) item: MColumn.IColumnGameItem
  @Prop({ type: Number, default: 0 }) idx

  $refs: { tags: HTMLElement }

  handleView(image) {
    previewImage({ imgUrl: image })
  }

  get filterTags() {
    return this.$root.$tstore.modules.gameList.getters.filterTags
  }

  get new_tag_style() {
    return this.filterTags && this.filterTags.length > 0
      ? this.item.new_tag_style.filter(v => !this.filterTags.includes(v.name))
      : this.item.new_tag_style
  }

  showMore = false

  mounted() {
    if (!this.$isServer) {
      this.calcTagsWidth(300)
    }
  }

  calcTagsWidth(maxWidth: number) {
    this.$nextTick(() => {
      if (this.$refs.tags.offsetWidth > maxWidth) {
        let width = 0
        let flag = false
        Array.from(this.$refs.tags.children).forEach((tag: HTMLElement) => {
          if (flag) {
            tag.style.display = "none"
            return
          }
          width += tag.offsetWidth
          if (width > maxWidth) {
            flag = true
            tag.style.display = "none"
            this.showMore = true
          }
        })
      }
    })
  }

  handleMoreTags() {
    TagsDLG(
      {
        rmAfterDestroy: true,
        presetData: {
          gameName: this.item.name,
          tags: this.new_tag_style
        }
      },
      ({ tag }) => {
        this.$router.push(`/tag/${tag}`)
      }
    )
  }
}
