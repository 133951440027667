import { render, staticRenderFns } from "./Weibo.vue?vue&type=template&id=4281f3aa"
import script from "./Weibo.vue?vue&type=script&lang=ts"
export * from "./Weibo.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  "68cdafbc"
  
)

export default component.exports