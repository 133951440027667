import { render, staticRenderFns } from "./CommentDetail.vue?vue&type=template&id=4b413c3c&scoped=true"
import script from "./CommentDetail.vue?vue&type=script&lang=ts"
export * from "./CommentDetail.vue?vue&type=script&lang=ts"
function injectStyles (context) {
  
  var style0 = require("./style.scss?vue&type=style&index=0&id=4b413c3c&prod&lang=scss&scoped=true&external")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "4b413c3c",
  "e5f99a86"
  
)

export default component.exports