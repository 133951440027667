












import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import load from "./dynamicLoadScript"
import { v4 as uuidv4 } from "uuid"
import createMenus from "./menu"
import {
  compress,
  createTOSClient,
  createSnapshots,
  createSnapshotRandomTimes,
  getAbsLeft,
  getImageBlob,
  insertAfter,
  loadVideo,
  OSS_REGION,
  secondToDate
} from "@/utils/base"
import videoPosterDLG from "@/components/videoPosterDLG"
import { initUI } from "./extra"
import alertMessage from "../ghAlert"

const editorCDN =
  "https://and-static.ghzs.com/web/wang_editor/4.7.15/wangEditor.min.js"

@Component({
  name: "ghEditor"
})
export default class ghEditor extends Vue {
  @Prop({ type: String, default: "" }) html: string

  customLinkTypes = [
    "community_article",
    "answer",
    "game",
    "game_collection",
    "video"
  ]

  updateHtml(html) {
    this.editor.txt.html(html) // 重新设置编辑器内容
    initUI(document.querySelector(`#${this.editor.textElemId}`))
  }

  mounted() {
    this.init()
    this.initEvents({ customLinkTypes: this.customLinkTypes })
  }
  beforeDestroy() {
    this.destroyEvents()
  }

  init() {
    // dynamic load tinymce from cdn
    load(editorCDN, err => {
      if (err) {
        // this.$message.error(err.message)
        return
      }

      this.initEditor()
    })
  }
  editor = null
  async initEditor() {
    const editor = new window.wangEditor(
      "#toolbar-container",
      "#text-container"
    )
    this.editor = editor
    const { $, BtnMenu, DropListMenu } = window.wangEditor
    editor.config.zIndex = 0
    editor.config.customAlert = (s, t) => {
      // console.log(s, t)
      switch (t) {
        case "success":
          this.$success(s)
          break
        case "info":
          this.$message(s)
          break
        case "warning":
          this.$message(s)
          break
        case "error":
          this.$error(s)
          break
        default:
          this.$message(s)
          break
      }
    }

    editor.config.menus = createMenus(
      editor,
      {
        $,
        BtnMenu
      },
      this.$root.$tstore
    )

    editor.config.uploadImgMaxLength = 10 // 一次最多上传

    editor.config.customUploadImg = this.customUploadImg

    editor.config.uploadImgMaxSize = 21 * 1024 * 1024

    editor.config.customUploadVideo = this.customUploadVideo

    // 配置 onchange 回调函数
    editor.config.onchange = newHtml => {
      // console.log("change 之后最新的 html", this.createOutputHtml(newHtml))
      this.$emit("htmlChange", this.createOutputHtml(newHtml))

      if (this.editor) {
        this.$emit("textCount", this.textCount(this.editor.txt.text()))
      }
    }
    // 配置触发 onchange 的时间频率，默认为 200ms
    editor.config.onchangeTimeout = 500 // 修改为 500ms

    /**
     * @desc 修复引用模板backspace问题 https://git.shanqu.cc/pm/halo/halo-app-issues/-/issues/1878 3
     * @param dom 当前节点
     * @return { is:是不是, $blockquote:引用节点}
     */
    function _isBlockquoteFirstChild(dom: Node) {
      let _d = dom
      let _old_d = dom
      let _f = false
      let $blockquote
      while (_d.parentElement.className !== "w-e-text-container") {
        if (_d.nodeName === "BLOCKQUOTE" && _d.firstChild === _old_d) {
          _f = true
          $blockquote = _d
        }
        _old_d = _d
        _d = _d.parentElement
      }
      return { is: _f, $blockquote }
    }

    editor.txt.eventHooks.deleteDownEvents.push(e => {
      const selection = editor.selection
      const $topSelectElem = selection.getSelectionRangeTopNodes()[0]
      //  const $li = $topSelectElem.childNodes()?.getNode()
      const selectionNode = window.getSelection()?.anchorNode as Node
      const pos = selection.getCursorPos()
      // const prevNode = selectionNode.previousSibling

      const { is, $blockquote } = _isBlockquoteFirstChild(selectionNode)
      if (is && pos === 0) {
        e.preventDefault()
        var fragment = document.createDocumentFragment()
        $blockquote.childNodes.forEach((v, index) => {
          fragment.appendChild(v.cloneNode(true))
        })

        insertAfter(fragment, $topSelectElem.selector)
        $topSelectElem.remove()
      }
    })

    editor.create()

    setTimeout(() => {
      this.fixUI()

      this.editor.txt.html(this.html) // 重新设置编辑器内容
    }, 0)
  }

  textCount(textContent) {
    let content = textContent.replace(/\s+/g, "")
    // 去掉插入视频中的文字数量
    let removeCount = 0
    let videoDoms = document.querySelectorAll(".placeholder-video-container")
    for (let i = 0; i < videoDoms.length; i++) {
      if (videoDoms[i].textContent) {
        removeCount += videoDoms[i].textContent.replace(/\s+/g, "").length
      }
    }
    return content.length - removeCount
  }

  handleClickText() {
    this.editor && this.editor.$textElem.focus()
  }

  fixUI() {
    ;(document.querySelector(
      ".w-e-menu[data-title='标题']"
    ) as HTMLElement).style.display = "none"

    function _createDivider() {
      let _div = document.createElement("div")
      _div.setAttribute("class", "editor-menu-divider")
      return _div
    }

    function _insert(_node) {
      if (_node && _node.parentNode) {
        _node.parentNode.insertBefore(_createDivider(), _node)
      }
    }
    _insert(document.querySelector(".w-e-menu[data-title='加粗']"))
    _insert(document.querySelector(".w-e-menu[data-title='左对齐']"))
    _insert(document.querySelector(".w-e-menu[data-title='引用回答']"))

    // 隐藏原来的图片菜单，本意是用回编辑器本身的上传功能，但不通过原来的菜单触发
    ;(document.querySelector(
      ".w-e-menu[data-title='图片']"
    ) as HTMLElement).style.display = "none"
    ;(document.querySelector(
      ".w-e-menu[data-title='视频']"
    ) as HTMLElement).style.display = "none"
  }

  initEvents({ customLinkTypes }) {
    window.delLink = function delLink(self) {
      window.event.stopPropagation()

      if (
        self.parentNode &&
        self.parentNode.parentNode &&
        self.parentNode.parentNode.getAttribute("class") &&
        customLinkTypes.indexOf(
          self.parentNode.parentNode.getAttribute("class")
        ) > -1
      ) {
        self.parentNode.parentNode.parentNode.parentNode.removeChild(
          self.parentNode.parentNode.parentNode
        )
      }
    }

    window.delImage = function delImage(self) {
      window.event.stopPropagation()
      if (
        self.parentNode &&
        self.parentNode.parentNode &&
        self.parentNode.parentNode.getAttribute("class") &&
        self.parentNode.parentNode.getAttribute("class") === "insert-image"
      ) {
        self.parentNode.parentNode.parentNode.removeChild(
          self.parentNode.parentNode
        )
      }
    }

    window.delPlaceholderImage = function delPlaceholderImage(id) {
      const placeholderImageNode = document.querySelector(
        "#placeholderImage" + id
      )
      placeholderImageNode &&
        placeholderImageNode.parentNode.removeChild(placeholderImageNode)
    }

    window.reUploadVideo = function reUploadVideo(id) {
      // console.log("reUploadVideo", id)
      window.isReUpload = true
      window.reUploadVideoId = id
      setTimeout(() => {
        if (document.querySelector(".w-e-up-btn")) {
          document.querySelector<HTMLElement>(".w-e-up-btn").click()
        } else {
          document.querySelector<HTMLElement>(".w-e-icon-play").click()
          document.querySelector<HTMLElement>(".w-e-up-btn").click()
        }
      }, 0)
    }

    window.showDelDialog = function showDelDialog(id) {
      const placeholderVideoNode = document.querySelector(
        "#placeholderVideo" + id
      )
      placeholderVideoNode &&
        placeholderVideoNode.parentNode.removeChild(placeholderVideoNode)
    }

    window.showUpdatePosterDialog = async id => {
      this.$loading(true)
      var videoDom = document.querySelector<HTMLElement>(
        "#placeholderVideo" + id
      )
      //  videoDom.style.backgroundImage = "url('" + poster + "')"
      var entity = new Function(
        "return " +
          videoDom.querySelector<HTMLElement>(".inner-wrap").dataset.video
      )()
      // console.log("showUpdatePosterDialog", entity)
      const length =
        (entity.duration.split(":")[0] * 60 +
          parseInt(entity.duration.split(":")[1])) *
        1000
      // console.log("showUpdatePosterDialoglength", length)

      const { client, Domain, Key } = await this.initOss()

      const videoPath = entity.url.replace(Domain, "")

      const shotsPromises = createSnapshotRandomTimes(length).map(async time =>
        client.getObjectV2({
          key: videoPath,
          dataType: "blob",
          process: `video/snapshot,t_${time},f_jpg,w_0,h_0`
        })
      )
      const res = await Promise.all(shotsPromises)
      const selects = []
      res.forEach((source, i) => {
        const dataBlob = source.data.content as Blob
        selects[i] = URL.createObjectURL(dataBlob)
      })
      this.$loading(false)
      videoPosterDLG(
        {
          rmAfterDestroy: true,
          presetData: {
            uploadImageApi: this.$root.$tstore.getters.api.video.uploadImage,
            selects
          }
        },
        async ({ url: originUrl }) => {
          if (originUrl) {
            let [post_file, err] = await getImageBlob(originUrl)

            let postFormData = new FormData()
            postFormData.append("file", post_file)
            const posterRes = await this.$root.$tstore.getters.api.video.uploadImage(
              "poster",
              postFormData
            )
            const url = posterRes.data.url

            videoDom.style.backgroundImage = "url('" + url + "')"
            videoDom
              .querySelector(".inner-wrap")
              .setAttribute(
                "data-video",
                `{url:'${entity.url}',duration: '${entity.duration}',poster: '${url}',id: '${entity.id}',status: '${entity.status}'}`
              )
            await this.$root.$tstore.getters.api.video.modifyInsertVideoPoster(
              entity.id,
              { type: "game_bbs_insert", poster: url }
            )

            alertMessage({
              type: "success",
              message: "操作成功！"
            })
          }
        }
      )
    }
  }
  destroyEvents() {
    window.delLink = null

    window.delImage = null

    window.delPlaceholderImage = null

    window.reUploadVideo = null

    window.showDelDialog = null

    window.showUpdatePosterDialog = null
  }

  // resultFiles 是 input 中选中的文件列表
  // insertImgFn 是获取图片 url 后，插入到编辑器的方法
  async customUploadImg(resultFiles, insertImgFn) {
    const uuids = []
    const results: any = await Promise.allSettled(
      resultFiles.map(async file => {
        // console.log(file)
        var ext = file.name.substring(
          file.name.lastIndexOf("."),
          file.name.length
        )

        if (
          !file.type.match(/jpg/i) &&
          !file.type.match(/jpeg/i) &&
          !file.type.match(/png/i) &&
          !file.type.match(/gif/i)
        ) {
          this.$error("仅支持JPG、PNG、GIF格式~")
          return false
        }

        let formData = new FormData()
        // gif不压缩
        if (file.type.match(/gif/i)) {
          formData.append("file", file)
        } else {
          const blob = await compress(file)
          let _file = new File(
            [blob],
            "poster-" +
              Math.random()
                .toString()
                .substring(2) +
              ext,
            {
              type: blob.type
            }
          )
          formData.append("file", _file)
        }
        console.log(formData)

        const id = uuidv4()
          .split("-")
          .join("")
        uuids.push(id)
        this.editor.cmd.do(
          "insertHTML",
          `
              <br><div data-id="${id}" id="placeholderImage${id}" class="placeholder-image-container" contenteditable="false">
                <span style="opacity:0;position:absolute;">图片上传中</span></span>
                <div style="position:relative;width:100%;height:355px;display:flex;align-items:center;justify-content:center;background:#f5f5f5;border-radius:8px;">
                  <img width="160px" height="60px" src ="https://resource.ghzs.com/image/article/large/5ffd16355ddc530ed31a4e43.png" >
                  <div class="del-icon-box">
                    <img onclick="delPlaceholderImage('${id}')" width="100%" height="100%" src="https://and-static.ghzs.com/image/article/thumb/2021/05/17/60a1e87f01a72060b534e7a9.png">
                  </div>
                </div>
              </div><br>`
        )
        return this.$root.$tstore.getters.api.video.uploadImage(
          "poster",
          formData
        )
      })
    )

    results.forEach((res, idx) => {
      // console.log(res)
      if (res.status === "fulfilled") {
        const placeholderImageNode = document.querySelector(
          `#placeholderImage${uuids[idx]}`
        )
        if (placeholderImageNode) {
          var imageNode = document.createElement("div")
          imageNode.setAttribute("class", "insert-image")
          imageNode.setAttribute("contenteditable", "false")

          var html = `
          <img src="${res.value.data.url}" style=" max-width: 100%; display:block; margin:0 auto; height: auto;">
          <div class="del-icon-box">
            <img onclick="delImage(this)" width="100%" height="100%" src="https://and-static.ghzs.com/image/article/thumb/2021/05/17/60a1e87f01a72060b534e7a9.png">
          </div>
          `

          imageNode.innerHTML = html

          placeholderImageNode.parentNode.replaceChild(
            imageNode,
            placeholderImageNode
          )
        }
      } else {
        if (
          res.reason.response.data &&
          res.reason.response.data.code &&
          res.reason.response.data.code === 403017
        ) {
          this.$error("图片太大了！")
        }
        let removeNode = document.querySelector(
          `#placeholderImage${uuids[idx]}`
        )
        removeNode.parentNode.removeChild(removeNode)
      }
    })

    // console.log(resultFiles, insertImgFn)
  }

  async initOss() {
    const STSRes = await this.$root.$tstore.getters.api.video.getSTSConfig()
    const {
      AccessKey,
      SecretKey,
      Region,
      EndPoint,
      SecurityToken,
      Bucket,
      Key,
      Domain
    } = STSRes.data
    return {
      client: createTOSClient({
        accessKeyId: AccessKey,
        accessKeySecret: SecretKey,
        region: Region,
        endpoint: EndPoint,
        stsToken: SecurityToken,
        bucket: Bucket
      }),
      ...STSRes.data
    }
  }

  async customUploadVideo(resultFiles, insertVideoFn) {
    // resultFiles 是 input 中选中的文件列表
    // console.log(resultFiles)
    const file = resultFiles[0]
    const id =
      window.reUploadVideoId ||
      uuidv4()
        .split("-")
        .join("")

    const videoInfo = {
      poster: "",
      url: "",
      format: "MP4",
      size: file.size, //单位：字节
      length: 0, //单位：秒
      type: "game_bbs_insert"
    }

    const video: any = await loadVideo(file)
    videoInfo.length = parseInt(video.duration) // 秒

    try {
      const { client: TOSClient, Key, Domain } = await this.initOss()
      const uploadPath = Key

      if (!window.isReUpload) {
        this.editor.cmd.do(
          "insertHTML",
          `
        <br><div data-id="${id}" id="placeholderVideo${id}" class="placeholder-video-container" contenteditable="false"
    style="background-image:url('');background-size: cover;background-position: 50%;background-repeat: no-repeat;">
      <div class="inner-wrap">
        <span class='video-wait-tip'>等待上传中...</span>
        <div style='display:none;color:#fff;' class='video-upload-tip'>
          <div>视频上传中…<span class="progress">30</span>%</div>
          <div class="progress-bar"><div class="bar"></div></div>
        </div>
        <div class="video-upload-fail" style="display:none;">
          <div>视频上传失败，请重新上传</div>
          <div class='reupload-btn' onclick="reUploadVideo('${id}')" >重新上传</div>
        </div>
        <div style="display:none;" class="video-play-btn"></div>

        <div class="del-icon-box">
          <img onclick="showDelDialog('${id}')" width="100%" height="100%" src="https://and-static.ghzs.com/image/article/thumb/2021/05/17/60a1e87f01a72060b534e7a9.png">
        </div>
        <div class='video-poster-btn' style='display:none;' onclick="showUpdatePosterDialog('${id}')" >更改封面</div>
      </div>
    </div><br>
        `
        )
      }
      const partSize = 5 * 1024 * 1024
      await TOSClient.uploadFile({
        key: uploadPath,
        file,
        partSize,
        progress: p => {
          const loadingProgress = Math.floor(p * 100)
          // console.log(loadingProgress)
          // this.progressValue = loadingProgress
          var videoDom = document.querySelector(
            "#placeholderVideo" + id
          ) as HTMLElement
          if (videoDom) {
            videoDom.querySelector<HTMLElement>(
              ".video-upload-fail"
            ).style.display = "none"
            videoDom.querySelector<HTMLElement>(
              ".video-wait-tip"
            ).style.display = "none"
            videoDom.querySelector<HTMLElement>(".del-icon-box").style.display =
              "none"
            videoDom.querySelector<HTMLElement>(
              ".video-upload-tip"
            ).style.display = ""
            videoDom.querySelector<HTMLElement>(".progress").innerText =
              loadingProgress + ""
            videoDom.querySelector<HTMLElement>(".bar").style.width =
              loadingProgress * 2 + "px"
          }
        }
      })
      videoInfo.url = Domain + Key

      const source = await TOSClient.getObjectV2({
        key: uploadPath,
        dataType: "blob",
        process: "video/snapshot,t_1000,f_jpg,w_0,h_0"
      })
      const dataBlob = source.data.content as Blob

      let postFormData = new FormData()
      postFormData.append("file", dataBlob)
      const posterRes = await this.$root.$tstore.getters.api.video.uploadImage(
        "poster",
        postFormData
      )
      videoInfo.poster = posterRes.data.url

      console.log(videoInfo)

      const insertResult = await this.$root.$tstore.getters.api.video.insertVideo(
        videoInfo
      )
      // console.log(insertResult)
      // var entity = new Function("return " + msg)()

      var videoDom = document.querySelector(
        "#placeholderVideo" + id
      ) as HTMLElement
      videoDom.setAttribute("contenteditable", "false")
      videoDom.querySelector<HTMLElement>(".video-wait-tip").style.display =
        "none"
      videoDom.querySelector<HTMLElement>(".video-upload-tip").style.display =
        "none"
      videoDom.querySelector<HTMLElement>(".video-upload-fail").style.display =
        "none"
      videoDom.querySelector<HTMLElement>(".video-play-btn").style.display = ""
      videoDom.querySelector<HTMLElement>(".del-icon-box").style.display = ""
      videoDom.querySelector<HTMLElement>(".video-poster-btn").style.display =
        ""
      videoDom.querySelector<HTMLElement>(".inner-wrap").style.background =
        "none"
      videoDom.setAttribute("data-url", Domain + Key)
      videoDom.style.backgroundImage = "url('" + posterRes.data.url + "')"
      videoDom
        .querySelector(".inner-wrap")
        .setAttribute(
          "data-video",
          `{url:'${insertResult.data.url}',duration: '${secondToDate(
            insertResult.data.length
          )}',poster: '${insertResult.data.poster}',id: '${
            insertResult.data._id
          }',status: 'success'}`
        )
      window.isReUpload = false
    } catch (error) {
      console.log(error)

      var videoDom = document.querySelector(
        "#placeholderVideo" + id
      ) as HTMLElement
      videoDom.querySelector<HTMLElement>(".video-wait-tip").style.display =
        "none"
      videoDom.querySelector<HTMLElement>(".video-upload-tip").style.display =
        "none"
      videoDom.querySelector<HTMLElement>(".del-icon-box").style.display = ""
      videoDom.querySelector<HTMLElement>(".video-upload-fail").style.display =
        ""
    }
    // insertVideoFn 是获取视频 url 后，插入到编辑器的方法
    // 上传视频，返回结果，将视频地址插入到编辑器中
    // insertVideoFn(videoUrl)
  }

  /**
   * @description 最终返回的html，主要用于过滤删除按钮等
   */
  createOutputHtml(html: string) {
    var fragment = document.createDocumentFragment()
    var ret = fragment.appendChild(document.createElement("div"))
    ret.innerHTML = html

    var $dels = ret.querySelectorAll(".del-icon-box")
    for (var i = 0; i < $dels.length; i++) {
      $dels[i] && $dels[i].parentNode.removeChild($dels[i])
    }
    /** 5.0.0 */
    var $videos = ret.querySelectorAll(
      ".placeholder-video-container"
    ) as NodeListOf<HTMLElement>
    for (var i = 0; i < $videos.length; i++) {
      if (!$videos[i].dataset.url) {
        $videos[i].parentNode.removeChild($videos[i])
      }
    }
    var $videoPosterBtns = ret.querySelectorAll(
      ".video-poster-btn"
    ) as NodeListOf<HTMLElement>
    for (var j = 0; j < $videoPosterBtns.length; j++) {
      if ($videoPosterBtns[j] && $videoPosterBtns[j].style.display !== "none") {
        $videoPosterBtns[j].style.display = "none"
      }
    }

    return ret.innerHTML
  }
}
