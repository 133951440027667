/**
 * @description 文章详情content需要重新定义的方法
 */

// import axios from "axios"
import DPlayer from "dplayer"
import merge from "lodash/merge"
import downloadDialog from "@/components/downloadDialog"

const defaultOptions = {
  preload: "none",
  autoplay: false,
  theme: "#FADFA3",
  loop: true,
  screenshot: true,
  hotkey: true,
  logo: "",
  volume: 0.2,
  mutex: true,
  video: {},
  contextmenu: [
    {
      text: "光环助手",
      link: "https://m.ghzs.com"
    }
  ]
}

export async function customLinkgo(self) {
  if (window.isEditing) {
    return false
  }
  var obj_data = null
  try {
    obj_data = JSON.parse(self.dataset.datas)
  } catch (error) {
    obj_data = new Function("return " + self.dataset.datas)()
  }
  if (!obj_data) return
  switch (obj_data.type) {
    case "game":
      // https://api.ghzs.com/v3d6/games/57be52048ab49e2c298b4567?view=detail return data: {"share_code": "fangkainasanguo2"}
      // jump: http://www.ghzs666.com/game/fangkainasanguo2
      // var code = await axios
      //   .get("https://api.ghzs.com/v3d6/games/" + obj_data.id + "?view=detail")
      //   .catch(() => (code = null))
      // if (code) {
      //   window.location.href =
      //     "http://www.ghzs666.com/game/" + code.data["share_code"]
      // }

      /** issue需求目前是打开下载弹窗 */
      // downloadDialog(
      //   {
      //     rmAfterDestroy: false,
      //     presetData: {}
      //   },
      //   () => {}
      // )
      window.location.href = `/game/${obj_data.id}`

      break
    case "answer":
      // https://www.ghzs.com/answer/5d0de17f15091368a94ad432
      window.location.href = "https://www.ghzs.com/answer/" + obj_data.id
      break
    case "community_article":
      // https://www.ghzs.com/communities/5cd046f6b27eaf7050255bf3/articles/5d0de92b1509136c2d630abd.html
      // window.location.href =
      //   "https://www.ghzs.com/communities/" +
      //   obj_data.communityId +
      //   "/articles/" +
      //   obj_data.id +
      //   ".html"
      window.location.href = `/bbs/${obj_data.communityId}/article/${obj_data.id}`
      break
    case "video":
      window.location.href = `/bbs/video-${obj_data.id}`
      break
    default:
      break
  }
}

export let isWebFullScreen = false

// click to play video
export function playCurrentVideo(self) {
  if (self.classList.contains("fail")) {
    return
  }
  self.style.display = "none"
  var parant = self.parentNode
  var current_id = parant.id

  parant.querySelector(".start-play").style.display = "none"
  parant.querySelector(".duration").style.display = "none"

  if (window.globalVideoList.some(item => item.refId === current_id)) {
    var initOption = window.globalVideoList.find(
      item => item.refId === current_id
    )

    const op = merge({}, defaultOptions, {
      container: parant,
      video: {
        url: initOption.url,
        type: "auto",
        pic: initOption.poster
      }
    })
    const player = new DPlayer(op)
    player.on("loadstart", () => {
      player.template.webFullButton.onclick = () => {
        isWebFullScreen = !isWebFullScreen
        // 是否是插件里
        if (window.Java && typeof window.Java.webFullscreen === "function") {
          window.Java.webFullscreen(isWebFullScreen)
        }
      }
      if (!window.GhzsUa.UA.isPC) {
        // 在插件不能用
        setTimeout(() => {
          player.template.browserFullButton.style.display = "none"
        })
      }
    })

    initOption._player = player
    player.play()
  }
}

export const uniqueId = (function() {
  var uniId = 0
  return function() {
    return new Date().getTime().toString(36) + ++uniId
  }
})()

// 初始化
export function initVideo() {
  var videoContainers =
    Array.from(document.querySelectorAll(".insert-video-container")) || []
  videoContainers.forEach(function(videoContainer) {
    videoContainer.setAttribute("contenteditable", "false")
    var posterEl = videoContainer.querySelector(".video-poster") as HTMLElement
    posterEl.style.display = ""
    posterEl.onclick = function() {
      playCurrentVideo(this)
    }
    var video = videoContainer.querySelector("video.video-js")
    if (video) {
      videoContainer.setAttribute("id", "player-video-" + uniqueId())
      const URLFn = new Function(
        "return " +
          (video.nextElementSibling.getAttribute("data-video") as any) || "{}"
      )
      if (!window.globalVideoList) window.globalVideoList = []
      window.globalVideoList.push({ refId: videoContainer.id, ...URLFn() })
    }
  })
}

// 判断视频是否在审核
export function judgeVideoStatus(videoArr: any[]) {
  const videoPosterDoms = document.querySelectorAll(".video-poster")

  if (videoPosterDoms.length === 0) {
    return false
  }

  let videoData: any = {}

  for (let i = 0; i < videoPosterDoms.length; i++) {
    videoData = new Function(
      "return " + (videoPosterDoms[i] as any).dataset.video
    )()

    videoArr.some(v => {
      if (v.id === videoData.id) {
        if (v.status === "pass") {
          videoPosterDoms[i].setAttribute(
            "data-video",
            `{url:'${v.url}',duration:'${v.duration}',poster:'${v.poster}',id:'${v.id}',status:'${v.status}'}`
          )
          videoPosterDoms[
            i
          ].innerHTML = `<img class="poster" src="${v.poster}"><span class="duration">${v.duration}</span><div class="start-play"> <div class="play-inner-button"></div></div>`
        } else if (v.status === "pending" || v.status === "fail") {
          // console.log("no pass")
          const text = v.status === "pending" ? "审核中" : "未通过"
          videoPosterDoms[i].setAttribute("class", "video-poster " + v.status)
          videoPosterDoms[i].setAttribute(
            "data-video",
            `{url:'${v.url}',duration:'${v.duration}',poster:'${v.poster}',id:'${v.id}',status:'${v.status}'}`
          )
          videoPosterDoms[
            i
          ].innerHTML = `<div class="status-tip">${text}</div><div class="start-play"> <div class="play-inner-button"></div></div>`
        }
        return true
      }
      return false
    })
  }
}

export function initPlaceholderVideo() {
  document
    .querySelectorAll(".placeholder-video-container .inner-wrap")
    .forEach((item: HTMLElement) => {
      item.onclick = function() {
        playPlaceholderVideo(this)
      }
    })
}

export function playPlaceholderVideo(self) {
  var videoData = new Function("return " + self.dataset.video)()
  var parant = self.parentNode

  const op = merge({}, defaultOptions, {
    container: parant,
    video: {
      url: videoData.url,
      type: "auto",
      pic: videoData.poster
    }
  })
  const player = new DPlayer(op)

  setTimeout(() => {
    parant.oncontextmenu = function() {
      return false
    }
  })

  player.play()

  player.on("loadstart", () => {
    player.template.webFullButton.onclick = () => {
      isWebFullScreen = !isWebFullScreen
      // 是否是插件里
      if (window.Java && typeof window.Java.webFullscreen === "function") {
        window.Java.webFullscreen(isWebFullScreen)
      }
    }
    if (!window.GhzsUa.UA.isPC) {
      // 在插件不能用
      setTimeout(() => {
        player.template.browserFullButton.style.display = "none"
      })
    }
  })
}
