import { render, staticRenderFns } from "./tieziDialog.vue?vue&type=template&id=023307ba&scoped=true"
import script from "./tieziDialog.vue?vue&type=script&lang=ts"
export * from "./tieziDialog.vue?vue&type=script&lang=ts"
function injectStyles (context) {
  
  var style0 = require("./tieziDialog.vue?vue&type=style&index=0&id=023307ba&prod&scoped=true&lang=scss")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "023307ba",
  "19c7f41e"
  
)

export default component.exports