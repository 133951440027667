








































/**
 * @description 图片
 */
import { Component, Vue, Prop } from "vue-property-decorator"
import previewImage from "@/components/previewImage"
import { Swiper, SwiperSlide } from "vue-awesome-swiper"
import "swiper/swiper-bundle.css"
@Component({
  name: "Gallery",
  components: {
    HaloImg: () => import("@/components/image/image.vue"),
    Swiper,
    SwiperSlide
  }
})
export default class Gallery extends Vue {
  @Prop({ type: Array, default: () => [] })
  gallery: string[]

  showPrevious = false
  showNext = true

  swiperOptions = {
    width: 632, // 整个容器宽度
    speed: 500, // 轮播速度
    slidesPerView: "auto", // 根据子元素自适应
    slidesPerGroup: 1, // 一次移动几个
    spaceBetween: 12 // 元素之间的间隔
  }

  get swiper() {
    return (this.$refs.mySwiper as any).swiperInstance
  }
  // 上一张图片
  handlePrevious() {
    this.swiper.slidePrev()
  }

  // 下一张图片
  handleNext() {
    this.swiper.slideNext()
  }

  // 改变前
  transitionStart(e) {
    this.showPrevious = e.translate < 0
    this.showNext = e.translate > -56 || e.activeIndex < this.gallery.length - 5
  }

  handleItem(src) {
    previewImage({
      imgUrl: src
    })
  }
}
