












































































import { Component, Vue, Prop } from "vue-property-decorator"
import downloadDialog, { downloadDialogType } from "@/components/downloadDialog"

@Component({
  name: "OfficialBbsDetail",
  components: {}
})
export default class OfficialBbsDetail extends Vue {
  @Prop()
  bbs

  isExpand: boolean = false

  handleTop(item) {
    if (item.type === "community_article") {
      this.$router.push(`/bbs/${item.link_community._id}/article/${item.link}`)
    } else if (item.type === "article") {
      this.$router.push(`/article/${item.link}`)
    }
  }

  handleFollow() {
    downloadDialog(
      {
        rmAfterDestroy: false,
        presetData: {
          type: downloadDialogType.BBS
        }
      },
      () => {}
    )
  }
}
