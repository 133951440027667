







import { Component, Vue, Prop } from "vue-property-decorator"
import downloadDialog, { downloadDialogType } from "@/components/downloadDialog"
@Component({
  name: "CommentInput"
})
export default class CommentInput extends Vue {
  handleDownload() {
    downloadDialog(
      {
        rmAfterDestroy: false,
        presetData: {
          type: downloadDialogType.BBS
        }
      },
      () => {}
    )
  }
}
