import Vue from "vue"
import PreviewImage from "./PreviewImage.vue"

let PreviewImageConstructor = Vue.extend(PreviewImage)

let instance
let timer

const defaultConifg = {
  rmAfterDestroy: true,
  imgUrl: ""
}

const removablePreviewImage = options => {
  const mergeOptions = Object.assign({}, defaultConifg, options)

  const { rmAfterDestroy, imgUrl } = mergeOptions

  if (!instance) {
    instance = new PreviewImageConstructor({
      watch: {
        dialogVisible: {
          handler(change) {
            this.dialogVisible = change

            if (this.dialogVisible) {
              timer = setTimeout(() => {
                instance.showCloseIcon = true
                clearTimeout(timer)
              }, 50)
            }

            // 关闭之后销毁实例
            if (rmAfterDestroy) {
              if (change === false) {
                this.$nextTick(() => {
                  instance.isRender = false
                  this.$nextTick(() => {
                    instance.$destroy()
                    instance = null
                  })
                })
              }
            }
          }
        }
      }
    })

    instance.$mount()

    document.body.appendChild(instance.$el)

    if (!instance.dialogVisible || (instance && !instance.dialogVisible)) {
      Vue.nextTick(() => {
        Vue.set(instance, "imgUrl", imgUrl)
        instance.dialogVisible = true
      })
    }
  }

  return instance
}

export default removablePreviewImage
