






























































































/**
 * @description 首页头部
 */
import { Component, Vue, Watch } from "vue-property-decorator"

import Img from "@/components/image/image.vue"
import { debounce } from "@/utils/base"

@Component({
  name: "HomeHeader",
  beforeRouteUpdate(to, from, next) {
    if (to.params.keyword) {
      this.keyword = to.params.keyword
    }
    next()
  },
  components: {
    Img,
    Breadcrumb: () => import("@/components/breadcrumb/breadcrumb.vue")
  }
})
export default class HomeHeader extends Vue {
  $refs: { tips: HTMLElement; searchInput: HTMLElement }

  titleIcon = require("@/assets/images/logo-顶部导航栏.png")
  searchIcon = require("@/assets/images/icon-搜索-搜索框内置.png")
  searchActiveIcon = require("@/assets/images/icon-搜索-搜索框内置-蓝色.png")
  clearIcon = require("@/assets/images/icon-清除文本.png")

  keyword = ""
  placeholder = null
  bottom = "0px"
  historyList = []
  showTips = false
  focused = false

  get breads() {
    return this.$root.$tstore.modules.basic.getters.breads
  }

  get showClearIcon() {
    return this.keyword?.length > 0
  }

  get userIcon() {
    return (
      this.$store.state.userInfo.userInfo?.icon ||
      require("@/assets/images/icon-默认图标-用户.png")
    )
  }

  // 刷新 todo
  refresh() {
    if (this.$route.path === "/") return
    this.$router.push("/")
  }

  handleEnter() {
    if (this.placeholder && (!this.keyword || this.keyword.length === 0)) {
      this.keyword = this.placeholder
    }
    if (
      this.keyword &&
      this.keyword.length > 0 &&
      this.keyword !== this.$route.params.keyword
    ) {
      this.focused = false
      this.showTips = false
      this.searchList = null

      this.updateHistory(this.keyword)
      const ACTIVE_MENU = sessionStorage.getItem("ACTIVE_MENU")
      if (ACTIVE_MENU === "bbs") {
        // this.$router.push(`/search/bbs/${this.keyword}`)
        this.$router.push({
          path: `/search/${this.keyword}`,
          query: { type: "bbs" }
        })
      } else {
        this.$router.push({
          path: `/search/${this.keyword}`,
          query: { type: this.$route.query.type }
        })
      }
    }
  }

  handleClear() {
    this.keyword = ""
  }

  get hotSearchList() {
    return this.$store.state.gameList.hotSearchList
  }

  // ssr hook
  serverPrefetch() {
    return this.fetchData()
  }

  mounted() {
    this.keyword = this.$route.params.keyword
    this.updateHistory()
    if (!this.hotSearchList) {
      this.fetchData()
    }
    if (!this.placeholder) {
      this.getPlaceholder()
    }
  }

  fetchData() {
    return this.$store
      .dispatch("gameList/FETCH_HOT_SEARCH_LIST", {})
      .catch(() => {
        // this.$router.push({ name: "404" })
      })
  }

  onFocus() {
    if (
      (!this.hotSearchList || this.hotSearchList.length === 0) &&
      this.historyList.length === 0
    ) {
      return false
    }
    this.focused = true

    if (this.keyword) {
      this.handleSearchTip()
    } else {
      this.showTips = true
      this.calcBottom()
    }
  }

  updateHistory(keyword?) {
    if (!localStorage) return

    if (keyword) {
      const HISTORY_LIST = JSON.parse(localStorage.getItem("HISTORY_LIST"))
      if (HISTORY_LIST) {
        if (HISTORY_LIST.includes(keyword)) {
          return
        }

        if (HISTORY_LIST.length < 5) {
          HISTORY_LIST.unshift(keyword)
        } else {
          HISTORY_LIST.pop()
          HISTORY_LIST.unshift(keyword)
        }
        localStorage.setItem("HISTORY_LIST", JSON.stringify(HISTORY_LIST))
      } else {
        localStorage.setItem("HISTORY_LIST", JSON.stringify([keyword]))
      }
    }

    if (localStorage.getItem("HISTORY_LIST")) {
      this.historyList = JSON.parse(localStorage.getItem("HISTORY_LIST"))
    }
  }

  calcBottom() {
    this.$nextTick(() => {
      this.bottom = `-${this.$refs.tips.offsetHeight + 4}px`
    })
  }

  handleClearHistory(e) {
    e.preventDefault()
    this.historyList = []
    localStorage.removeItem("HISTORY_LIST")
    this.calcBottom()
  }

  handleSelect(keyword, e) {
    e.preventDefault()
    this.keyword = keyword
    this.handleEnter()
  }

  handleUser() {
    if (this.$store.getters["userInfo/isLogin"]) {
      this.showDropdown = !this.showDropdown
    } else {
      this.$router.push("/login")
    }
  }
  handleToMine() {
    this.$router.push("/mine")
  }
  handleLogout() {
    this.$root.$tstore.modules.userInfo.mutations.SET_USER_INFO({
      userInfo: null
    })
    this.$cookies.remove("access_token")
    this.$cookies.remove("refresh_token")
    this.$root.$tstore.actions.UPDATE_API_TOKEN({
      access_token: null
    })
    this.$router.replace("/")
    this.showDropdown = false
  }

  showDropdown = false
  @Watch("showDropdown")
  onShowOptions(val) {
    if (val) {
      document.body.addEventListener("click", () => {
        this.showDropdown = false
      })
    } else {
      document.body.removeEventListener("click", () => {})
    }
  }

  @Watch("$route")
  onRoute(route) {
    if (route && route.name !== "searchResult") {
      this.keyword = ""
    }
  }

  async getPlaceholder() {
    const res = await this.$root.$tstore.getters.api.basic.settings()
    this.placeholder = res.data.search?.default[0]
  }

  onBlur(e) {
    if ((e.target as HTMLElement).nodeName !== "INPUT") {
      this.focused = false
      this.showTips = false
      this.searchList = null
      this.keyword = ""
    }
  }

  @Watch("focused")
  onFocused(val) {
    if (val) {
      document.body.addEventListener("click", this.onBlur)
    } else {
      document.body.removeEventListener("click", this.onBlur)
    }
  }

  keywordChange = debounce(
    this,
    () => {
      this.handleSearchTip()
    },
    400
  )

  searchList = null

  async handleSearchTip() {
    if (this.keyword.length > 0) {
      const res = await this.$root.$tstore.getters.api.basic.gameSearch(
        this.keyword
      )
      if (res.data.length > 0) {
        const reg = new RegExp(this.keyword, "g")
        this.searchList = res.data.map(v => ({
          name: v,
          text: v.replace(reg, item => {
            return `<span style="color:#3E96FE;">${item}</span>`
          })
        }))
        this.showTips = true
        this.calcBottom()
      } else {
        this.showTips = false
      }
    } else {
      this.searchList = null
      this.calcBottom()
    }
  }
}
