export const communityArticle = {
  namespaced: true,
  state: () => ({
    detail: null,
    commentList: null
  }),
  getters: {
    detail: state => state.detail,
    commentList: state => state.commentList
  },
  mutations: {
    SET_DETAIL: (state, { detail }) => {
      state.detail = detail
    },
    SET_COMMENT_LIST: (state, { commentList }) => {
      state.commentList = commentList
    }
  },
  actions: {
    FETCH_DETAIL: ({ commit, rootState: { api } }, { bbs_id, article_id }) => {
      return api.communityArticle
        .detail(bbs_id, article_id)
        .then(res => commit("SET_DETAIL", { detail: res.data }))
    },
    FETCH_COMMENT_LIST: (
      { commit, rootState: { api } },
      { bbs_id, article_id, params }
    ) => {
      return api.communityArticle
        .comments(bbs_id, article_id, params)
        .then(res =>
          commit("SET_COMMENT_LIST", {
            commentList: res.data
          })
        )
    }
  }
}
