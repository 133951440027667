




















































import { Component, Vue } from "vue-property-decorator"
import updateTdk from "@/utils/tdk"

@Component({
  name: "Mine",
  components: {
    Qrcode: () => import("@/components/qrcode/qrcode.vue")
  },
  tdk() {
    return this.metaInfo
  }
})
export default class Mine extends Vue {
  get metaInfo() {
    return {
      title: `${this.name}的个人主页 | 要好玩，上光环`,
      description: "",
      keywords: ""
    }
  }

  get name() {
    return this.$store.state.userInfo?.userInfo?.name
  }

  // ssr hook
  serverPrefetch() {
    if (!this.name) {
      return Promise.resolve()
    }
    updateTdk.call(this)
    return Promise.resolve()
  }

  beforeMount() {
    if (!this.name) {
      this.$router.replace("/login")
    }
    updateTdk.call(this)
  }

  handleDownload() {
    window.open("https://ghzs.com", "_blank")
  }
}
