import { apiApp, apiBaseUrl, apiBaseUrlV5, apiBaseUrlV5_5 } from "@/config"
import { AxiosInstance, AxiosResponse } from "axios"
import { IListParams } from "../api"

export default function(
  service: AxiosInstance,
  serviceWithToken: AxiosInstance
) {
  return {
    video: {
      // 视频帖详情
      detail(video_id: string): Promise<AxiosResponse<NVideo.IDetail>> {
        return serviceWithToken.get(`/bbses/videos/${video_id}?view=web`)
      },
      // 获取游戏视频的评论列表
      comments(
        video_id: string,
        params: IListParams
      ): Promise<AxiosResponse<NVideoComment.IItem[]>> {
        return serviceWithToken.get(
          `${apiBaseUrlV5_5}/videos/${video_id}/comments?view=web&attached_limit=3`,
          {
            params
          }
        )
      },
      // 获取游戏视频的评论回复
      commentsReplys(
        video_id: string | number,
        comment_id: string | number
      ): Promise<AxiosResponse<NVideoComment.IItem[]>> {
        return serviceWithToken.get(
          `${apiBaseUrlV5_5}/videos/${video_id}/comments/${comment_id}/replies`
        )
      },
      // 视频-相关/推荐视频
      recommends(
        video_id: string
      ): Promise<AxiosResponse<NVideoRecommend.IItem[]>> {
        return serviceWithToken.get(
          `/bbses/videos/${video_id}/recommends?page_size=10`
        )
      },
      getSTSConfig() {
        return serviceWithToken.get(`${apiApp}/sts/tos`, {
          params: { type: "game_video" }
        })
      },
      uploadImage(
        type: string,
        formData: any
      ): Promise<AxiosResponse<{ url: string }>> {
        return serviceWithToken.post(
          `${apiBaseUrlV5_5}/images?type=${type}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          }
        )
      },
      create(data: ICreateVideo) {
        return serviceWithToken.post(`${apiBaseUrlV5_5}/bbses/videos`, data)
      },
      actTagList(location: "bbs_video" | "bbs_article", bbs_id: string) {
        return serviceWithToken.get(`${apiBaseUrlV5}/videos/activity_tags`, {
          params: { location, bbs_id }
        })
      },

      drafts: {
        list(user_id: string): Promise<AxiosResponse<IDraftItem[]>> {
          return serviceWithToken.get(
            `${apiBaseUrlV5}/users/${user_id}/video_drafts`
          )
        },
        detail(
          user_id: string,
          draft_id: string
        ): Promise<AxiosResponse<IDraftItem>> {
          return serviceWithToken.get(
            `${apiBaseUrlV5}/users/${user_id}/video_drafts/${draft_id}`
          )
        },
        create(user_id: string, data: ICreateVideoDraft) {
          return serviceWithToken.post(
            `${apiBaseUrlV5}/users/${user_id}/video_drafts`,
            data
          )
        },
        edit(user_id: string, draft_id: string, data: ICreateVideoDraft) {
          return serviceWithToken.post(
            `${apiBaseUrlV5}/users/${user_id}/video_drafts/${draft_id}`,
            data
          )
        },
        del(user_id: string, draft_id: string) {
          return serviceWithToken.delete(
            `${apiBaseUrlV5}/users/${user_id}/video_drafts/${draft_id}`
          )
        }
      },
      // 插入视频（只适用于发帖子和问题）
      insertVideo(
        data: IInsertVideo
      ): Promise<AxiosResponse<IInsertVideoResult>> {
        return serviceWithToken.post(`/bbses/insert_videos`, data)
      },
      // 修改插入视频（只适用于发帖子和问题）
      modifyInsertVideoPoster(
        video_id: string,
        data: { type: string; poster: string }
      ) {
        return serviceWithToken.post(`/bbses/insert_videos/${video_id}`, data)
      }
    }
  }
}

interface ISTSRes {
  AccessKeyId: string
  AccessKeySecret: string
  Expiration: Date
  SecurityToken: string
  EndPoint: string
  Bucket: string
  Domain: string
  Key: string
}

export interface ICreateVideo {
  title: string
  des: string
  poster: string
  url: string
  format: string
  size: number
  length: number
  type: string
  tag_activity_id: string
  bbs_id: string
  game_id: string
  original: string
  video_info: {
    width: number
    height: number
  }
}

export interface IDraftItem {
  _id: string
  title: string
  poster: string
  url: string
  local_path: string
  format: string
  size: number
  length: number
  des: string
  game_id: string
  bbs_id: string
  bbs_name: string
  type: string
  tag_activity_id: string
  tag_activity_name: string
  original: string
  source: string
  user: User
  time: Time
  video_info: VideoInfo
}

interface Time {
  create: number
  update: number
}
interface User {
  _id: string
  name: string
  icon: string
}

export interface VideoInfo {
  width: number
  height: number
}

export interface ICreateVideoDraft {
  title: string
  des: string
  poster: string
  url: string
  local_path: string
  format: string
  size: number
  length: number
  game_id: string
  bbs_id: string
  type: string
  tag_activity_id: string
  tag_activity_name?: string
  original: string
  source: string
  video_info?: VideoInfo
}

interface IInsertVideo {
  poster: string
  url: string
  format: string
  size: number
  length: number
  type: string
}

interface IInsertVideoResult {
  _id: string
  url: string
  poster: string
  length: number
}

export namespace NVideo {
  export interface IDetail {
    _id: string
    title: string
    des: string
    poster: string
    url: string
    length: number
    type: string
    bbs_id: string
    vote: number
    comment_count: number
    favorite: number
    share: number
    user: IUser
    status: string
    time: ITime
    bbs?: IBb
    game?: IGame
    tag_activity_id: string
    tag_activity_name: string
    video_info: IVideo_info
    choiceness_status: string
    me?: IMe
    original: string
  }

  interface IMe {
    is_follower: boolean
    is_video_favorite: boolean
    is_voted: boolean
  }

  interface IVideo_info {
    width: number
    height: number
  }

  interface IGame {
    _id: string
    name: string
    name_suffix: string
    category: string
    brief: string
    icon: string
    ori_icon: string
    type_tag: void /* undefined */[]
    download: number
    show_comment: boolean
    direct_comment: boolean
    ignore_comment: boolean
    comment_description: string
    mirror_status: string
    comment_count: number
    version_number: string
    new_star: number
    tag: void /* undefined */[]
    download_off_text: string
    apk_normal: void /* undefined */[]
    apk: void /* undefined */[]
    d_button_add_word: string
    p_button_add_word: string
    concern_article_exists: boolean
    libao_exists: boolean
    zone_setting: IZone_setting
    _seq?: number
  }

  interface IZone_setting {
    status: string
    style: string
  }

  interface IBb {
    _id: string
    name: string
    game_id: string
    _seq: string
    icon: string
  }

  interface ITime {
    upload: number
    audit: number
    update: number
  }

  interface IUser {
    _id: string
    name: string
    icon: string
    icon_border_url: string
    badge: IBadge
    count: ICount
  }

  interface ICount {
    fans: number
  }

  interface IBadge {
    name: string
    icon: string
    actions: IAction[]
  }

  interface IAction {
    text: string
  }
}

export namespace NVideoComment {
  export interface IItem {
    _id: string
    content: string
    user: IUser
    time: number
    me: IMe
    attached: IAttached[]
    reply: number
    is_top?: boolean
    images?: string[]
    vote: number
    _seq: number
  }

  interface IAttached {
    _id: string
    video_id: string
    game_id: string
    content: string
    active: boolean
    type: string
    user: IAttachedUser
    trace: string[]
    vote: number
    time: number
    operator: IAttachedOperator
    priority: number
    _seq: number
    me: IAttachedMe
  }

  interface IAttachedMe {
    is_video_own: boolean
  }

  interface IAttachedOperator {
    creator: string
    modifier: string
  }

  interface IAttachedUser {
    _id: string
    name: string
    icon: string
    badge: IBadge
  }

  interface IBadge {
    name: string
    icon: string
    actions: IAction[]
  }

  interface IAction {
    text: string
  }
  interface IMe {
    is_video_commented: boolean
    is_video_comment_voted: boolean
    is_video_own: boolean
  }

  interface IUser {
    _id: string
    icon: string
    name: string
    auth: IAuth
    badge?: IBadge
  }

  interface IBadge {
    name: string
    icon: string
    actions: IAction[]
  }

  interface IAction {
    text: string
  }

  interface IAuth {
    text: string
    color: string
    icon: string
  }
}

export namespace NVideoRecommend {
  export interface IItem {
    length: number
    title: string
    url: string
    des: string
    poster: string
    game_id: string
    _id: string
    user: IUser
    count: ICount
    _seq: number
  }

  interface ICount {
    vote: number
    comment: number
    favorite: number
  }

  interface IUser {
    _id: string
    name: string
    icon: string
  }
}
