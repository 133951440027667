const Tools = Vue => {
  // 自动聚焦
  Vue.directive("focus", {
    inserted: function(el) {
      el.focus()
    }
  })

  // 使页脚保持在页面底部
  Vue.directive("footer", {
    bind: function(el) {
      el.style.opacity = 0
    },
    inserted: function(el, { value }) {
      setFooterStyle(el, value)
    },
    componentUpdated: function(el, { value }) {
      const timer = setTimeout(() => {
        setFooterStyle(el, value)
        clearTimeout(timer)
      }, 17)
    }
  })
}

/**
 * 设置页脚位置
 * @param el - 指令所绑定的元素，可以用来直接操作 DOM
 * @param value - 父组件id名称
 */
function setFooterStyle(el, value: string) {
  if (!value) return
  const childNodes = document.querySelector(`#${value}`).children
  let countHeight = 0
  for (let i = 0; i < childNodes.length; i++) {
    countHeight += childNodes[i].scrollHeight
  }

  el.style.opacity === "1" ? null : (el.style.opacity = 1)
  if (countHeight < document.body.clientHeight) {
    el.style.position = "absolute"
    el.style.bottom = "0px"
    el.style.left = "0px"
    el.style.height = "120px"
  } else {
    el.style.position = "relative"
  }
}

export default Tools
