



























import { Component, Vue } from "vue-property-decorator"

@Component({
  name: "Layout",
  components: {
    Header: () => import("@/components/header/header.vue"),
    BackTop: () => import("@/components/backtop/backtop.vue"),
    Menu: () => import("@/components/menu/menu.vue"),
    Footer: () => import("@/components/footer/footer.vue")
  }
})
export default class Layout extends Vue {}
