





















import { IBreadcrumbItem } from "@/components/breadcrumb/extra"
import { Component, Vue } from "vue-property-decorator"

@Component({
  name: "BbsList",
  components: {
    Content: () => import("./components/Content.vue"),
    Empty: () => import("@/components/empty/empty.vue"),
    Sticky: () => import("@/components/sticky/sticky.vue"),
    HotBbs: () => import("@/views/BbsList/components/HotBbs.vue"),
    ScanDownload: () => import("@/components/scanDownload/scanDownload.vue"),
    OfficialBbs: () => import("./components/OfficialBbsList.vue"),
    Breadcrumb: () => import("@/components/breadcrumb/breadcrumb.vue")
  }
})
export default class BbsList extends Vue {
  breads: IBreadcrumbItem[] = [{ name: "论坛首页" }]
}
