






import { Component, Vue } from "vue-property-decorator"

@Component
export default class Auth extends Vue {
  mounted() {
    console.log("auth")
  }
  getUserInfo() {
    this.$root.$tstore.modules.userInfo.actions.FETCH_USER_INFO().then(() => {
      if (sessionStorage.getItem("BEFORE_LOGIN_PATH")) {
        this.$router.push(sessionStorage.getItem("BEFORE_LOGIN_PATH"))
      }
    })
  }
}
