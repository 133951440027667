











import { Component, Vue, Provide } from "vue-property-decorator"
import { isPC } from "@/utils/base"
import { isProdDeploy } from "@/config"
import { Log } from "@/utils/log"
@Component({
  name: "App"
})
export default class App extends Vue {
  pvLog = () => {}
  beforeMount() {
    this.judgePath()

    if (!this.$store.getters["userInfo/isLogin"]) {
      if (this.$cookies.get("access_token")) {
        this.$root.$tstore.getters.api.auth
          .getUserInfo()
          .then(res => {
            this.$root.$tstore.modules.userInfo.mutations.SET_USER_INFO({
              userInfo: res.data
            })
          })
          .catch(error => {
            if (error.response && error.response.data.code === 401001) {
              this.refreshToken()
            }
          })
      } else {
        this.refreshToken()
      }
    }

    if (!this.$root.$tstore.modules.gameList.getters.filterTags) {
      this.$root.$tstore.modules.gameList.actions.FETCH_FILTER_TAGS()
    }
  }

  refreshToken() {
    if (this.$cookies.get("refresh_token")) {
      this.$root.$tstore.getters.api.auth
        .refreshToken(this.$cookies.get("refresh_token"))
        .then(res => {
          this.$cookies.set("access_token", res.data.access_token.value, {
            expires: res.data.access_token.expire
          })
          this.$cookies.set("refresh_token", res.data.refresh_token.value, {
            expires: res.data.refresh_token.expire
          })
          this.$root.$tstore.actions.UPDATE_API_TOKEN({
            access_token: res.data.access_token.value
          })
          this.$root.$tstore.modules.userInfo.actions.FETCH_USER_INFO()
        })
    }
  }
  adminPreviewRoutes = [
    "article",
    "communityArticle",
    "communityArticleThread",
    "video",
    "question"
  ]
  /**
   * @description issue https://git.ghzs.com/pm/web-issues/-/issues/142
   * !(this.$route.query.flag && this.$route.query.flag === "preview") ?
   */
  judgePath() {
    if (!isPC() && isProdDeploy()) {
      if (
        this.adminPreviewRoutes.includes(this.$route.name) &&
        this.$route.query.from &&
        this.$route.query.from === "admin"
      ) {
        return
      }
      if (this.$route.name === "login" || this.$route.name === "mine") {
        window.location.href = "https://m.ghzs666.com"
      } else {
        window.location.href = `https://m.ghzs666.com${this.$route.fullPath}`
      }
    } else {
      // web埋点
      this.pvLog = Log()
    }
  }

  serverPrefetch() {
    return this.$root.$tstore.modules.gameList.actions.FETCH_FILTER_TAGS()
  }

  beforeDestroy() {
    if (this.pvLog && this.pvLog["clearEvent"]) this.pvLog["clearEvent"]()
  }
}
