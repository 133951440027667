













import { Component, Vue, Watch } from "vue-property-decorator"

@Component({
  name: "Game",
  components: {
    RelatedGame: () => import("./components/RelatedGame/RelatedGame.vue"),
    ScanDownload: () => import("@/components/scanDownload/scanDownload.vue"),
    FooterCard: () => import("@/components/footerCard/footerCard.vue")
  },
  beforeRouteUpdate(to, from, next) {
    if (to.params.game_id !== this.$route.params.game_id) {
      this.gameDetail = null
      this.gameAbstract = null
      this.fetchData(to.params.game_id)
    }

    next()
  }
})
export default class Game extends Vue {
  relatedGames = []
  gameAbstract = null
  gameDetail = null
  // ssr hook
  serverPrefetch() {
    return this.fetchData()
  }

  beforeMount() {
    if (
      !this.$store.state.gameList.gameDetail ||
      !this.$store.state.gameList.gameAbstract ||
      !this.$store.state.gameList.filterTags
    ) {
      this.fetchData()
    } else {
      this.formatData()
    }
  }
  fetchData(game_id?) {
    const id = game_id || this.$route.params.game_id
    return new Promise(async (resolve, reject) => {
      try {
        await this.$root.$tstore.modules.gameList.actions.FETCH_GAME_ABSTRACT({
          game_id: id
        })
        await this.$root.$tstore.modules.gameList.actions.FETCH_GAME_DETAIL({
          game_id: id
        })
        await this.$store.dispatch("gameList/FETCH_FILTER_TAGS")
        this.formatData()
        resolve("")
      } catch (error) {
        reject(error)
        if (!this.$isServer) {
          this.$router.push("/404")
        }
      }
    })
  }

  formatData() {
    // console.log(this.$store.state.gameList.gameAbstract)
    // console.log(
    //   this.$store.state.gameList.gameDetail,
    //   this.$store.state.gameList.gameAbstract,
    //   this.$store.state.gameList.gameEvents
    // )

    this.gameAbstract = JSON.parse(
      JSON.stringify(this.$store.state.gameList.gameAbstract)
    )
    this.gameDetail = JSON.parse(
      JSON.stringify(this.$store.state.gameList.gameDetail)
    )

    if (this.gameAbstract.new_tag_style) {
      this.gameAbstract.new_tag_style = this.gameAbstract.new_tag_style.filter(
        e => !this.$store.state.gameList.filterTags.includes(e.name)
      )
      this.$root.$tstore.modules.gameList.mutations.SET_GAME_ABSTRACT({
        gameAbstract: this.gameAbstract
      })
    }

    const relatedGameFilter = this.gameDetail.detail_tab.filter(
      v => v.type === "related_game"
    )
    if (relatedGameFilter.length > 0) {
      this.relatedGames = relatedGameFilter[0].related_game
    }
  }

  destroyed() {
    this.$store.commit("gameList/SET_GAME_DETAIL", { gameDetail: null })
  }
}
