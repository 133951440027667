import { render, staticRenderFns } from "./JXWD.vue?vue&type=template&id=17d17748&scoped=true"
import script from "./JXWD.vue?vue&type=script&lang=ts"
export * from "./JXWD.vue?vue&type=script&lang=ts"
function injectStyles (context) {
  
  var style0 = require("./JXWD.vue?vue&type=style&index=0&id=17d17748&prod&lang=scss&scoped=true")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "17d17748",
  "9d6411d2"
  
)

export default component.exports