import { MHome } from "@/api/api"
import { ActionContext } from "vuex"
import { IRootState } from ".."
interface IState {
  columns: MHome.IColumn[]
  tags: MHome.ITag[]
  contents: MHome.IContent[]
  isLoading: boolean
}

type Context = ActionContext<IState, IRootState>

export const home = {
  namespaced: true,
  state: () => ({
    columns: null,
    tags: null,
    contents: null,
    isLoading: false
  }),
  getters: {
    columns: (state: IState) => state.columns,
    tags: (state: IState) => state.tags,
    contents: (state: IState) => state.contents,
    isLoading: (state: IState) => state.isLoading
  },
  mutations: {
    SET_COLUMNS: (state: IState, data) => {
      state.columns = data
    },
    SET_TAGS: (state: IState, data) => {
      state.tags = data
    },
    SET_CONTENTS: (state: IState, data) => {
      state.contents = data
    },
    SET_LOADING: (state: IState, data) => {
      state.isLoading = data
    }
  },
  actions: {
    FETCH_HOMES: ({ commit, rootState: { api }, getters }: Context) => {
      if (getters.isLoading) return Promise.resolve()
      return new Promise(async (resolve, reject) => {
        try {
          commit("SET_LOADING", true)
          const res = await api.games.homeList()
          const { columns, contents, tags } = res.data

          commit("SET_COLUMNS", columns)
          commit("SET_TAGS", tags)
          commit("SET_CONTENTS", contents)
          commit("SET_LOADING", false)
          resolve("")
        } catch (error) {
          reject(error)
        }
      })
    }
  }
}
