



































import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import api from "@/api/api"
@Component({
  name: "memberDialog",
  components: {
    NoMoreData: () => import("@/components/noMoreData/noMore.vue")
  }
})
export default class memberDialog extends Vue {
  // 通用弹窗的JS数据和方法
  cancel() {
    this.isSubmit = false
    this.dialogVisible = false
  }

  onSubmit() {
    this.isSubmit = true
    this.dialogVisible = false
  }

  dialogDataPresets = {
    bbs_id: "",
    list: []
  }

  isRender: boolean = true
  isSubmit: boolean = false

  @Prop()
  dialogVisible: boolean = false

  list = []
  // async getList() {
  //   try {
  //     const res = await this.$store.state.api.bbs.moderators(
  //       this.dialogDataPresets.bbs_id
  //     )
  //     this.list = res.data
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

  @Watch("dialogVisible")
  onShow(c) {
    if (c) {
      this.list = this.dialogDataPresets.list
    }
  }
}
