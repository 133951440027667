import { render, staticRenderFns } from "./HotBbs.vue?vue&type=template&id=100ccdf8&scoped=true"
import script from "./HotBbs.vue?vue&type=script&lang=ts"
export * from "./HotBbs.vue?vue&type=script&lang=ts"
function injectStyles (context) {
  
  var style0 = require("./HotBbs.vue?vue&type=style&index=0&id=100ccdf8&prod&scoped=true&lang=scss")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "100ccdf8",
  "e56bd9ae"
  
)

export default component.exports