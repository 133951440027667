import { TTStore } from "@/store"
import GameDialog from "../../extra/gameDialog"

// 第一，菜单 class ，Button 菜单继承 BtnMenu class
export default function($, BtnMenu, tstore?: TTStore) {
  return class customMenu extends BtnMenu {
    constructor(editor) {
      // data-title属性表示当鼠标悬停在该按钮上时提示该按钮的功能简述
      const $elem = $(
        `<div class="w-e-menu" data-title="引用游戏">
            <i class="w-e-icon-insert-game"></i>
         </div>`
      )
      super($elem, editor)
    }
    // 菜单点击事件
    clickHandler() {
      // 做任何你想做的事情
      // 可参考【常用 API】文档，来操作编辑器
      // console.log("menuList", this.editor.menus)
      // this.editor.menus.menuList[1].dropList.conf.clickHandler("<h1>")
      GameDialog(
        {
          rmAfterDestroy: true,
          presetData: {
            api: tstore.state.api
          }
        },
        ({ selected }) => {
          // console.log(selected)
          let tags = ""
          if (selected.tag && selected.tag.length > 0) {
            tags = selected.tag.map(v => `<label>${v}</label>`).join("")
          }
          const data = {
            brief: selected.brief,
            communityId: "",
            icon: selected.icon,
            id: selected._id,
            tags: selected.tag,
            title: selected.name,
            type: "game"
          }
          this.editor.cmd.do(
            "insertHTML",
            `
            <br/><div class='game-container'>
            <a class='game' href="javascript:void(0);" contenteditable="false" onclick="customLinkgo(this)" data-datas='${JSON.stringify(
              data
            )}'>
              <div class='del-icon-box'><img onclick="delLink(this)" width="100%" height="100%" src="http://static-web.ghzs.com/website-static/images/icon_del.png"></div>
              <div class='flex-container'>
                <div class='gh-internal-content img-left'>
                  <img class='image-link' src='${selected.icon}' />
                </div>
                <div class='gh-internal-content content-right'>
                  <p class='content-title'>${selected.name}</p>
                  <p class='tags'>${tags}</p>
                </div>
              </div>
            </a>
          </div><p>&nbsp;<br></p>
        `
          )
        }
      )
    }
    // 菜单是否被激活（如果不需要，这个函数可以空着）
    // 1. 激活是什么？光标放在一段加粗、下划线的文本时，菜单栏里的 B 和 U 被激活，如下图
    // 2. 什么时候执行这个函数？每次编辑器区域的选区变化（如鼠标操作、键盘操作等），都会触发各个菜单的 tryChangeActive 函数，重新计算菜单的激活状态
    tryChangeActive() {
      // 激活菜单
      // 1. 菜单 DOM 节点会增加一个 .w-e-active 的 css class
      // 2. this.this.isActive === true
      this.active()

      // // 取消激活菜单
      // // 1. 菜单 DOM 节点会删掉 .w-e-active
      // // 2. this.this.isActive === false
      // this.unActive()
    }
  }
}
