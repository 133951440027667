import {
  INovelColumnItem,
  INovelHotItem,
  INovelSlideItem
} from "@/api/modules/novel"
import { ActionContext } from "vuex"
import { IRootState } from ".."
interface IState {
  slideList: INovelSlideItem[]
  hotList: INovelHotItem[]
  columns: IColumns
}

type Context = ActionContext<IState, IRootState>

export const novel = {
  namespaced: true,
  state: () => ({
    slideList: null,
    hotList: null,
    columns: null
  }),
  getters: {
    slideList: (state: IState) => state.slideList,
    hotList: (state: IState) => state.hotList,
    columns: (state: IState) => state.columns
  },
  mutations: {
    SET_SLIDE_LIST: (state: IState, { list }) => {
      state.slideList = list
    },
    SET_HOT_LIST: (state: IState, { list }) => {
      state.hotList = list
    },
    SET_COLUMNS: (state: IState, data: IColumns) => {
      state.columns = data
    }
  },
  actions: {
    FETCH_SLIDE_LIST: ({ commit, rootState: { api } }: Context) => {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await api.novel.slideList()
          commit("SET_SLIDE_LIST", { list: res.data })
          resolve("")
        } catch (error) {
          reject(error)
        }
      })
    },
    FETCH_HOT_LIST: ({ commit, rootState: { api } }: Context) => {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await api.novel.hotList()
          commit("SET_HOT_LIST", { list: res.data })
          resolve("")
        } catch (error) {
          reject(error)
        }
      })
    },
    FETCH_COLUMNS: ({ commit, rootState: { api } }: Context, page?: number) => {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await api.novel.columnList(page)

          commit("SET_COLUMNS", {
            list: res.data,
            total: parseInt(res.headers.total),
            finished: res.data.length >= res.headers.total
          })
          resolve("")
        } catch (error) {
          reject(error)
        }
      })
    }
  }
}

export interface IColumns {
  list: INovelColumnItem[]
  finished: boolean
  total: number
}
