export const userInfo = {
  namespaced: true,
  state: () => ({
    userInfo: null
  }),
  mutations: {
    SET_USER_INFO: (state, { userInfo }) => {
      state.userInfo = userInfo
    }
  },
  actions: {
    FETCH_USER_INFO: ({ commit, rootState: { api } }) => {
      return api.auth
        .getUserInfo()
        .then(res => commit("SET_USER_INFO", { userInfo: res.data }))
    }
  },
  getters: {
    // this.$store.getters["userInfo/isLogin"]
    isLogin: state => {
      return !!state.userInfo
    },
    userInfo: state => state.userInfo
  }
}
