import { render, staticRenderFns } from "./Libao.vue?vue&type=template&id=15a6bab0&scoped=true"
import script from "./Libao.vue?vue&type=script&lang=ts"
export * from "./Libao.vue?vue&type=script&lang=ts"
function injectStyles (context) {
  
  var style0 = require("./style.scss?vue&type=style&index=0&id=15a6bab0&prod&scoped=true&lang=scss&external")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "15a6bab0",
  "011bce30"
  
)

export default component.exports