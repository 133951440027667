




























import { Component, Vue, Prop, Watch } from "vue-property-decorator"
interface IRelatedGame {
  score: number
  game: string[]
}
@Component({
  name: "RelatedGame"
})
export default class RelatedGame extends Vue {
  @Prop({ type: Array, default: () => [] })
  related_game: IRelatedGame[]

  gameIds = []

  gameData = []

  async getGameList() {
    const ids = this.gameIds.splice(0, 8)
    const result = await Promise.allSettled(
      ids.map(v => {
        return this.$root.$tstore.getters.api.games.detail(v)
      })
    )
    this.gameData = []
    result.forEach(item => {
      if (item.status === "fulfilled") {
        this.gameData.push(item.value.data)
      }
    })
  }

  @Watch("related_game", { immediate: true })
  onIds(related_game) {
    if (related_game) {
      this.gameIds = related_game
        .filter(v => v.game_seq)
        .map(v => v.game_seq)
        .reduce((pre, cur) => {
          return pre.concat(cur)
        }, [])
      this.getGameList()
    }
  }
}
