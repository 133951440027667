















/**
 * @description 更新内容
 */
import { Component, Vue } from "vue-property-decorator"

@Component({
  name: "DetailUpdateContent",
  components: {
    Ellipsis: () => import("@/components/Ellipsis/Ellipsis.vue")
  }
})
export default class DetailUpdateContent extends Vue {
  // 游戏详情
  get newDetail() {
    return this.$root.$tstore.modules.gameList.getters.gameDetail
  }

  get update() {
    const item = this.newDetail.detail_tab.find(v => v.type === "update")
    return item ? item.update : null
  }

  showExpandAll = false

  handleExpandAll() {
    this.showExpandAll = true
  }
}
