










































/**
 * @description 页脚
 */
import { Component, Vue } from "vue-property-decorator"

@Component({
  name: "Footer"
})
export default class Footer extends Vue {
  titleIcon = require("@/assets/images/logo-顶部导航栏.png")

  handleJumpSite() {
    window.open("https://beian.miit.gov.cn/#/Integrated/index")
  }

  handleLicence() {
    window.open("https://resource.ghzs.com/page/license/www.html")
  }

  handleLink(url) {
    window.open(url, "_blank")
  }

  get originList() {
    return this.$root.$tstore.modules.basic.getters.links
  }
  get informations() {
    return this.$root.$tstore.modules.basic.getters.informations
  }

  showContact = true
  list = []
  // ssr hook
  serverPrefetch() {
    return this.fetchData()
  }

  beforeMount() {
    if (!this.originList) {
      this.fetchData()
    } else {
      this.formatData()
    }
  }

  fetchData() {
    return this.$root.$tstore.modules.basic.actions
      .FETCH_INFO()
      .then(() => {
        this.formatData()
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {})
  }

  formatData() {
    this.list = JSON.parse(JSON.stringify(this.originList))

    if (
      this.informations &&
      this.informations.filter(v => v.title === "联系我们").length > 0
    ) {
      this.showContact = true
    } else {
      this.showContact = false
    }
  }
}
