import Vue from "vue"
import App from "./App.vue"
import createRouter from "./router"
import createStore from "./store"
import "./styles/reset.css"
import "./plugins"
import globalMixin from "@/plugins/mixin"
import Tools from "@/utils/tools"
import createApi from "./api/api"

Vue.mixin({
  ...globalMixin
})
Tools(Vue)

Vue.config.productionTip = false

export function createApp({ access_token }) {
  const router = createRouter()
  const api = createApi({ access_token })
  const $tstore = createStore(api)

  const app = new Vue({
    router,
    store: $tstore.store,
    render: h => h(App)
  })

  app.$tstore = $tstore

  return { app, router, $tstore, api }
}
