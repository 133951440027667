




















import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import { IBreadcrumbItem } from "./extra"

@Component({
  name: "Breadcrumb",
  components: {}
})
export default class Breadcrumb extends Vue {
  @Prop({ default: () => [] })
  breads: IBreadcrumbItem[]
}
