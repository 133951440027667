












import { Component, Vue, Watch } from "vue-property-decorator"
import updateTdk from "@/utils/tdk"
import { IBreadcrumbItem } from "@/components/breadcrumb/extra"

@Component({
  name: "Contact",
  components: {
    GameRecommend: () => import("@/components/GameRecommend/GameRecommend.vue"),
    Breadcrumb: () => import("@/components/breadcrumb/breadcrumb.vue")
  },
  tdk() {
    return this.$store.state.tdk
  }
})
export default class Contact extends Vue {
  breads: IBreadcrumbItem[] = [
    { name: "首页", path: "/" },
    { name: "联系我们" }
  ]

  get content() {
    return this.$root.$tstore.modules.basic.getters.content
  }

  // ssr hook
  serverPrefetch() {
    return this.fetchData()
  }

  beforeMount() {
    if (!this.content) {
      this.fetchData()
    }
  }

  async fetchData() {
    return new Promise(async (resolve, reject) => {
      try {
        await this.$root.$tstore.modules.basic.actions.FETCH_INFO()
        const infos = this.$root.$tstore.modules.basic.getters.informations
        if (infos && infos.filter(v => v.title === "联系我们")[0]) {
          await this.$root.$tstore.modules.basic.actions.FETCH_CONTENT({
            _id: infos.filter(v => v.title === "联系我们")[0]._id
          })
        }
        resolve("")
      } catch (error) {
        reject(error)
      }
    })
  }
}
