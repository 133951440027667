export const isProd = process.env.NODE_ENV === "production"

export function isProdDeploy() {
  return isProd && process.env.NODE_DEPLOY && process.env.NODE_DEPLOY === "prod"
}

export const apiBaseUrl = isProdDeploy()
  ? `https://and-api.ghzs.com`
  : `https://dev-and-api.ghzs.com`

export const apiBaseWikiUrl = isProdDeploy()
  ? `https://api-wiki-game.ghzs.com/v1d0`
  : `https://dev-api-wiki-game.ghzs.com/v1d0`

export const apiBaseUrlV5 = isProdDeploy()
  ? `https://and-api.ghzs.com/v5d0d0`
  : `https://dev-and-api.ghzs.com/v5d0d0`

export const apiBaseUrlV5_5 = isProdDeploy()
  ? `https://and-api.ghzs.com/v5d5d0`
  : `https://dev-and-api.ghzs.com/v5d5d0`

export const apiApp = isProdDeploy()
  ? `https://app-api.ghzs.com`
  : `https://dev-app-api.ghzs.com`

export const createArticleLink = id => {
  return isProdDeploy()
    ? `https://m.ghzs666.com/article/${id}#/`
    : `https://static-web.ghzs.com/ghzs_forum_dev/android-article.html?${id}.html#/`
}

export enum TLocalStorageTypes {
  "CLICKEDTOOLS" = "CLICKEDTOOLS",
  "VIDEO_LIST_VOLUME" = "VIDEO_LIST_VOLUME",
  "VIDEO_DETAIL_VOLUME" = "VIDEO_DETAIL_VOLUME"
}
