






















/**
 * @description 图片组件，提供图片填充的模式，支持加载失败提示
 * 图片填充模式object-fit参见：
 * https://developer.mozilla.org/zh-CN/docs/Web/CSS/object-fit
 */
import { Component, Vue, Prop } from "vue-property-decorator"

@Component({
  name: "HaloImage"
})
export default class HaloImage extends Vue {
  @Prop({ type: String, default: "" }) src: string // 图片路径
  @Prop({ type: [Number, String], default: 0 }) width!: string | number // 宽度
  @Prop({ type: [Number, String], default: 0 }) height!: string | number // 高度
  @Prop({ type: String, default: "fit" }) mode!: string // 图片填充模式 [contain | cover | fill | none | scale-down]
  @Prop({ type: Number, default: 1 }) errIconType!: number // 异常图片 [1:用户  2:媒体  3:论坛]
  @Prop({ type: Boolean, default: false }) border!: boolean // 边框
  @Prop({ type: String, default: "" }) alt?: string

  error: boolean = false
  loading: boolean = true

  get styles() {
    // 支持由父级样式覆盖
    return {
      width: this.width === 0 ? null : this.suffix(this.width),
      height: this.height === 0 ? null : this.suffix(this.height)
    }
  }

  get errImg() {
    switch (this.errIconType) {
      case 2:
        return require("@/assets/images/icon-默认图标-论坛.png")

      default:
        return require("@/assets/images/缺省插图-无搜索结果.png")
    }
  }

  // 支持后缀px/vw/vh/rem/em/%
  suffix(val: string | number) {
    if (typeof val === "string" && val === JSON.stringify(parseFloat(val))) {
      // 值为字符串，并且没有后缀
      return parseFloat(val) + "px"
    } else if (typeof val === "number") {
      // 值为数字，以像素为基准
      return val + "px"
    } else {
      // 值带有后缀，保持原始值
      return val
    }
  }

  onLoad() {
    this.loading = false
  }

  onError() {
    this.loading = false
    this.error = true
  }
}
