




























import { Component, Vue, Prop } from "vue-property-decorator"

@Component({
  name: "gameInfo",
  components: {}
})
export default class gameInfo extends Vue {
  @Prop({ type: String, default: "" })
  name: string

  @Prop({ type: String, default: "" })
  src: string

  @Prop({ type: [String, Number], default: "" })
  gameId: string | number

  @Prop({ type: [String, Number], default: "" })
  bbsId: string
}
