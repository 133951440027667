




























import { Component, Vue, Prop } from "vue-property-decorator"

@Component({
  name: "GameRecommend"
})
export default class GameRecommend extends Vue {
  get list() {
    return this.$root.$tstore.modules.gameList.getters.recommendList
  }

  // ssr hook
  serverPrefetch() {
    return this.fetchData()
  }

  mounted() {
    if (!this.list) {
      this.fetchData()
    }
  }

  fetchData() {
    return this.$root.$tstore.modules.gameList.actions.FETCH_RECOMMEND_LIST({})
  }

  // destroyed() {
  //   this.$store.commit("gameList/SET_RECOMMEND_LIST", { recommendList: null })
  // }
}
