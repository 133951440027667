




















import { MBasic } from "@/api/modules/basic"
import { Component, Prop, Vue, Watch } from "vue-property-decorator"

@Component({
  name: "MapItem",
  components: {
    GameRecommend: () => import("@/components/GameRecommend/GameRecommend.vue"),
    Breadcrumb: () => import("@/components/breadcrumb/breadcrumb.vue")
  }
})
export default class MapItem extends Vue {
  @Prop({})
  propData: MBasic.IMapItem
}
