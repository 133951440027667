













































import { Component, Vue, Prop } from "vue-property-decorator"
import { NTagSearch } from "@/api/api"

@Component({
  name: "SearchItem"
})
export default class SearchItem extends Vue {
  @Prop({ type: Object, default: () => {} }) item: NTagSearch.IItem

  handleTag(name) {
    if (name === this.$route.params.tag_name) return
    this.$router.push(`/tag/${name}`)
  }
}
