import { MColumn } from "@/api/modules/column"
import { IRankingItem } from "@/api/modules/ranking"
import { mixin } from "vue/types/umd"
import { ActionContext } from "vuex"
import { IRootState } from ".."
interface IState {
  showFilter: boolean
  list: MColumn.IColumnGameItem[]
  finished: boolean
  columnName: string
}

type Context = ActionContext<IState, IRootState>

export const column = {
  namespaced: true,
  state: () => ({
    list: null,
    showFilter: null,
    finished: null,
    columnName: null
  }),
  getters: {
    list: (state: IState) => state.list,
    showFilter: (state: IState) => state.showFilter,
    finished: (state: IState) => state.finished,
    columnName: (state: IState) => state.columnName
  },
  mutations: {
    SET_COLUMN: (state: IState, { list, finished }) => {
      state.list = list
      state.finished = finished
    },
    SET_SHOW_FILTER: (state: IState, { showFilter }) => {
      state.showFilter = showFilter
    },
    SET_COLUMN_NAME: (state: IState, { name }) => {
      state.columnName = name
    }
  },
  actions: {
    FETCH_COLUMN: (
      { commit, rootState: { api } }: Context,
      column_id: string
    ) => {
      return new Promise(async (resolve, reject) => {
        try {
          const { data: detail } = await api.column.detail(column_id)
          commit("SET_COLUMN_NAME", { name: detail.name })

          const { data: settingData } = await api.column.setting(column_id)
          const showFilter =
            settingData.filter === "on" &&
            settingData.filter_options &&
            settingData.filter_options.length > 0

          const res = await api.column.columnGames(column_id, {
            page: 1,
            page_size: 20
          })

          commit("SET_COLUMN", {
            list: res.data,
            finished: res.data.length >= parseInt(res.headers.total)
          })
          commit("SET_SHOW_FILTER", { showFilter })

          resolve("")
        } catch (error) {
          reject(error)
        }
      })
    }
  }
}
