



































































/**
 * @description 玩家评论
 */
import { Component, Vue, Prop } from "vue-property-decorator"
import { TimestampFormat } from "@/utils/base"

@Component({
  name: "Comment",
  components: {
    HaloImg: () => import("@/components/image/image.vue"),
    Ellipsis: () => import("@/components/Ellipsis/Ellipsis.vue")
  }
})
export default class Comment extends Vue {
  TimestampFormat = TimestampFormat

  @Prop({ type: Array, default: () => [] })
  commentProp
}
