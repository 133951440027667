




import createApi from "@/api/api"
import { Component, Vue } from "vue-property-decorator"
const api = createApi()
@Component({
  name: "Wechat"
})
export default class Wechat extends Vue {
  async mounted() {
    if (this.$route.query.code) {
      this.getToken()
    }
  }
  async getToken() {
    try {
      const res = await api.auth.wechatLogin({
        code: this.$route.query.code as string
      })
      this.$cookies.set("access_token", res.data.access_token.value, {
        expires: res.data.access_token.expire
      })
      this.$cookies.set("refresh_token", res.data.refresh_token.value, {
        expires: res.data.refresh_token.expire
      })
      this.$root.$tstore.actions.UPDATE_API_TOKEN({
        access_token: res.data.access_token.value
      })
      ;(this.$parent as any).getUserInfo()
    } catch (error) {
      console.log(error)
    }
  }
}
