



























import { Component, Prop, Vue, Watch } from "vue-property-decorator"

@Component({
  name: "postSuccessDLG",
  components: {}
})
export default class postSuccessDLG extends Vue {
  // 通用弹窗的JS数据和方法
  cancel() {
    this.isSubmit = false
    this.dialogVisible = false
  }

  onSubmit() {
    this.isSubmit = true
    this.dialogVisible = false
  }

  dialogDataPresets = {}

  isRender: boolean = true
  isSubmit: boolean = false

  @Prop()
  dialogVisible: boolean = false
}
