














import { Component, Vue, Watch } from "vue-property-decorator"
import { IBreadcrumbItem } from "@/components/breadcrumb/extra"
import { isProdDeploy } from "@/config"
import { MBasic } from "@/api/modules/basic"

@Component({
  name: "MapList",
  components: {
    GameRecommend: () => import("@/components/GameRecommend/GameRecommend.vue"),
    Breadcrumb: () => import("@/components/breadcrumb/breadcrumb.vue"),
    MapItem: () => import("./components/mapItem/mapItem.vue")
  }
})
export default class MapList extends Vue {
  breads: IBreadcrumbItem[] = [
    { name: "首页", path: "/" },
    { name: "网站地图" }
  ]
  maps: MBasic.IMapItem[] = []
  get originMaps() {
    return this.$root.$tstore.modules.basic.getters.maps
  }

  // ssr hook
  serverPrefetch() {
    return this.fetchData()
  }

  beforeMount() {
    if (!this.originMaps) {
      this.fetchData()
    } else {
      this.formatData()
    }
  }

  async fetchData() {
    return this.$root.$tstore.modules.basic.actions
      .FETCH_MAPS()
      .then(this.formatData)
  }
  formatData() {
    this.maps = this.originMaps
      .filter(v => v.field && v.contents && v.contents.length > 0)
      .map(v => {
        v.contents = v.contents.map(content => {
          content.url = this.createPcUrl(
            content.link.type,
            content.link.link,
            content.title,
            content.link.link_seq
          )
          return content
        })
        return v
      })
  }

  createPcUrl(type, link, text, link_seq) {
    const baseUrl = isProdDeploy()
      ? "https://www.ghzs666.com/"
      : "https://dev-bbs-desktop.ghzs.com/"
    const pathMap = new Map([
      ["game", `${link_seq || link}`],
      ["bbs", `bbs/${link_seq || link}`],
      ["tag", `tag/${link_seq}`]
    ])
    if (pathMap.get(type)) {
      return baseUrl + pathMap.get(type)
    } else {
      return link
    }
  }
}
