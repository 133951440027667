import Vue from "vue"
import Vuex from "vuex"
Vue.use(Vuex)

import { content } from "./modules/content"
import { article } from "./modules/article"
import { communityArticle } from "./modules/communityArticle"
import { bbs } from "./modules/bbs"
import { game } from "./modules/game"
import { gameList } from "./modules/gameList"
import { userInfo } from "./modules/userInfo"
import { tdk } from "./modules/tdk"
import { toolkit } from "./modules/toolkit"
import { basic } from "./modules/basic"
import { ranking } from "./modules/ranking"
import { novel } from "./modules/novel"
import { column } from "./modules/column"
import { home } from "./modules/home"
import { ques } from "./modules/ques"

import TStore from "vuex-tstore"
import createApi from "@/api/api"

export interface IRootState {
  api: ReturnType<typeof createApi>
}

export default function createStore(api: ReturnType<typeof createApi>) {
  let $tstore = new TStore.Store({
    modules: {
      content,
      article,
      communityArticle,
      bbs,
      game,
      gameList,
      userInfo,
      tdk,
      toolkit,
      basic,
      ranking,
      novel,
      column,
      home,
      ques
    },
    state: () => ({
      api
    }),
    getters: {
      api: (state: IRootState) => state.api
    },
    mutations: {
      SET_API_TOKEN: (state, { api }) => {
        state.api = api
      }
    },
    actions: {
      UPDATE_API_TOKEN: ({ commit }, { access_token }) => {
        commit("SET_API_TOKEN", { api: createApi({ access_token }) })
      }
    }
  })

  return $tstore
}

export type TTStore = ReturnType<typeof createStore>
