import { render, staticRenderFns } from "./DraftDLG.vue?vue&type=template&id=243b6a86&scoped=true"
import script from "./DraftDLG.vue?vue&type=script&lang=ts"
export * from "./DraftDLG.vue?vue&type=script&lang=ts"
function injectStyles (context) {
  
  var style0 = require("./DraftDLG.vue?vue&type=style&index=0&id=243b6a86&prod&scoped=true&lang=scss")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "243b6a86",
  "77667fad"
  
)

export default component.exports