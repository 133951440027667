import { render, staticRenderFns } from "./postSuccessDLG.vue?vue&type=template&id=793a4922&scoped=true"
import script from "./postSuccessDLG.vue?vue&type=script&lang=ts"
export * from "./postSuccessDLG.vue?vue&type=script&lang=ts"
function injectStyles (context) {
  
  var style0 = require("./postSuccessDLG.vue?vue&type=style&index=0&id=793a4922&prod&scoped=true&lang=scss")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "793a4922",
  "044791db"
  
)

export default component.exports