
















































































import { MBbsContent } from "@/api/api"
import { TLocalStorageTypes } from "@/config"
import { timeago } from "@/utils/base"
import { consoleSandbox } from "@sentry/utils"
import { Component, Vue, Prop, Watch } from "vue-property-decorator"

@Component({
  name: "VideoCard",
  components: {
    DPlayerVideo: () => import("@/components/DPlayerVideo/index.vue")
  }
})
export default class VideoCard extends Vue {
  @Prop({ type: Object, default: {} }) data: MBbsContent.IVideo

  $refs: {
    dplayerVideo: any
    videoWrapper: HTMLElement
    videoCard: HTMLElement
  }

  timeago = timeago

  isPlaying = false

  options = {
    screenshot: false,
    video: {
      _id: "",
      url: "",
      pic: "",
      type: "auto"
    },
    volume: 0
  }
  videoWrapper = null
  onLoad(c) {
    // this.image = ""
    // ;(this.$el.querySelector(
    //   ".dplayer-controller"
    // ) as HTMLElement).style.display = "none"
    ;(this.$el.querySelector(".dplayer-notice") as HTMLElement).style.display =
      "none"
    this.isPlaying = true
    // 这里设置声音会导致播放暂停
    // this.$refs.dplayerVideo.dplayer.volume(0.1, true, true)
    this.videoWrapper = this.$el.querySelector(".video-wrapper")
    if (this.videoWrapper) {
      this.videoWrapper.addEventListener("click", this.prevent)
    }
  }
  onPlay() {
    this.isPlaying = true
  }

  onEnd() {
    this.isPlaying = false
  }
  onVolumn(v) {
    if (!!v) {
      localStorage.setItem(TLocalStorageTypes.VIDEO_LIST_VOLUME, v)
    }
  }

  mounted() {
    this.options = {
      screenshot: false,
      video: {
        _id: this.data._id,
        url: this.data.url,
        pic: this.data.poster,
        type: "auto"
      },
      volume: localStorage.getItem(TLocalStorageTypes.VIDEO_LIST_VOLUME)
        ? parseFloat(localStorage.getItem(TLocalStorageTypes.VIDEO_LIST_VOLUME))
        : 0
    }
  }

  prevent(event) {
    event.preventDefault()
    event.stopPropagation()
  }

  beforeDestroy() {
    if (this.videoWrapper) {
      this.videoWrapper.removeEventListener("click", this.prevent)
    }
  }

  handlePlay() {
    this.isPlaying = true

    if (localStorage.getItem(TLocalStorageTypes.VIDEO_LIST_VOLUME)) {
      this.$refs.dplayerVideo.dplayer.volume(
        parseFloat(localStorage.getItem(TLocalStorageTypes.VIDEO_LIST_VOLUME)),
        true,
        true
      )
    } else {
      this.$refs.dplayerVideo.dplayer.volume(0.1, true, true)
    }

    this.$refs.dplayerVideo.dplayer.seek(0)
    this.$refs.dplayerVideo.dplayer.play()
  }
}
