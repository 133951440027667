















































































































import createApi from "@/api/api"
import { Component, Vue, Watch } from "vue-property-decorator"
import updateTdk from "@/utils/tdk"
import { isProdDeploy } from "@/config"

const api = createApi()

@Component({
  name: "Login",
  //进入该页面时，用之前保存的滚动位置赋值
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (from && from.fullPath && !vm.$isServer) {
        sessionStorage.setItem("BEFORE_LOGIN_PATH", from.fullPath)
      }
    })
  },
  tdk() {
    return this.metaInfo
  }
})
export default class Login extends Vue {
  get metaInfo() {
    return {
      title: `登录 | 要好玩，上光环`,
      description: "",
      keywords: ""
    }
  }

  phone = ""
  validateCode = ""
  invitedCode = ""

  showCount = false

  count = 60

  timer = null

  service_id = ""

  async handleCode() {
    if (this.validatePhone()) {
      this.showCount = true
      try {
        const res = await api.auth.getValidateCode(this.phone)
        this.$toast("已发送")

        this.service_id = res.data.service_id
      } catch (error) {
        console.log(error)
      }
    }
  }

  validatePhone() {
    if (this.phone === "") {
      this.$toast.fail("手机号码不能为空！")
      return false
    }

    if (
      !/^((1[3,5,8,7,9][0-9])|(14[5,7])|(17[0,6,7,8])|(19[1,7]))\d{8}$/.test(
        this.phone
      )
    ) {
      this.$toast.fail("请填写正确的手机号码！")
      return false
    }

    return true
  }

  async handleLogin() {
    if (!this.validatePhone()) {
      return false
    }

    if (!this.validateCode.trim()) {
      this.$toast.fail("验证码不能为空！")
      return false
    }

    try {
      const data = {
        mobile: this.phone,
        service_id: this.service_id,
        code: this.validateCode.trim()
      }
      if (this.invitedCode.trim()) {
        data["invite_code"] = this.invitedCode.trim()
      }
      const res = await api.auth.mobileLogin(data)
      this.$cookies.set("access_token", res.data.access_token.value, {
        expires: new Date(res.data.access_token.expire * 1000)
      })
      this.$root.$tstore.actions.UPDATE_API_TOKEN({
        access_token: res.data.access_token.value
      })
      this.$cookies.set("refresh_token", res.data.refresh_token.value, {
        expires: new Date(res.data.refresh_token.expire * 1000)
      })

      this.getUserInfo()
    } catch (error) {
      console.log(error)
    }
  }

  getUserInfo() {
    this.$root.$tstore.modules.userInfo.actions.FETCH_USER_INFO()
  }
  // ssr hook
  serverPrefetch() {
    updateTdk.call(this)
    return Promise.resolve()
  }

  beforeMount() {
    updateTdk.call(this)
  }

  @Watch("$store.state.userInfo.userInfo")
  onLogin(c) {
    if (c) {
      this.$router.back()
    }
  }

  @Watch("showCount")
  onShowCount(c) {
    if (c) {
      this.timer = setInterval(() => {
        this.count--
        if (this.count === 0) {
          clearInterval(this.timer)
          this.count = 60
          this.showCount = false
        }
      }, 1000)
    }
  }

  makeState() {
    return (
      Math.random()
        .toString(36)
        .substring(2, 15) +
      Math.random()
        .toString(36)
        .substring(2, 15)
    )
  }

  handleWxLogin() {
    if (!isProdDeploy()) return
    window.location.href = ` https://open.weixin.qq.com/connect/qrconnect?appid=wxd4fce13dc2f57d3d&redirect_uri=${encodeURIComponent(
      "https://www.ghzs666.com/auth/wechat"
    )}&response_type=code&scope=snsapi_login&state=${this.makeState()}#wechat_redirect`
  }

  handleQQLogin() {
    if (!isProdDeploy()) return
    window.location.href = `https://graph.qq.com/oauth2.0/show?which=Login&display=pc&client_id=101960389&redirect_uri=${encodeURIComponent(
      "https://www.ghzs666.com/auth/qq"
    )}&response_type=code&state=${this.makeState()}`

    // window.QC.Login.showPopup({
    //   appId: "101960389",
    //   redirectURI: "https://www.ghzs666.com/auth/qq"
    // })
  }

  handleWeiboLogin() {
    if (!isProdDeploy()) return
    window.location.href = `https://api.weibo.com/oauth2/authorize?client_id=1114439088&response_type=code&redirect_uri=${encodeURIComponent(
      "https://www.ghzs666.com/auth/weibo"
    )}`
  }
}
