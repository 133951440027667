/**
 * @description 编辑时对传入html添加删除按钮等
 */
export const initUI = editorDom => {
  showDelVideoBtn(editorDom)
  showDelImgBtn(editorDom)
  initPlaceholderVideo(editorDom)
  _showDelLinkBtn(editorDom)
}

function showDelVideoBtn(editorDom) {
  var $videos = editorDom.querySelectorAll(".insert-video-container")
  for (var i = 0; i < $videos.length; i++) {
    var $del = document.createElement("div")
    $del.setAttribute("class", "del-icon-box")
    $del.innerHTML =
      '<img onclick="delVideo(this)" width="100%" height="100%" src="https://resource.ghzs.com/image/article/large/5ffd15f1247e784b67610e03.png">'
    $videos[i].appendChild($del)
  }
}

function showDelImgBtn(editorDom) {
  var $imgs = editorDom.querySelectorAll("img")
  // console.log("enter showDelImgBtn $imgs", $imgs)
  for (var i = 0; i < $imgs.length; i++) {
    if (
      (!$imgs[i].parentNode.getAttribute("class") &&
        !$imgs[i].parentNode.getAttribute("style")) ||
      $imgs[i].parentNode.getAttribute("class") === "insert-image"
    ) {
      // console.log($imgs[i])

      var $del = document.createElement("div")
      $del.setAttribute("class", "del-icon-box")
      $del.innerHTML =
        '<img onclick="delImage(this)" width="100%" height="100%" src="https://and-static.ghzs.com/image/article/thumb/2021/05/17/60a1e87f01a72060b534e7a9.png">'
      if (!$imgs[i].parentNode.getAttribute("class")) {
        $imgs[i].parentNode.setAttribute("class", "insert-image")
      }
      $imgs[i].parentNode.appendChild($del)
      $imgs[i].style.margin = "0 auto"
    }
    $imgs[i].parentNode.setAttribute("contenteditable", false)
  }
}

function initPlaceholderVideo(editorDom) {
  var $videos = editorDom.querySelectorAll(".placeholder-video-container")
  for (var i = 0; i < $videos.length; i++) {
    $videos[i].querySelector(".video-poster-btn").style.display = ""
    var $del = document.createElement("div")
    $del.setAttribute("class", "del-icon-box")
    $del.innerHTML = `<img onclick="showDelDialog('${$videos[i].dataset.id}')" width="100%" height="100%" src="https://and-static.ghzs.com/image/article/thumb/2021/05/17/60a1e87f01a72060b534e7a9.png">`
    $videos[i].appendChild($del)
  }
}

function _showDelLinkBtn(editorDom) {
  function _insertBtn(doms) {
    for (var i = 0; i < doms.length; i++) {
      var $del = document.createElement("div")
      $del.setAttribute("class", "del-icon-box")
      $del.innerHTML =
        '<img onclick="delLink(this)" width="100%" height="100%" src="http://static-web.ghzs.com/website-static/images/icon_del.png">'
      doms[i].appendChild($del)
    }
  }
  _insertBtn(editorDom.querySelectorAll("a.answer"))
  _insertBtn(editorDom.querySelectorAll("a.community_article"))
  _insertBtn(editorDom.querySelectorAll("a.game"))
}
