




























































import { NVideoComment } from "@/api/modules/video"
import { timeago, formatURLInHTML } from "@/utils/base"
import { Component, Vue, Prop } from "vue-property-decorator"
import previewImage from "@/components/previewImage"
import downloadDialog, { downloadDialogType } from "@/components/downloadDialog"

@Component({
  name: "CommentItem"
})
export default class CommentItem extends Vue {
  @Prop({ type: Object, default: () => {} }) data: NVideoComment.IItem

  timeago = timeago
  formatURLInHTML = formatURLInHTML

  handleItem(src) {
    previewImage({
      imgUrl: src
    })
  }

  handleDownload() {
    downloadDialog(
      {
        rmAfterDestroy: false,
        presetData: {
          type: downloadDialogType.BBS
        }
      },
      () => {}
    )
  }
}
