














/**
 * @description 没有更多数据了
 */
import { Component, Vue, Prop } from "vue-property-decorator"

@Component({
  name: "NoMoreData"
})
export default class NoMoreData extends Vue {
  @Prop({ type: Boolean, default: false }) main: Boolean
}
