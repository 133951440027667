import { apiApp, apiBaseUrlV5 } from "@/config"
import { AxiosInstance, AxiosPromise, AxiosResponse } from "axios"
/**
 * https://git.ghzs.com/halo/wiki/and-wiki/-/wikis/api/v4.7.0/api-v4.7.0#get-usersuser_idcommunitiesarticle_drafts
 */
export default function(
  service: AxiosInstance,
  serviceWithToken: AxiosInstance
) {
  return {
    user: {
      // 我的回答
      myAnswers(
        user_id: string
      ): Promise<AxiosResponse<User.IHistoriesAnswer[]>> {
        return serviceWithToken.get(`/users/${user_id}/histories`, {
          params: { filter: "scene:question_answer,type:answer" }
        })
      },
      // 我的帖子
      myCommunityArticles(
        user_id: string
      ): Promise<AxiosResponse<User.IHistoriesCommunityArticle[]>> {
        return serviceWithToken.get(`/users/${user_id}/histories`, {
          params: { filter: "scene:question_answer,type:community_article" }
        })
      },
      // 我的视频帖
      myVideos(
        user_id: string
      ): Promise<AxiosResponse<User.IHistoriesVideo[]>> {
        return serviceWithToken.get(`/users/${user_id}/histories`, {
          params: { filter: "scene:question_answer,type:video" }
        })
      },
      // 获取收藏 - 回答
      answerCollections(
        user_id: string
      ): Promise<AxiosResponse<User.IAnswerCollection[]>> {
        return serviceWithToken.get(`/users/${user_id}/favorites/answers`)
      },
      // 获取收藏 - 帖子(社区文章)
      communityArticleCollections(
        user_id: string
      ): Promise<AxiosResponse<User.ICommunityArticleCollection[]>> {
        return serviceWithToken.get(
          `/users/${user_id}/favorites/communities/articles`
        )
      },
      // 获取收藏 - 视频贴
      videoCollections(
        user_id: string
      ): Promise<AxiosResponse<User.IVideoCollection[]>> {
        return serviceWithToken.get(`/users/${user_id}/favorites/videos`)
      },
      // 帖子草稿
      drafts: {
        list(user_id: string): Promise<AxiosResponse<User.IDraftItem[]>> {
          return serviceWithToken.get(
            `/users/${user_id}/communities/article_drafts`
          )
        },
        detail(
          user_id: string,
          draft_id: string
        ): Promise<AxiosResponse<User.IDraftItem>> {
          return serviceWithToken.get(
            `/users/${user_id}/communities/article_drafts/${draft_id}`
          )
        },
        create(user_id: string, data: User.ICreateDraft) {
          return serviceWithToken.post(
            `/users/${user_id}/communities/article_drafts`,
            data
          )
        },
        edit(user_id: string, draft_id: string, data: User.ICreateDraft) {
          return serviceWithToken.put(
            `/users/${user_id}/communities/article_drafts/${draft_id}`,
            data
          )
        },
        del(user_id: string, draft_id: string) {
          return serviceWithToken.delete(
            `/users/${user_id}/communities/article_drafts/${draft_id}`
          )
        }
      },
      fakeUsersList(): AxiosPromise<{ _id: string; name: string }[]> {
        return serviceWithToken.get(`${apiApp}/users/fake`)
      }
    }
  }
}

export declare module User {
  export interface IHistoriesAnswer {
    _id: string
    question: Question
    type: string
    brief: string
    images: any[]
    images_info: any[]
    videos: any[]
    count: Count
    user: User
    time: number
    community: Community
    commentable: boolean
  }
  interface Question {
    _id: string
    title: string
    description: string
    images: string[]
    videos: any[]
  }
  interface Community {
    _id: string
    name: string
    type: string
    game: Game
  }
  interface Count {
    vote: number
    comment: number
  }
  interface Game {
    _id: string
    icon: string
    icon_subscript: string
    ori_icon: string
  }
  interface ImagesInfo {
    width: number
    height: number
  }
  interface IHistoriesCommunityArticle {
    _id: string
    type: string
    title: string
    brief: string
    images: string[]
    images_info: ImagesInfo[]
    videos: Videos[]
    community: Community
    count: Count
    user: User
    time: number
    status: string
    is_edit: boolean
  }

  interface User {
    _id: string
    name: string
    icon: string
  }

  interface Videos {
    id: string
    url: string
    duration: string
    poster: string
    status: string
    width: number
    height: number
  }
  interface Videoinfo {
    width: number
    height: number
  }
  interface User {
    _id: string
    name: string
    icon: string
  }
  interface Count {
    vote: number
    comment: number
  }

  interface Question {
    _id: string
    title: string
  }

  interface User {
    name: string
    icon: string
  }

  interface Me {
    is_answer_voted: boolean
  }

  export interface IAnswerCollection {
    _id: string
    question: Question
    brief: string
    images: string[]
    active: boolean
    user: User
    vote: number
    community_name: string
    comment_count: number
    read: boolean
    me: Me
    time: number
  }
  export interface ICommunityArticleCollection {
    _id: string
    title: string
    brief: string
    images: string[]
    videos: any[]
    active: boolean
    user: ICommunityArticleCollectionUser
    time: ICommunityArticleCollectionTime
    images_info: ICommunityArticleCollectionImagesinfo[]
    count: ICommunityArticleCollectionCount
    bbs: ICommunityArticleCollectionBbs
    type: string
    read: boolean
  }
  interface ICommunityArticleCollectionBbs {
    _id: string
    name: string
    type: string
    game: ICommunityArticleCollectionBbsGame
  }
  interface ICommunityArticleCollectionBbsGame {
    _id: string
    icon: string
    icon_subscript: string
    ori_icon: string
  }
  interface ICommunityArticleCollectionCount {
    vote: number
    comment: number
  }
  interface ICommunityArticleCollectionImagesinfo {
    width: number
    height: number
  }
  interface ICommunityArticleCollectionTime {
    create: number
    update: number
    edit: number
    comment: number
  }
  interface ICommunityArticleCollectionUser {
    _id: string
    name: string
    icon: string
    icon_border_url: string
    auth: ICommunityArticleCollectionAuth
    badge: ICommunityArticleCollectionBadge
  }
  interface ICommunityArticleCollectionBadge {
    name: string
    icon: string
    actions: ICommunityArticleCollectionAction[]
  }
  interface ICommunityArticleCollectionAction {
    text: string
  }
  interface ICommunityArticleCollectionAuth {
    icon: string
    text: string
    color: string
  }

  interface IHistoriesVideo {
    _id: string
    type: string
    title: string
    des: string
    count: ICount
    url: string
    poster: string
    length: number
    user: IUser
    time: number
    community: ICommunity
    status: string
    video_info: IVideo_info
  }

  interface IVideo_info {
    width: number
    height: number
  }

  interface ICommunity {
    _id: string
    name: string
    type: string
    _seq: number
    game: IGame
  }

  interface IGame {
    _id: string
    icon: string
    icon_subscript: string
    ori_icon: string
    _seq: number
  }

  interface IUser {
    _id: string
    name: string
    icon: string
    badge: IBadge
    count: ICount_1
  }

  interface ICount_1 {
    fans: number
  }

  interface IBadge {
    name: string
    icon: string
    actions: IAction[]
  }

  interface IAction {
    text: string
  }

  interface ICount {
    vote: number
    comment: number
  }

  interface IVideoCollection {
    _id: string
    title: string
    des?: string
    poster: string
    url: string
    length: number
    vote: number
    comment_count: number
    user: IVideoCollectionUser
  }

  interface IVideoCollectionUser {
    _id: string
    name: string
    icon: string
  }

  interface IDraftItem {
    _id: string
    title: string
    brief: string
    images: string[]
    tags: string[]
    time: DraftTime
    community: DraftCommunity
  }
  interface DraftCommunity {
    _id: string
    name: string
    type: string
    game: Game
  }
  interface DraftGame {
    _id: string
    icon: string
    icon_subscript: string
    ori_icon: string
  }
  interface DraftTime {
    create: number
    update: number
    edit: number
  }

  interface ICreateDraft {
    title: string
    content: string
    type: string
    tag_activity_id: string
    community_id: string
  }
}
