import { apiBaseUrlV5 } from "@/config"
import { AxiosInstance, AxiosResponse } from "axios"

export default function(service: AxiosInstance) {
  return {
    toolkit: {
      list(params?: IList): Promise<AxiosResponse<IToolkitList[]>> {
        return service.get(`${apiBaseUrlV5}/toolkits`, { params })
      },
      listByCategory(
        category_id: string,
        total: string | number
      ): Promise<AxiosResponse<Datum[]>> {
        return service.get(`${apiBaseUrlV5}/toolkits`, {
          params: { filter: `category_id:${category_id}`, page_size: total }
        })
      },
      listByKeyword(keyword: string): Promise<AxiosResponse<Datum[]>> {
        return service.get(`${apiBaseUrlV5}/toolkits`, {
          params: { filter: `keyword:${keyword}` }
        })
      }
    }
  }
}

interface IList {
  page?: number
  page_size?: number
  view?: string
  filter?: string
  keyword?: string
  active?: boolean
}

export interface IToolkitList {
  _id: string
  name: string
  total: number
  data: Datum[]
}
export interface Datum {
  _id: string
  icon: string
  name: string
  des: string
  url: string
  time: number
}
