import { render, staticRenderFns } from "./GameDialog.vue?vue&type=template&id=a61feb6c&scoped=true"
import script from "./GameDialog.vue?vue&type=script&lang=ts"
export * from "./GameDialog.vue?vue&type=script&lang=ts"
function injectStyles (context) {
  
  var style0 = require("./GameDialog.vue?vue&type=style&index=0&id=a61feb6c&prod&scoped=true&lang=scss")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "a61feb6c",
  "8adddffe"
  
)

export default component.exports