
























































import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import { copyText, DateFotmat } from "@/utils/base"
import { User } from "@/api/modules/user"

@Component({
  name: "shareDialog",
  components: {
    NoMoreData: () => import("@/components/noMoreData/noMore.vue")
  }
})
export default class shareDialog extends Vue {
  get userInfo() {
    return this.$tstore.modules.userInfo.getters.userInfo
  }

  DateFotmat = DateFotmat
  // 通用弹窗的JS数据和方法
  cancel() {
    this.isSubmit = false
    this.dialogVisible = false
  }

  onSubmit(item?) {
    if (item) {
      this.selected = item
    }
    this.isSubmit = true
    this.dialogVisible = false
  }

  dialogDataPresets: { list: User.IDraftItem[] } = {
    list: []
  }

  isRender: boolean = true
  isSubmit: boolean = false

  @Prop()
  dialogVisible: boolean = false

  selected = null

  handleDel(id) {
    this.$confirmDLG(
      "确定删除此草稿吗？删除后不可恢复",
      async () => {
        await this.$tstore.getters.api.user.drafts.del(this.userInfo._id, id)
        this.getList()
      },
      () => {}
    )
  }

  list: User.IDraftItem[] = []
  getList() {
    this.$root.$tstore.getters.api.user.drafts
      .list(this.userInfo._id)
      .then(res => {
        this.list = res.data
      })
      .catch(err => {
        // NOT FOUND
        if (err.response.data.code === 404001) {
          this.list = []
        }
      })
  }

  @Watch("dialogDataPresets.list")
  onList(c) {
    if (c && c.length > 0) {
      this.list = c
    }
  }
}
