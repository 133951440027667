








































































































































































/**
 * api https://git.shanqu.cc/halo/wiki/and-wiki/-/wikis/api/bbs#post-bbsesarticles
 */
import { Component, Vue, Inject, Watch } from "vue-property-decorator"
import draftDialog from "./components/draftDialog"
import alertMessage from "@/components/common/ghAlert"
import postSuccessDLG from "./components/postSuccessDLG"
import GameDialog from "./components/gameDialog"
import SectionDialog from "./components/sectionDialog"
import UserDialog from "./components/userDialog"

@Component({
  name: "ArticleCreate",
  components: {
    ConfirmBar: () =>
      import("@/views/VideoCreate/components/confirmBar/ConfirmBarComp.vue"),
    GameInfo: () => import("@/views/Article/components/gameInfo.vue"),
    ScanDownload: () => import("@/components/scanDownload/scanDownload.vue"),
    FooterCard: () => import("@/components/footerCard/footerCard.vue"),
    GhEditor: () => import("@/components/common/ghEditor")
  },
  beforeRouteUpdate(to, from, next) {
    //console.log(to, from, next)
    this.getBbs(to.params.bbs_id)
    next()
  }
})
export default class ArticleCreate extends Vue {
  $refs: { bar: any; createContainer: any; ghEditor: any; mainContainer: any }

  get isOfficial() {
    return this.bbs && this.bbs.type === "official_bbs"
  }

  alertError(message) {
    alertMessage({
      type: "error",
      message
    })
  }

  userInfo = null

  showEditor = false
  container = null
  /** client端获取数据 */

  mounted() {
    this.clientFetchData()
  }

  /** 页面权限 */
  auth() {
    return new Promise(async (resolve, reject) => {
      // 判断是否登陆状态
      if (!this.$root.$tstore.modules.userInfo.getters.isLogin) {
        // 判断是否有 access_token
        if (this.$cookies.get("access_token")) {
          try {
            await this.handleGetUserInfo()
          } catch (error) {
            if (
              error.response &&
              error.response.data.code === 401001 &&
              this.$cookies.get("refresh_token")
            ) {
              await this.handleRefreshToken()
            } else {
              console.log(error)
              reject(error)
            }
          }
        } else if (this.$cookies.get("refresh_token")) {
          await this.handleRefreshToken()
        }
      }
      this.userInfo = this.$root.$tstore.modules.userInfo.getters.userInfo

      try {
        const res = await this.$root.$tstore.getters.api.auth.permissions(
          this.userInfo._id,
          "bbs"
        )
        if (!res.data.post) {
          reject()
        }
        resolve("")
      } catch (error) {
        console.log(error)
        reject(error)
      }
    })
  }

  handleGetUserInfo() {
    return new Promise(async (resolve, reject) => {
      try {
        const apiAuthRes = await this.$root.$tstore.getters.api.auth.getUserInfo()
        this.$root.$tstore.modules.userInfo.mutations.SET_USER_INFO({
          userInfo: apiAuthRes.data
        })
        resolve("")
      } catch (apiAuthError) {
        reject(apiAuthError)
      }
    })
  }

  handleRefreshToken() {
    return new Promise(async (resolve, reject) => {
      try {
        const refreshTokenRes = await this.$root.$tstore.getters.api.auth.refreshToken(
          this.$cookies.get("refresh_token")
        )

        this.$cookies.set(
          "access_token",
          refreshTokenRes.data.access_token.value,
          {
            expires: refreshTokenRes.data.access_token.expire
          }
        )
        this.$cookies.set(
          "refresh_token",
          refreshTokenRes.data.refresh_token.value,
          {
            expires: refreshTokenRes.data.refresh_token.expire
          }
        )
        this.$root.$tstore.actions.UPDATE_API_TOKEN({
          access_token: refreshTokenRes.data.access_token.value
        })
        this.$root.$tstore.modules.userInfo.actions.FETCH_USER_INFO()
        resolve("")
      } catch (error) {
        console.log(error)
        reject(error)
      }
    })
  }

  async clientFetchData() {
    try {
      await this.auth()
      this.showEditor = true
      this.getFakeUsers()
      await this.getBbs()
      this.getTagList()
      await this.getModeratorInfo()
      this.getSectionList()
      this.getDraftList(this.userInfo._id)
      window.isEditing = true // 全局flag 编辑时点击引用帖子不跳转
      setTimeout(() => {
        this.container = this.$refs.mainContainer
        this.addIo()
      }, 100)
    } catch (error) {
      this.$router.replace(`/bbs/${this.$route.params.bbs_id}`)
    }
  }

  /** 论坛信息 */
  bbs = null
  async getBbs(id?) {
    const res = await this.$root.$tstore.getters.api.bbs.detail(
      id || this.$route.params.bbs_id
    )
    this.bbs = res.data
  }

  /** 活动标签 */
  isNeedOriginal = false
  tagOpts = []
  async getTagList() {
    const res = await this.$root.$tstore.getters.api.video.actTagList(
      "bbs_article",
      this.bbs._id
    )
    this.tagOpts = res.data.map(v => ({
      label: v.name,
      value: v._id,
      original: v.original
    }))
  }
  handleClickTag(item) {
    this.form.tag_activity_id = item.value
    this.form.tag_activity_name = item.label
    this.isNeedOriginal = item.original
    this.saveDraft()
  }
  handleClearTag() {
    this.form.tag_activity_id = ""
    this.form.tag_activity_name = ""
    this.isNeedOriginal = false
    this.saveDraft()
  }

  /** 草稿箱 */
  timer = null
  draft_id = null
  draftList = []
  tipText = ""
  saveDraft() {
    this.tipText = "草稿保存中..."
    if (this.timer) {
      clearTimeout(this.timer)
    }
    this.timer = setTimeout(async () => {
      const params = {
        title: this.form.title,
        content: this.form.content,
        type: this.form.type,
        tag_activity_id: this.form.tag_activity_id,
        community_id: this.$route.params.bbs_id
      }
      try {
        if (!this.draft_id) {
          const res = await this.$root.$tstore.getters.api.user.drafts.create(
            this.userInfo._id,
            params
          )
          this.draft_id = res.data._id
        } else {
          await this.$root.$tstore.getters.api.user.drafts.edit(
            this.userInfo._id,
            this.draft_id,
            params
          )
        }
        this.tipText = "草稿已保存"
        this.getDraftList(this.userInfo._id)
      } catch (error) {
        console.log(error)
        if (error.response && error.response.data.code === 401001) {
          this.$error("登录状态已过期")
          this.$root.$tstore.modules.userInfo.mutations.SET_USER_INFO({
            userInfo: null
          })
          this.$router.replace(`/bbs/${this.$route.params.bbs_id}`)
        } else {
          this.alertError("草稿保存失败！")
        }
      }
    }, 3000)
  }
  async getDraftList(_id: string) {
    this.$root.$tstore.getters.api.user.drafts
      .list(_id)
      .then(res => {
        this.draftList = res.data
      })
      .catch(err => {
        // NOT FOUND
        if (err.response.data.code === 404001) {
          this.draftList = []
        }
      })
  }
  async getDraft(draft_id) {
    const res = await this.$root.$tstore.getters.api.user.drafts.detail(
      this.userInfo._id,
      draft_id
    )
    this.draft_id = res.data._id
    Object.keys(this.form).forEach(key => {
      if (res.data[key]) {
        this.form[key] = res.data[key]
      }
    })
    // 手动更新编辑器html
    ;(this.$refs.ghEditor as any).updateHtml(this.form.content)
  }
  handleDraft() {
    draftDialog(
      {
        rmAfterDestroy: true,
        presetData: {
          list: this.draftList
        },
        $tstore: this.$root.$tstore
      },
      ({ selected }) => {
        if (selected) {
          this.draft_id = selected._id
          this.form = this.initForm()

          if (
            selected.community &&
            selected.community._id !== this.$route.params.bbs_id
          ) {
            this.$router.replace({
              path: `/bbs/${selected.community._id}/article/create`
            })
          }
          this.getDraft(this.draft_id)
        }
        this.getDraftList(this.userInfo._id)
      }
    )
  }

  onTitleKeydown() {
    if (this.form.title.length === 50) {
      this.$error("标题字数不超过50~")
    }
  }

  /** 发布 */
  get canCreate() {
    return (
      this.form.title &&
      this.form.title.length >= 6 &&
      this.form.content.replace(/<[^>]*>|/g, "").length > 6
    )
  }

  async onCreate() {
    // valiedate
    if (!this.form.title) {
      this.alertError("标题不可为空~")
      return false
    } else if (this.form.title.length < 6) {
      this.alertError("标题字数至少需要6个字符~")
      return false
    } else if (this.form.content.replace(/<[^>]*>|/g, "").length <= 6) {
      this.alertError("正文内容需要大于6个字符~")
      return false
    } else if (this.isNeedOriginal && !this.form.original) {
      this.alertError("本次话题内容要求原创~")
      return false
    }

    if (this.fakeUserList.length > 0) {
      UserDialog(
        {
          rmAfterDestroy: true,
          presetData: { fakeUserList: this.fakeUserList },
          $tstore: this.$root.$tstore
        },
        ({ selected }) => {
          if (selected) {
            const { _id } = selected
            this.form.fake_user_id = _id
            this.create()
          }
        }
      )
    } else {
      this.create()
    }
  }
  async create() {
    const form = JSON.parse(JSON.stringify(this.form))
    if (this.selectedGame) {
      form.game_id = this.selectedGame._id
    }
    if (this.selectedSection) {
      form.section_id = this.selectedSection._id
    }
    if (this.isOfficial) {
      form.type = "official_bbs"
    }
    form.original = this.form.original ? "yes" : "no"
    form.draft_id = this.draft_id

    try {
      await this.$root.$tstore.getters.api.communityArticle.create(
        this.bbs._id,
        form
      )

      this.handleBack()

      postSuccessDLG({
        rmAfterDestroy: false,
        presetData: {}
      })
    } catch (error) {
      console.log(error)
      if (error.response && error.response.data.code === 401001) {
        this.$error("登录状态已过期")
        this.$root.$tstore.modules.userInfo.mutations.SET_USER_INFO({
          userInfo: null
        })
        this.$router.replace(`/bbs/${this.$route.params.bbs_id}`)
      } else {
        this.alertError("发布失败！")
      }
    }
  }

  selectedGame: { _id: string; name: string; icon: string } = null
  selectedSection: { _id: string; name: string } = null
  form = this.initForm()
  initForm() {
    return {
      title: "",
      content: "",
      tag_activity_id: "",
      tag_activity_name: "",
      type: "game_bbs",
      draft_id: "",
      original: "",
      game_id: "",
      fake_user_id: ""
    }
  }
  htmlChange(val) {
    if (val !== this.form.content) {
      this.form.content = val
      this.saveDraft()
    }
  }
  count = ""
  textCountChange(val) {
    this.count = val
  }

  handleBack() {
    this.$router.go(-1)
  }

  io: IntersectionObserver = null
  addIo() {
    setTimeout(() => {
      this.io = new IntersectionObserver(
        entries => {
          entries.forEach(item => {
            if (item.isIntersecting) {
              this.$refs.bar.$el.style.position = "absolute"
              this.$refs.bar.$el.style.width = "680px"
              this.$refs.bar.$el.style.bottom = "-20px"
              this.$refs.bar.$el.style.left = "auto"
            } else {
              if (this.$refs.bar) {
                this.$refs.bar.$el.style.position = "fixed"
                this.$refs.bar.$el.style.bottom = "0"
                //  this.$refs.bar.setLeft()
              }
            }
          })
        },
        {
          threshold: [0]
        }
      )
      this.io.observe(document.querySelector(".footer-component"))
    }, 0)
  }
  destroyed() {
    if (this.io) {
      this.io.disconnect()
      this.io = null
    }
  }

  handleSelectGame() {
    GameDialog(
      {
        rmAfterDestroy: true,
        presetData: {},
        $tstore: this.$root.$tstore
      },
      ({ selected }) => {
        if (selected) {
          const { _id, name, icon } = selected
          this.selectedGame = {
            _id,
            name,
            icon
          }
        } else {
          this.selectedGame = null
        }
      }
    )
  }

  /** 子板块 */
  isModerator = false
  getModeratorInfo() {
    return this.$root.$tstore.getters.api.bbs
      .moderatorsInfo(this.$route.params.bbs_id)
      .then(res => {
        this.isModerator = res.data.is_moderators
      })
      .catch(err => {
        console.log(err)
      })
  }
  get hasSections() {
    return this.sectionList && this.sectionList.length > 1
  }
  sectionList = []
  async getSectionList() {
    if (!this.$root.$tstore.modules.bbs.getters.sections) {
      await this.$root.$tstore.modules.bbs.actions.FETCH_SECTIONS({
        bbs_id: this.bbs._id
      })
    }
    if (this.$root.$tstore.modules.bbs.getters.sections) {
      this.sectionList = [{ name: "全部（不选择子版块）", _id: "" }].concat(
        this.$root.$tstore.modules.bbs.getters.sections.filter(v => {
          return (
            v.permissions === "user" ||
            (this.isModerator && v.permissions === "moderators")
          )
        })
      )
    }
  }
  handleSelectSection() {
    SectionDialog(
      {
        rmAfterDestroy: true,
        presetData: {
          sectionList: this.sectionList,
          selected: this.selectedSection
        },
        $tstore: this.$root.$tstore
      },
      ({ selected }) => {
        if (selected && selected._id) {
          const { _id, name } = selected
          this.selectedSection = {
            _id,
            name
          }
        } else {
          this.selectedSection = null
        }
      }
    )
  }

  /** 获取fakeUser列表，检验自己是不是 */
  fakeUserList = []
  async getFakeUsers() {
    try {
      const res = await this.$root.$tstore.getters.api.user.fakeUsersList()
      if (res) {
        this.fakeUserList = res.data || []
      }
    } catch (error) {
      console.log(error)
    }
  }
}
