




































import { Component, Vue } from "vue-property-decorator"
import updateTdk from "@/utils/tdk"

@Component({
  name: "BbsSearchList",
  components: {
    Empty: () => import("@/components/empty/empty.vue"),
    LoadMore: () => import("@/components/loadMore/loadMore.vue")
  },
  tdk() {
    return this.metaInfo
  }
})
export default class BbsSearchList extends Vue {
  get metaInfo() {
    return {
      title: `查看 ${this.$route.params.keyword}的搜索结果 | 要好玩，上光环！`,
      description: `光环助手为您找到以下和 ${this.$route.params.keyword}相关的游戏和论坛内容，更多 ${this.$route.params.keyword}内容，尽在光环`,
      keywords: `${this.$route.params.keyword} `
    }
  }

  loading = false
  showNone = false
  list = []
  // ssr hook
  serverPrefetch() {
    return this.fetchData()
  }

  beforeMount() {
    this.fetchData()
  }

  fetchData() {
    this.loading = true
    return this.$store
      .dispatch("bbs/FETCH_GAME_SEARCH", {
        keyword: this.$route.params.keyword,
        type: "game"
      })
      .then(this.formatData)
      .catch(() => {
        // this.$router.push({ name: "404" })
      })
      .finally(() => {
        this.loading = false
        updateTdk.call(this)
      })
  }

  formatData() {
    this.list = this.$store.state.bbs.gameSearchList
    if (this.list.length === 0) {
      this.showNone = true
    }
  }

  destroyed() {
    this.$store.commit("bbs/SET_GAME_SEARCH", {
      list: null
    })
  }

  handleDetail(bbs_id: string) {
    this.$router.push(`/bbs/${bbs_id}`)
  }
}
