





























































































































































import { Component, Inject, Vue, Watch } from "vue-property-decorator"

import { IVideoInfo } from "./components/uploadVideo/UploadVideoComp.vue"
import draftDialog from "./components/draftDialog"
import alertMessage from "@/components/common/ghAlert"
import UserDialog from "@/views/ArticleCreate/components/userDialog"
import postSuccessDLG from "../ArticleCreate/components/postSuccessDLG"
import SectionDialog from "../ArticleCreate/components/sectionDialog"

@Component({
  name: "VideoCreate",
  beforeRouteUpdate(to, from, next) {
    //console.log(to, from, next)
    this.getBbs(to.params.bbs_id)
    next()
  },
  components: {
    GameInfo: () => import("@/views/Article/components/gameInfo.vue"),
    ScanDownload: () => import("@/components/scanDownload/scanDownload.vue"),
    FooterCard: () => import("@/components/footerCard/footerCard.vue"),
    ConfirmBar: () => import("./components/confirmBar/ConfirmBarComp.vue"),
    UploadVideo: () => import("./components/uploadVideo/UploadVideoComp.vue")
  }
})
export default class VideoCreate extends Vue {
  $refs: { bar: any }
  alertError(message) {
    alertMessage({
      type: "error",
      message
    })
  }
  userInfo = null

  get isOfficial() {
    return this.bbs && this.bbs.type === "official_bbs"
  }

  /** client端获取数据 */
  mounted() {
    this.clientFetchData()
  }
  /** 验证 页面权限 */
  auth() {
    return new Promise(async (resolve, reject) => {
      // 判断是否登陆状态
      if (!this.$root.$tstore.modules.userInfo.getters.isLogin) {
        // 判断是否有 access_token
        if (this.$cookies.get("access_token")) {
          try {
            await this.handleGetUserInfo()
          } catch (error) {
            if (
              error.response &&
              error.response.data.code === 401001 &&
              this.$cookies.get("refresh_token")
            ) {
              await this.handleRefreshToken()
            } else {
              console.log(error)
              reject(error)
            }
          }
        } else if (this.$cookies.get("refresh_token")) {
          await this.handleRefreshToken()
        }
      }
      this.userInfo = this.$root.$tstore.modules.userInfo.getters.userInfo

      try {
        const res = await this.$root.$tstore.getters.api.auth.permissions(
          this.userInfo._id,
          "bbs"
        )
        if (!res.data.post) {
          reject()
        }
        resolve("")
      } catch (error) {
        console.log(error)
        reject(error)
      }
    })
  }

  handleGetUserInfo() {
    return new Promise(async (resolve, reject) => {
      try {
        const apiAuthRes = await this.$root.$tstore.getters.api.auth.getUserInfo()
        this.$root.$tstore.modules.userInfo.mutations.SET_USER_INFO({
          userInfo: apiAuthRes.data
        })
        resolve("")
      } catch (apiAuthError) {
        reject(apiAuthError)
      }
    })
  }
  handleRefreshToken() {
    return new Promise(async (resolve, reject) => {
      try {
        const refreshTokenRes = await this.$root.$tstore.getters.api.auth.refreshToken(
          this.$cookies.get("refresh_token")
        )

        this.$cookies.set(
          "access_token",
          refreshTokenRes.data.access_token.value,
          {
            expires: refreshTokenRes.data.access_token.expire
          }
        )
        this.$cookies.set(
          "refresh_token",
          refreshTokenRes.data.refresh_token.value,
          {
            expires: refreshTokenRes.data.refresh_token.expire
          }
        )
        this.$root.$tstore.actions.UPDATE_API_TOKEN({
          access_token: refreshTokenRes.data.access_token.value
        })
        this.$root.$tstore.modules.userInfo.actions.FETCH_USER_INFO()
        resolve("")
      } catch (error) {
        console.log(error)
        reject(error)
      }
    })
  }

  async clientFetchData() {
    try {
      await this.auth()
      this.getFakeUsers()
      await this.getBbs()
      this.getTagList()
      await this.getModeratorInfo()
      this.getSectionList()
      this.getDraftList(this.userInfo._id)
      setTimeout(() => {
        this.addIo()
      }, 100)
    } catch (error) {
      this.$router.replace(`/bbs/${this.$route.params.bbs_id}`)
    }
  }

  /** 论坛信息 */
  bbs = null
  async getBbs(id?) {
    const res = await this.$root.$tstore.getters.api.bbs.detail(
      id || this.$route.params.bbs_id
    )
    this.bbs = res.data
    this.form.bbs_id = this.bbs._id
    if (this.bbs.game_id) {
      this.form.game_id = this.bbs.game_id
    }
  }

  /** 活动标签 */
  isNeedOriginal = false
  tagOpts = []
  async getTagList() {
    const res = await this.$root.$tstore.getters.api.video.actTagList(
      "bbs_video",
      this.bbs._id
    )
    this.tagOpts = res.data.map(v => ({
      label: v.name,
      value: v._id,
      original: v.original
    }))
  }
  handleClickTag(item) {
    this.form.tag_activity_id = item.value
    this.isNeedOriginal = item.original
    this.saveDraft()
  }
  handleClearTag() {
    this.form.tag_activity_id = ""
    this.isNeedOriginal = false
    this.saveDraft()
  }

  /** 草稿箱 */
  timer = null
  draft_id = null
  draftList = []
  tipText = ""
  saveDraft() {
    this.tipText = "草稿保存中..."
    if (this.timer) {
      clearTimeout(this.timer)
    }
    this.timer = setTimeout(async () => {
      const params = JSON.parse(JSON.stringify(this.form))
      params.local_path = ""
      try {
        if (!this.draft_id) {
          const res = await this.$root.$tstore.getters.api.video.drafts.create(
            this.userInfo._id,
            params
          )
          this.draft_id = res.data._id
        } else {
          await this.$root.$tstore.getters.api.video.drafts.edit(
            this.userInfo._id,
            this.draft_id,
            params
          )
        }
        this.tipText = "草稿已保存"
        this.getDraftList(this.userInfo._id)
      } catch (error) {
        if (error.response && error.response.data.code === 401001) {
          this.$error("登录状态已过期")
          this.$root.$tstore.modules.userInfo.mutations.SET_USER_INFO({
            userInfo: null
          })
          this.$router.replace(`/bbs/${this.$route.params.bbs_id}`)
        } else {
          this.alertError("草稿保存失败！")
        }
      }
    }, 3000)
  }
  async getDraftList(_id: string) {
    this.$root.$tstore.getters.api.video.drafts
      .list(_id)
      .then(res => {
        this.draftList = res.data
      })
      .catch(err => {
        // NOT FOUND
        if (err.response.data.code === 404001) {
          this.draftList = []
        }
      })
  }
  async getDraft(draft_id) {
    const res = await this.$root.$tstore.getters.api.video.drafts.detail(
      this.userInfo._id,
      draft_id
    )
    this.draft_id = res.data._id

    Object.keys(this.form).forEach(key => {
      if (res.data[key]) {
        this.form[key] = res.data[key]
      }
    })
  }
  handleDraft() {
    draftDialog(
      {
        rmAfterDestroy: true,
        presetData: {
          list: this.draftList
        },
        $tstore: this.$root.$tstore
      },
      ({ selected }) => {
        if (selected) {
          this.startUpload = true
          this.draft_id = selected._id
          this.form = this.initForm()

          Object.keys(this.form).forEach(key => {
            if (selected[key]) {
              if (key === "bbs_id" && selected[key] !== this.form[key]) {
                this.$router.replace({
                  path: `/bbs/${selected[key]}/video/create`
                })
              }
              this.form[key] = selected[key]
            }
          })
        }
        this.getDraftList(this.userInfo._id)
      }
    )
  }

  /** 发布 */
  get createDisabled() {
    return !this.form.title || !this.form.url || !this.form.original
  }

  async onCreate() {
    // valiedate
    if (this.createDisabled) {
      if (!this.form.title) {
        this.alertError("视频贴的标题不可为空~")
      } else if (!this.form.url) {
        this.alertError("请上传视频~")
      } else if (!this.form.original) {
        this.alertError("请选择视频来源~")
      }
      return false
    } else if (this.isNeedOriginal && this.form.original === "no") {
      this.alertError("本次话题内容要求原创~")
      return false
    }
    if (this.form.original === "no" && !this.form.source) {
      this.alertError("请填写视频来源网址~")
      return false
    }
    if (this.fakeUserList.length > 0) {
      UserDialog(
        {
          rmAfterDestroy: true,
          presetData: { fakeUserList: this.fakeUserList },
          $tstore: this.$root.$tstore
        },
        ({ selected }) => {
          if (selected) {
            const { _id } = selected
            this.form.fake_user_id = _id
            this.create()
          }
        }
      )
    } else {
      this.create()
    }
  }
  async create() {
    try {
      if (this.selectedSection) {
        this.form["section_id"] = this.selectedSection._id
      }
      await this.$root.$tstore.getters.api.video.create(this.form)

      this.handleBack()
      postSuccessDLG({
        rmAfterDestroy: false,
        presetData: {}
      })
    } catch (error) {
      console.log(error)
      if (error.response && error.response.data.code === 403073) {
        this.alertError("标题违规，无法发布~")
      } else if (error.response && error.response.data.code === 401001) {
        this.$error("登录状态已过期")
        this.$root.$tstore.modules.userInfo.mutations.SET_USER_INFO({
          userInfo: null
        })
        this.$router.replace(`/bbs/${this.$route.params.bbs_id}`)
      } else {
        this.alertError("发布失败！")
      }
    }
  }
  form = this.initForm()
  initForm() {
    return {
      title: "",
      des: "",
      poster: "",
      url: "",
      format: "MP4",
      size: 0, //单位：字节
      length: 0, //单位：秒
      type: "game_bbs", // game_bbs游戏论坛视频 official_bbs官方论坛视频 game_bbs_insert游戏论坛视频插入 official_bbs_insert官方论坛视频插入
      tag_activity_id: "", // 活动标签
      bbs_id: "",
      game_id: "", // 在官方论坛发视频贴会有这个字段
      original: "", // 是否原创，yes是，no不是
      source: "", // 来源地址
      video_info: {
        width: 0,
        height: 0
      },
      fake_user_id: ""
    }
  }
  get originalTip() {
    if (this.form.original === "yes") {
      return "单选必填/勾选原创即代表声明此视频为本人原创"
    } else if (this.form.original === "no") {
      return "单选必填/转载请注明视频来源"
    } else {
      return "单选必填"
    }
  }
  onOriginal() {
    this.saveDraft()
  }

  /** 视频上传回调 */
  onVideoInfoChange(info: IVideoInfo) {
    const { poster, url, size, length, width, height } = info
    this.form.poster = poster
    this.form.url = url
    this.form.size = size
    this.form.length = length
    this.form.video_info.width = width
    this.form.video_info.height = height

    this.saveDraft()
  }
  startUpload = false
  onStart() {
    if (!this.startUpload) {
      this.startUpload = true
    }
  }
  get propVideoInfo() {
    return {
      poster: this.form.poster,
      url: this.form.url,
      format: this.form.format,
      size: this.form.size,
      length: this.form.length,
      width: this.form.video_info.width,
      height: this.form.video_info.height
    }
  }

  handleBack() {
    this.$router.go(-1)
  }

  io: IntersectionObserver = null
  addIo() {
    setTimeout(() => {
      this.io = new IntersectionObserver(
        entries => {
          entries.forEach(item => {
            if (item.isIntersecting) {
              this.$refs.bar.$el.style.position = "absolute"
              this.$refs.bar.$el.style.width = "680px"
              this.$refs.bar.$el.style.bottom = "-20px"
              this.$refs.bar.$el.style.left = "auto"
            } else {
              if (this.$refs.bar) {
                this.$refs.bar.$el.style.position = "fixed"
                this.$refs.bar.$el.style.bottom = "0"
                // this.$refs.bar.setLeft()
              }
            }
          })
        },
        {
          threshold: [0]
        }
      )
      this.io.observe(document.querySelector(".footer-component"))
    }, 0)
  }
  destroyed() {
    if (this.io) {
      this.io.disconnect()
      this.io = null
    }
  }

  /** 获取fakeUser列表，检验自己是不是 */
  fakeUserList = []
  async getFakeUsers() {
    try {
      const res = await this.$root.$tstore.getters.api.user.fakeUsersList()
      if (res) {
        this.fakeUserList = res.data || []
      }
    } catch (error) {
      console.log(error)
    }
  }

  /** 子板块 */
  isModerator = false
  getModeratorInfo() {
    return this.$root.$tstore.getters.api.bbs
      .moderatorsInfo(this.$route.params.bbs_id)
      .then(res => {
        this.isModerator = res.data.is_moderators
      })
      .catch(err => {
        console.log(err)
      })
  }
  get hasSections() {
    return this.sectionList && this.sectionList.length > 1
  }
  selectedSection: { _id: string; name: string } = null
  sectionList = []
  async getSectionList() {
    if (!this.$root.$tstore.modules.bbs.getters.sections) {
      await this.$root.$tstore.modules.bbs.actions.FETCH_SECTIONS({
        bbs_id: this.bbs._id
      })
    }
    if (this.$root.$tstore.modules.bbs.getters.sections) {
      this.sectionList = [{ name: "全部（不选择子版块）", _id: "" }].concat(
        this.$root.$tstore.modules.bbs.getters.sections.filter(v => {
          return (
            v.permissions === "user" ||
            (this.isModerator && v.permissions === "moderators")
          )
        })
      )
    }
  }
  handleSelectSection() {
    SectionDialog(
      {
        rmAfterDestroy: true,
        presetData: {
          sectionList: this.sectionList,
          selected: this.selectedSection
        },
        $tstore: this.$root.$tstore
      },
      ({ selected }) => {
        if (selected && selected._id) {
          const { _id, name } = selected
          this.selectedSection = {
            _id,
            name
          }
        } else {
          this.selectedSection = null
        }
      }
    )
  }
}
