import { insertAfter } from "@/utils/base"

// 第一，菜单 class ，Button 菜单继承 BtnMenu class
export default function($, BtnMenu, tstore?) {
  return class customItalic extends BtnMenu {
    constructor(editor) {
      // data-title属性表示当鼠标悬停在该按钮上时提示该按钮的功能简述
      const $elem = $(
        `<div class="w-e-menu" data-title="斜体">
            <i class="w-e-icon-italic"></i>
         </div>`
      )
      super($elem, editor)
      this._active = false
    }

    // 菜单点击事件
    clickHandler() {
      const editor = this.editor
      const isSelectEmpty = editor.selection.isSelectionEmpty()

      // 没有选区时点击加粗
      if (isSelectEmpty) {
        // P标签
        const containerElem = editor.selection.getSelectionContainerElem()
          .elems[0]

        // curNode 当前光标节点
        let curNode = editor.selection.getRange().commonAncestorContainer

        let isActive = false

        let targetNode
        while (curNode) {
          if (curNode.nodeName === "P") {
            curNode = null
          } else if (curNode.nodeName === "I") {
            isActive = true
            targetNode = curNode
            curNode = curNode.parentNode
          } else {
            curNode = curNode.parentNode
          }
        }
        // 如果当前处于B标签内，代表已经应用，再次点击则取消
        if (isActive) {
          const newNode = document.createElement("span")
          newNode.innerHTML = "&ZeroWidthSpace;"
          insertAfter(newNode, targetNode)
          editor.selection.moveCursor(newNode)
        } else {
          if (
            containerElem.nodeName === "P" &&
            containerElem.innerHTML === "<br>"
          ) {
            containerElem.innerHTML = "<i>&ZeroWidthSpace;</i>"
            editor.selection.moveCursor(containerElem.childNodes[0])
          } else {
            const _node = document.createElement("i")
            _node.innerHTML = "&ZeroWidthSpace;"
            containerElem.append(_node)
            editor.selection.moveCursor(_node)
          }
        }
      }

      // 执行 italic 命令
      editor.cmd.do("italic")

      if (isSelectEmpty) {
        // 需要将选区范围折叠起来
        editor.selection.collapseRange()
        editor.selection.restoreSelection()
      }
    }
    // 菜单是否被激活（如果不需要，这个函数可以空着）
    // 1. 激活是什么？光标放在一段加粗、下划线的文本时，菜单栏里的 B 和 U 被激活，如下图
    // 2. 什么时候执行这个函数？每次编辑器区域的选区变化（如鼠标操作、键盘操作等），都会触发各个菜单的 tryChangeActive 函数，重新计算菜单的激活状态
    tryChangeActive() {
      const editor = this.editor
      if (editor.cmd.queryCommandState("italic")) {
        this.active()
      } else {
        this.unActive()
      }
    }
  }
}
