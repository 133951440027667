import { render, staticRenderFns } from "./Comment.vue?vue&type=template&id=d58c6e6e&scoped=true"
import script from "./Comment.vue?vue&type=script&lang=ts"
export * from "./Comment.vue?vue&type=script&lang=ts"
function injectStyles (context) {
  
  var style0 = require("./style.scss?vue&type=style&index=0&id=d58c6e6e&prod&scoped=true&lang=scss&external")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "d58c6e6e",
  "f1cbb580"
  
)

export default component.exports