const ghLog = process.env.isSSR ? {} : require("@shanquweb/gh-log")

// 埋点已url为key
const urlKey = "https://www.ghzs666.com/"

export const EnterLog = () => {
  if (process.env.isSSR) return
  const entrance = ghLog.getChannel()
  ghLog.WebLog({
    event: "tool_box_page_enter",
    entrance,
    url: urlKey,
    tool_box_id: window.location.href,
    tool_box_name: ""
  })
}

export const PvLog = () => {
  if (process.env.isSSR) return {}
  return ghLog.webPvLog({
    event: "tool_box_page_view",
    url: urlKey,
    tool_box_id: window.location.href,
    tool_box_name: ""
  })
}

export const Log = () => {
  if (process.env.isSSR) return
  EnterLog()
  return PvLog()
}

export const downloadLog = () => {
  if (process.env.isSSR) return {}
  return ghLog.webPvLog({
    event: "pc_content_home_page_dialog_download_click"
  })
}
