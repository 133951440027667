










































































import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import { copyText, DateFotmat } from "@/utils/base"
import { IDraftItem } from "@/api/modules/video"
@Component({
  name: "shareDialog",
  components: {
    NoMoreData: () => import("@/components/noMoreData/noMore.vue")
  }
})
export default class shareDialog extends Vue {
  get userInfo() {
    return this.$tstore.modules.userInfo.getters.userInfo
  }

  DateFotmat = DateFotmat
  // 通用弹窗的JS数据和方法
  cancel() {
    this.isSubmit = false
    this.dialogVisible = false
  }

  onSubmit(item?) {
    if (item) {
      this.selected = item
    }
    this.isSubmit = true
    this.dialogVisible = false
  }

  dialogDataPresets: { list: IDraftItem[] } = {
    list: []
  }

  isRender: boolean = true
  isSubmit: boolean = false

  @Prop()
  dialogVisible: boolean = false

  selected = null

  handleDel(id) {
    this.$confirmDLG(
      "确定删除此草稿吗？删除后不可恢复",
      async () => {
        await this.$tstore.getters.api.video.drafts.del(this.userInfo._id, id)
        this.getList()
      },
      () => {}
    )
  }

  // 修复overflow hidden不起作用，修复弹框自动下拉的问题
  scrollTopVal: number = 0
  @Watch(`dialogVisible`)
  onChangeDialogShow(change: any) {
    if (change) {
      this.scrollTopVal =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        0
      document.body.style.overflow = "hidden"
      document.documentElement.style.position = "fixed"
      document.body.style.position = "fixed"
      document.body.style.width = "100%"
      document.documentElement.style.top = -1 * this.scrollTopVal + "px"
    } else {
      document.body.style.overflow = ""
      document.documentElement.style.position = ""
      document.body.style.position = ""
      window.scrollTo(0, this.scrollTopVal)
    }
  }

  list: IDraftItem[] = []
  getList() {
    this.$root.$tstore.getters.api.video.drafts
      .list(this.userInfo._id)
      .then(res => {
        this.list = res.data
      })
      .catch(err => {
        // NOT FOUND
        if (err.response.data.code === 404001) {
          this.list = []
        }
      })
  }

  @Watch("dialogDataPresets.list")
  onList(c) {
    if (c && c.length > 0) {
      this.list = c
    }
  }
}
